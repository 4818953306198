import { Box, Button, ModalProps, Typography } from '@mui/material';
import { Modal } from 'components/Modal';

type Props = {
    onCancel: () => void;
    onStartOver: () => void;
} & Omit<ModalProps, 'children'>;

export const StartOverModal = (props: Props) => {
  return (
    <Modal {...props} boxSx={{ width: { xs: 420, md: 500 }, padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ color: (t) => t.palette.grey['900'], textAlign: 'center', mb: 0 }}>Want to Start Over?</Typography>
        <Typography variant="body1" sx={{ color: (t) => t.palette.grey['800'], textAlign: 'center', my: { xs: 2 }, mt: { md: 2 }, mb: { md: 3 } }}>
          This will clear your answers and start over again from the beginning
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <Button
            onClick={() => props.onCancel()}
            variant="contained"
            sx={{
              color: (t) => t.palette.grey['900'],
              backgroundColor: (t) => t.palette.grey['400'],
              px: 4,
              mx: { md: 2 },
              my: { xs: 1, md: 0 },
              '&:hover': {
                backgroundColor: (t) => t.palette.grey['500']
              }
            }}
          >Cancel</Button>
          <Button
            onClick={() => props.onStartOver()}
            variant="contained"
            sx={{
              px: 3,
              mx: { md: 2 },
              my: { xs: 1, md: 0 }
            }}
          >Start Over</Button>
        </Box>
      </Box>
    </Modal>
  );
};
