import { Box, Typography } from '@mui/material';

export const TermsOfServiceView = () => (
  <Box sx={{
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    maxWidth: '100%',
    py: { md: 4 },
    px: { md: 16 },
    gap: 2
  }}>
    <Box sx={{
      py: 2,
      px: { xs: 4, md: 8 }
    }}>
      <Typography variant="h2" sx={{ textAlign: 'center'}}>
        Homeflow Terms of Service
      </Typography>
      <Typography variant="h4" sx={{ my: 2 }}>
        Acceptance of These Terms of Service
      </Typography>
      <p>
        <span
        >Rentr Insurance Services, LLC dba Homeflow Insurance Services
            (&ldquo;Homeflow,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or
            &ldquo;our&rdquo;) provides our services (described below) and related
            content to you through our website(s) located at homeflow.me (the
            &ldquo;Site&rdquo;) and through our mobile applications and related
            technologies (&ldquo;Mobile Apps&rdquo;, and collectively, such Mobile
            Apps and the Site, including any updated or new features,
            functionality and technology, the &ldquo;Service&rdquo;). All access
            and use of the Service is subject to the terms and conditions
            contained in these Terms of Service (as amended from time to time,
            these &ldquo;Terms of Service&rdquo;). By accessing, browsing, or
            otherwise using the Site, Mobile Apps, or any other aspect of the
            Service, you acknowledge that you have read, understood, and agree to
            be bound by these Terms of Service. If you do not accept the terms and
            conditions of these Terms of Service, you will not access, browse, or
            otherwise use the Service.
        </span>
      </p>
      <p>
        <span
        >We reserve the right, at our sole discretion, to change or modify
            portions of these Terms of Service at any time. If we do this, we will
            post the changes on this page and will indicate at the top of this
            page the date these Terms of Service were last revised. You may read a
            current, effective copy of these Terms of Service by visiting the
            &ldquo;Terms of Service&rdquo; link on the Site. We will also notify
            you of any material changes, either through the Service user
            interface, a pop-up notice, email, or through other reasonable means.
            Your continued use of the Service after the date any such changes
            become effective constitutes your acceptance of the new Terms of
            Service. You should periodically visit this page to review the current
            Terms of Service so you are aware of any revisions. If you do not
            agree to abide by these or any future Terms of Service, you will not
            access, browse, or use (or continue to access, browse, or use) the
            Service.</span
        >
      </p>
      <p>
        <span
        >PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
            AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
            LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
            REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
            AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL
            ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST HOMEFLOW ON AN INDIVIDUAL
            BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO
            SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF)
            ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS
            YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.</span
        >
      </p>
      <p>
        <span>Your Privacy: </span
        ><span
        >At Homeflow, we respect the privacy of our users. For more
            information please see our Privacy Policy, located at
            https://www.homeflow.me/privacy-policy (the &ldquo;Privacy
            Policy&rdquo;). By using the Service, you consent to our collection,
            use and disclosure of personal data and other data as outlined
            therein.</span
        >
      </p>
      <p>
        <span>Additional Terms</span
        ><span
        >: In addition, when using certain features through the Service, you
            will be subject to any additional terms applicable to such features
            that may be posted on or within the Service from time to time. </span
        ><span>&nbsp;</span
        ><span
        >All such terms are hereby incorporated by reference into these Terms
            of Service.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2 }}>
        Access and Use of the Service
      </Typography>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>Service Description: </span
        ><span
        >The Service is designed to help homeowners, landlords, and renters
            compare insurance quotes and purchase a policy.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Policy Purchases</span
        ><span
        >: &nbsp;If you choose to purchase a policy through the Service or
            through a Homeflow agent, you agree to (i) designate Homeflow as your
            broker of record or agent of record, (ii) authorize Homeflow to
            communicate this designation to any insurance carrier, your prior
            insurance producer, and any other person or entity Homeflow determines
            should be advised, (iii) work exclusively with Homeflow for insurance
            brokerage services unless otherwise expressly agreed,; (iv) permit
            Homeflow to receive any commission or other form of compensation that
            any insurance carrier agrees to pay to Homeflow in connection with the
            provision of insurance brokerage services and (v) notify Homeflow in
            writing of any additional individual, managing member, or executive
            officer authorized to act on behalf of an entity with respect to all
            matters directly or indirectly related to any insurance
            issued.&nbsp;</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Your Registration Obligations: </span
        ><span
        >You may be required to register with Homeflow or provide information
            about yourself (</span
        ><span>e.g.</span
        ><span
        >, name and email address) in order to access and use certain features
            of the Service.</span
        ><span>&nbsp;</span
        ><span
        >You agree to provide and maintain true, accurate, current, and
            complete information about yourself as prompted by the Service.
            Registration data and certain other information about you are governed
            by our Privacy Policy</span
        ><span>. </span
        ><span
        >If you are under 18 years of age, you are not authorized to use the
            Service, with or without registering.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>Member Account, Password and Security: </span
        ><span
        >You are responsible for maintaining the confidentiality of your
            password and account details, if any, and are fully responsible for
            any and all activities that occur under your password or account. You
            agree to (a) immediately notify Homeflow of any unauthorized use of
            your password or account or any other breach of security, and (b)
            ensure that you exit from your account at the end of each session when
            accessing the Service. Homeflow will not be liable for any loss or
            damage arising from your failure to comply with this paragraph.</span
        >
      </p>
      <p>
        <span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modifications to
            Service: </span
        ><span
        >Homeflow reserves the right to modify or discontinue, temporarily or
            permanently, the Service (or any part thereof) with or without notice.
            You agree that Homeflow will not be liable to you or to any third
            party for any modification, suspension or discontinuance of the
            Service.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>General Practices Regarding Use and Storage</span
        ><span
        >: You acknowledge that Homeflow may establish general practices and
            limits concerning use of the Service, including the maximum period of
            time that data or other content will be retained by the Service and
            the maximum storage space that will be allotted on Homeflow&rsquo;s or
            its third-party service providers&rsquo; servers on your behalf. You
            agree that Homeflow has no responsibility or liability for the
            deletion or failure to store any data or other content maintained or
            uploaded by the Service. You acknowledge that Homeflow reserves the
            right to terminate accounts that are inactive for an extended period
            of time. You further acknowledge that Homeflow reserves the right to
            change these general practices and limits at any time, in its sole
            discretion, with or without notice.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>Not Legal Advice</span
        ><span
        >: Any information you access via Homeflow or the Service does not
            represent a recommendation, advice, or complete description of any
            products, services, or plan. Coverage is provided by our insurance
            provider partners and is subject to the terms and conditions contained
            within your specific policy. The availability of coverage under your
            specific policy depends on the policy language, the facts of the claim
            and the law of the jurisdiction governing the interpretation of the
            policy. You must not rely on the information on the Service as an
            alternative to legal advice from an attorney nor should you delay
            seeking legal advice, disregard legal advice, or commence or
            discontinue any legal action based on the Service or Service
            Content.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>Benchmarking Data: </span
        ><span
        >Homeflow uses commercially and publicly available benchmarking data.
            Homeflow does not guarantee the accuracy of its benchmarking data.
        </span>
      </p>
      <Typography variant="h4" sx={{ my: 2 }}>
        <span>Conditions of Access and Use</span>
      </Typography>
      <p>
        <span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User Conduct: </span
        ><span
        >You are solely responsible for all code, video, images, information,
            data, text, software, music, sound, photographs, graphics, messages,
            and other materials (&ldquo;content&rdquo;) that you make available to
            Homeflow, including by uploading, posting, publishing, or displaying
            (hereinafter, &ldquo;upload(ing)&rdquo;) via the Service or by
            emailing or otherwise making available to other users of the Service
            (collectively, &ldquo;User Content&rdquo;). The following are examples
            of the kinds of content and/or uses that are illegal or prohibited by
            Homeflow. Homeflow reserves the right to investigate and take
            appropriate legal action against anyone who, in Homeflow&rsquo;s sole
            discretion, violates this provision, including removing the offending
            content from the Service, suspending or terminating the account of
            such violators, and reporting the violator to law enforcement
            authorities. You agree to not use the Service to:</span
        >
      </p>
      <ol start={1}>
        <li>
          <span
          >email or otherwise upload any content that (i) infringes any
              intellectual property or other proprietary rights of any party; (ii)
              you do not have a right to upload under any law or under contractual
              or fiduciary relationships; (iii) contains software viruses or any
              other computer code, files or programs designed to interrupt,
              destroy, or limit the functionality of any computer software or
              hardware or telecommunications equipment; (iv) poses or creates a
              privacy or security risk to any person; (v) constitutes unsolicited
              or unauthorized advertising, promotional materials, commercial
              activities and/or sales, &ldquo;junk mail,&rdquo;
              &ldquo;spam,&rdquo; &ldquo;chain letters,&rdquo; &ldquo;pyramid
              schemes,&rdquo; &ldquo;contests,&rdquo; &ldquo;sweepstakes,&rdquo;
              or any other form of solicitation; (vi) is unlawful, harmful,
              threatening, abusive, harassing, tortious, excessively violent,
              defamatory, vulgar, obscene, pornographic, libelous, invasive of
              another&rsquo;s privacy, hateful, discriminatory, or otherwise
              objectionable; or (vii) in the sole judgment of Homeflow, is
              objectionable or which restricts or inhibits any other person from
              using or enjoying the Service, or which may expose Homeflow or its
              users to any harm or liability of any type;</span
          >
        </li>
        <li>
          <span
          >interfere with or disrupt the Service or servers or networks
              connected to the Service, or disobey any requirements, procedures,
              policies, or regulations of networks connected to the Service;
          </span>
        </li>
        <li>
          <span
          >violate any applicable local, state, national, or international
              law, or any regulations having the force of law;
          </span>
        </li>
        <li>
          <span
          >impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or entity;</span
          >
        </li>
        <li>
          <span
          >solicit personal information from anyone under the age of 18;</span
          >
        </li>
        <li>
          <span
          >harvest or collect email addresses or other contact information of
              other users from the Service by electronic or other means for the
              purposes of sending unsolicited emails or other unsolicited
              communications;</span
          >
        </li>
        <li>
          <span
          >advertise or offer to sell or buy any goods or services for any
              business purpose that is not specifically authorized;</span
          >
        </li>
        <li>
          <span
          >further or promote any criminal activity or enterprise or provide
              instructional information about illegal activities;</span
          >
        </li>
        <li>
          <span
          >obtain or attempt to access or otherwise obtain any content or
              information through any means not intentionally made available or
              provided for through the Service;
          </span>
        </li>
        <li>
          <span
          >circumvent, remove, alter, deactivate, degrade, or thwart any of
              the content protections in or geographic restrictions on any content
              (including Service Content (as defined below)) available on or
              through the Service, including through the use of virtual private
              networks; or</span
          >
        </li>
        <li>
          <span
          >engage in or use any data mining, robots, scraping, or similar data
              gathering or extraction methods. If you are blocked by Homeflow from
              accessing the Service (including by blocking your IP address), you
              agree not to implement any measures to circumvent such blocking
              (e.g., by masking your IP address or using a proxy IP address or
              virtual private network).</span
          >
        </li>
      </ol>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Competitors: </span
        ><span
        >No employee, independent contractor, agent, or affiliate of any
            competing insurance comparison and purchasing company is permitted to
            view, access, or use any portion of the Service without express
            written permission from Homeflow. By viewing, using, or accessing the
            Service, you represent and warrant that you are not a competitor of
            Homeflow or any of its affiliates, or acting on behalf of a competitor
            of Homeflow in using or accessing the Service.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Fees:</span
        ><span
        >&nbsp;To the extent the Service or any portion thereof is made
            available for any fee, you may be required to select a payment plan
            and provide information regarding your credit card or other payment
            instrument. You represent and warrant to Homeflow that such
            information is true and that you are authorized to use the payment
            instrument. You will promptly update your account information with
            Ascend (as defined below) or your chosen insurance provider, as
            applicable, of any changes (for example, a change in your billing
            address or credit card expiration date) that may occur. You agree to
            pay Homeflow the amount that is specified in the payment plan in
            accordance with the terms of such plan and these Terms of Service.
            &nbsp;If your payment plan includes an ongoing subscription that is
            automatically renewed periodically, you hereby authorize Homeflow
            (through Ascend or your chosen insurance provider) to bill your
            payment instrument in advance on such periodic basis in accordance
            with the terms of the applicable payment plan until you terminate your
            account, and you further agree to pay any charges so incurred. If you
            dispute any charges you must let Homeflow know within sixty (60) days
            after the date that Homeflow charges you, or within such longer period
            of time as may be required under applicable law. You will be
            responsible for all taxes associated with the Service, other than
            taxes based on Homeflow&rsquo;s net income.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Payment Processing:</span
        ><span
        >&nbsp;Notwithstanding any amounts owed to Homeflow hereunder,
            HOMEFLOW DOES NOT PROCESS PAYMENT FOR ANY SERVICES. To facilitate
            payment for the Service via bank account, credit card, or debit card,
            we use Slash Eureka, Inc. dba Ascend, and its affiliates
            (&ldquo;Ascend&rdquo;), a third-party payment processor. These payment
            processing services are provided by Ascend and are subject
            to&nbsp;the&nbsp;Ascend terms and conditions and other policies
            available at https://www.useascend.com/terms-and-conditions and
            Ascend&rsquo;s Privacy Policy available at:
            https://www.useascend.com/privacy-policy (collectively, the
            &quot;Ascend Agreements&quot;). By agreeing to these Terms of Service,
            users that use the payment functions of the Service also agree to be
            bound by the Ascend Agreements for the payment function the user is
            using, as the same may be modified by Ascend from time to time. You
            hereby authorize Ascend to store and continue billing your specified
            payment method even after such payment method has expired, to avoid
            interruptions in payment for your use of the Service. Please contact
            Ascend for more information. Homeflow assumes no liability or
            responsibility for any payments you make through the Service.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Refunds and Cancellations</span
        ><span
        >: Payments made by you hereunder are final and non-refundable, unless
            otherwise determined by Homeflow or your chosen insurance provider, as
            applicable. Please refer to your policy documents for your specific
            cancellation and refund policy and procedure. You may also cancel your
            policy online by emailing us at: </span
        ><span
        ><a href="mailto:support@homeflow.me"
          >support@homeflow.me</a
          ></span
        ><span>. </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Commercial Use: </span
        ><span
        >Unless otherwise expressly authorized herein or in the Service, you
            agree not to display, distribute, license, perform, publish,
            reproduce, duplicate, copy, create derivative works from, modify,
            sell, resell, grant access to, transfer, or otherwise use or exploit
            any portion of the Service for any commercial purposes. The Service is
            for your personal use.
        </span>
      </p>
      <Typography variant="h4" sx={{ my: 2 }}>
        Mobile Services and Software
      </Typography>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Mobile Services: </span
        ><span
        >The Service includes certain services that are available via a mobile
            device, including (i) the ability to upload content to the Service via
            a mobile device, (ii) the ability to browse the Service and the Site
            from a mobile device, and (iii) the ability to access certain features
            and content through Mobile Apps (collectively, the &ldquo;Mobile
            Services&rdquo;). To the extent you access the Service through a
            mobile device, your wireless service carrier&rsquo;s standard charges,
            data rates, and other fees may apply. In addition, downloading,
            installing, or using certain Mobile Services may be prohibited or
            restricted by your carrier, and not all Mobile Services may work with
            all carriers or devices.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Telephonic Communications Services</span
        ><span
        >: &nbsp;By using the Service and providing us with your telephone
            number(s), you are consenting to be contacted by Homeflow or its
            affiliates or partners by telephone (including on a recorded line),
            automated calling, automated telephone dialing system calling,
            automated system calling, artificial voice or pre-recorded calling,
            text message, SMS and/or MMS message, fax, or other telephonic or
            electronic means for marketing, solicitation, informational or another
            purposes, even if your &nbsp;telephone number(s) is registered on the
            National Do Not Call List, a state Do not Call List, or the internal
            Do Not Call List of Homeflow or its affiliates or partners. You may be
            required to respond to an initial call or message as instructed to
            complete your registration and confirm enrollment to receive such
            calls, texts or other telephonic communications. &nbsp;You do not have
            to consent to receive calls or text messages from Homeflow or its
            affiliates or partners for marketing or solicitation purposes &nbsp;to
            purchase Homeflow&rsquo;s products or services. In the event you no
            longer wish to receive such calls, text messages or other telephonic
            communications, you agree to notify Homeflow or its affiliates or
            partners, as applicable, directly. In the event you change or
            deactivate your telephone number, you agree to promptly update your
            Homeflow account information to ensure that your messages are not sent
            to a person that acquires your old telephone number.
        </span>
      </p>
      <p>
        <span
        >There is no additional charge for telephonic communications, but your
            carrier&rsquo;s standard message and data rates apply to any calls,
            text messages, SMS or MMS messages you send or receive. Your carrier
            may prohibit or restrict certain mobile features and certain mobile
            features may be incompatible with your carrier or mobile device. We
            are not liable for any delays in the receipt of, or any failures to
            receive, any calls, text messages, SMS or MMS messages, as delivery is
            subject to effective transmission by your mobile carrier and
            compatibility of your mobile device. Please contact your mobile
            carrier if you have any questions regarding these issues or your
            mobile data and messaging plan.</span
        >
      </p>
      <p>
        <span
        >By reply to any text, SMS or MMS message you receive from us, you may
            text &ldquo;STOP&rdquo; to cancel or &ldquo;HELP&rdquo; for customer
            support information. If you choose to cancel text, SMS or MMS messages
            from us, you agree to receive a final message from us confirming your
            cancellation.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Ownership; Restrictions:</span
        ><span
        >&nbsp;The technology and software underlying the Service or
            distributed in connection therewith are the property of Homeflow, its
            affiliates, and its licensors (the &ldquo;Software&rdquo;). You agree
            not to copy, modify, create a derivative work of, reverse engineer,
            reverse assemble, or otherwise attempt to discover any source code,
            sell, assign, sublicense, or otherwise transfer any right in the
            Software. Any rights not expressly granted herein are reserved by
            Homeflow.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Special Notice for International Use; Export Controls: </span
        ><span
        >Homeflow is headquartered in the United States. Whether inside or
            outside of the United States, you are solely responsible for ensuring
            compliance with the laws of your specific jurisdiction. Software
            available in connection with the Service and the transmission of
            applicable data, if any, is subject to United States export controls.
            No Software may be downloaded from the Service or otherwise exported
            or re-exported in violation of U.S. export laws. Downloading,
            accessing or using the Software or Services is at your sole
            risk.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        Intellectual Property Rights
      </Typography>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Service Content:&nbsp;</span>
        <span
        >You acknowledge and agree that the Service may contain content or
            features (&ldquo;Service Content&rdquo;) that are protected by
            copyright, patent, trademark, trade secret, or other proprietary
            rights and laws. Except as expressly authorized by Homeflow, you agree
            not to modify, copy, frame, scrape, rent, lease, loan, sell,
            distribute, or create derivative works based on the Service or the
            Service Content, in whole or in part, except that the foregoing does
            not apply to your own User Content (as defined below) that you upload
            to or make available through the Service in accordance with these
            Terms of Service. Any use of the Service or the Service Content other
            than as specifically authorized herein is strictly prohibited.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Trademarks:</span
        ><span
        >&nbsp;The Homeflow name and logos are trademarks and service marks of
            Homeflow (collectively the &ldquo;Homeflow Trademarks&rdquo;). Other
            company, product, and service names and logos used and displayed via
            the Service may be trademarks or service marks of their respective
            owners who may or may not endorse or be affiliated with or connected
            to Homeflow. Nothing in these Terms of Service or the Service should
            be construed as granting, by implication, estoppel, or otherwise, any
            license or right to use any of Homeflow Trademarks displayed on the
            Service, without our prior written permission in each instance. All
            goodwill generated from the use of Homeflow Trademarks will inure to
            our exclusive benefit.</span
        >
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>Third-Party Material: </span
        ><span
        >Under no circumstances will Homeflow be liable in any way for any
            content or materials of any third parties (including users), including
            for any errors or omissions in any content, or for any loss or damage
            of any kind incurred as a result of the use of any such content. You
            acknowledge that Homeflow does not pre-screen content, but that
            Homeflow and its designees will have the right (but not the
            obligation) in their sole discretion to refuse or remove any content
            that is available via the Service. Without limiting the foregoing,
            Homeflow and its designees will have the right to remove any content
            that violates these Terms of Service or is deemed by Homeflow, in its
            sole discretion, to be otherwise objectionable. You agree that you
            must evaluate, and bear all risks associated with, the use of any
            content, including any reliance on the accuracy, completeness, or
            usefulness of such content.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span>User Content:&nbsp;</span>
        <span
        >You represent and warrant that you own all right, title and interest
            in and to such User Content, including all copyrights and rights of
            publicity contained therein. You hereby grant Homeflow and its
            affiliated companies, successors, and assigns a non-exclusive,
            worldwide, royalty-free, fully paid-up, transferable, sublicensable
            (directly and indirectly through multiple tiers), perpetual, and
            irrevocable license to copy, display, upload, perform, distribute,
            store, modify, and otherwise use your User Content in connection with
            the operation of the Service and the promotion, advertising or
            marketing of the foregoing in any form, medium or technology now known
            or later developed. You assume all risk associated with your User
            Content and the transmission of your User Content, and you have sole
            responsibility for the accuracy, quality, legality and appropriateness
            of your User Content.</span
        >
      </p>
      <p>
        <span
        >You hereby authorize Homeflow and its third-party service providers
            to derive statistical and usage data relating to your use of the
            Service (&ldquo;Usage Data&rdquo;). We may use Usage Data for any
            purpose in accordance with applicable law and our Privacy
            Policy.</span
        >
      </p>
      <p>
        <span
        >Any questions, comments, suggestions, ideas, feedback, reviews, or
            other information about the Service (&ldquo;Submissions&rdquo;),
            provided by you to Homeflow are non-confidential and Homeflow will be
            entitled to the unrestricted use and dissemination of these
            Submissions for any purpose, commercial or otherwise, without
            acknowledgment, attribution, or compensation to you.
        </span>
      </p>
      <p>
        <span
        >You acknowledge and agree that Homeflow may preserve User Content and
            may also disclose User Content if required to do so by law or in the
            good faith belief that such preservation or disclosure is reasonably
            necessary to: (a) comply with legal process, applicable laws, or
            government requests; (b) enforce these Terms of Service; (c) respond
            to claims that any content violates the rights of third parties; or
            (d) protect the rights, property, or personal safety of Homeflow, its
            users, or the public. You understand that the technical processing and
            transmission of the Service, including your User Content, may involve
            (i) transmissions over various networks; and (ii) changes to conform
            and adapt to technical requirements of connecting networks or devices.
        </span>
      </p>
      <Typography variant="h4" sx={{ my: 1 }}>Third-Party Services and Websites</Typography>
      <p>
        <span
        >The Service may provide links or other access to services, sites,
            technology, and resources that are provided or otherwise made
            available by third parties (the &ldquo;Third-Party Services&rdquo;).
            Your access and use of the Third-Party Services may also be subject to
            additional terms and conditions, privacy policies, or other agreements
            with such third party, and you may be required to authenticate to or
            create separate accounts to use Third-Party Services on the websites
            or via the technology platforms of their respective providers. Some
            Third-Party Services will provide us with access to certain
            information that you have provided to third parties, including through
            such Third-Party Services, and we will use, store and disclose such
            information in accordance with our Privacy Policy. For more
            information about the implications of activating Third-Party Services
            and our use, storage and disclosure of information related to you and
            your use of such Third-Party Services within the Service, please see
            our Privacy Policy. Homeflow has no control over and is not
            responsible for such Third-Party Services, including for the accuracy,
            availability, reliability, or completeness of information shared by or
            available through Third-Party Services, or on the privacy practices of
            Third-Party Services. We encourage you to review the privacy policies
            of the third parties providing Third-Party Services prior to using
            such services. You, and not Homeflow, will be responsible for any and
            all costs and charges associated with your use of any Third-Party
            Services. Homeflow enables these Third-Party Services merely as a
            convenience and the integration or inclusion of such Third-Party
            Services does not imply an endorsement or recommendation. Any dealings
            you have with third parties while using the Service are between you
            and the third party. Homeflow will not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to be
            caused by or in connection with use of or reliance on any Third-Party
            Services.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 1 }}>Indemnification</Typography>
      <p>
        <span
        >To the extent permitted under applicable law, you agree to defend,
            indemnify, and hold harmless Homeflow, its affiliates, and its and
            their respective officers, employees, directors, service providers,
            licensors, and agents (collectively, the &ldquo;Homeflow
            Parties&rdquo;) from any and all losses, damages, expenses, including
            reasonable attorneys&rsquo; fees, rights, claims, actions of any kind,
            and injury (including death) arising out of or relating to your use of
            the Service, any User Content, your connection to the Service, your
            violation of these Terms of Service, or your violation of any rights
            of another. Homeflow will provide notice to you of any such claim,
            suit, or proceeding. Homeflow reserves the right to assume the
            exclusive defense and control of any matter which is subject to
            indemnification under this section, and you agree to cooperate with
            any reasonable requests assisting Homeflow&rsquo;s defense of such
            matter. You may not settle or compromise any claim against the
            Homeflow Parties without Homeflow&rsquo;s written consent.
        </span>
      </p>
      <Typography variant="h4" sx={{ my: 1 }}>Disclaimer of Warranties</Typography>
      <p>
        <span
        >YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED
            ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. THE
            HOMEFLOW PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, AND NON-INFRINGEMENT.</span
        >
      </p>
      <p>
        <span
        >THE HOMEFLOW PARTIES MAKE NO WARRANTY THAT (A) THE SERVICE WILL MEET
            YOUR REQUIREMENTS; (B) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE
            USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF
            ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
            OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 1 }}>Limitation of Liability</Typography>
      <p>
        <span
        >YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE HOMEFLOW PARTIES WILL NOT
            BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING DAMAGES
            FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
            THE HOMEFLOW PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
            LIABILITY, OR OTHERWISE, RESULTING FROM: (A) THE USE OR THE INABILITY
            TO USE THE SERVICE; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS
            AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES
            PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED
            INTO THROUGH OR FROM THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR
            ALTERATION OF YOUR TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF
            ANY THIRD PARTY ON THE SERVICE; OR (E) ANY OTHER MATTER RELATING TO
            THE SERVICE. IN NO EVENT WILL THE HOMEFLOW PARTIES&rsquo; TOTAL
            LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED
            THE AMOUNT YOU HAVE PAID HOMEFLOW IN THE LAST SIX (6) MONTHS, OR, IF
            GREATER, ONE HUNDRED DOLLARS ($100).
        </span>
      </p>
      <p>
        <span
        >SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
            CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
            INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
            LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE
            WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
            SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY
            IS TO DISCONTINUE USE OF THE SERVICE.
        </span>
      </p>
      <p><span></span></p>
      <p>
        <span
        >IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
            &ldquo;INDEMNIFICATION&rdquo;, &ldquo;DISCLAIMER OF WARRANTIES&rdquo;
            AND &ldquo;LIMITATION OF LIABILITY&rdquo; ARE INTENDED TO BE ONLY AS
            BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF
            ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF
            THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION WILL NOT
            AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
            SECTIONS.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2 }}>
        Dispute Resolution By Binding Arbitration
      </Typography>
      <p>
        <span>
          PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
        </span>
      </p>
      <ol start={1}>
        <li>
          <span>Agreement to Arbitrate</span>
        </li>
      </ol>
      <p>
        <span
        >This Dispute Resolution by Binding Arbitration section is referred to
            in these Terms of Service as the &ldquo;Arbitration Agreement.&rdquo;
            You agree that any and all disputes or claims that have arisen or may
            arise between you and Homeflow, whether arising out of or relating to
            these Terms of Service (including any alleged breach thereof), the
            Service, any advertising, or any aspect of the relationship or
            transactions between us, will be resolved exclusively through final
            and binding arbitration, rather than a court, in accordance with the
            terms of this Arbitration Agreement, except that you may assert
            individual claims in small claims court, if your claims qualify.
            Further, this Arbitration Agreement does not preclude you from
            bringing issues to the attention of federal, state, or local agencies,
            and such agencies can, if the law allows, seek relief against us on
            your behalf. You agree that, by entering into these Terms of Service,
            you and Homeflow are each waiving the right to a trial by jury or to
            participate in a class action. Your rights will be determined by a
            neutral arbitrator, not a judge or jury. The Federal Arbitration Act
            governs the interpretation and enforcement of this Arbitration
            Agreement.</span
        >
      </p>
      <ol start={2}>
        <li>
          <span
          >Prohibition of Class and Representative Actions and
              Non-Individualized Relief</span
          >
        </li>
      </ol>
      <p>
        <span
        >YOU AND HOMEFLOW AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
            OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
            UNLESS BOTH YOU AND HOMEFLOW AGREE OTHERWISE, THE ARBITRATOR MAY NOT
            CONSOLIDATE OR JOIN MORE THAN ONE PERSON&rsquo;S OR PARTY&rsquo;S
            CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
            REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD
            RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY
            IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY&rsquo;S
            INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE
            ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO
            THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
        </span>
      </p>
      <ol start={3}>
        <li>
          <span>Pre-Arbitration Dispute Resolution</span>
        </li>
      </ol>
      <p>
        <span
        >Homeflow is always interested in resolving disputes amicably and
            efficiently, and most customer concerns can be resolved quickly and to
            the customer&rsquo;s satisfaction by emailing customer support at
            support@homeflow.me. If such efforts prove unsuccessful, a party who
            intends to seek arbitration must first send to the other, by certified
            mail, a written Notice of Dispute (&ldquo;Notice&rdquo;). The Notice
            to Homeflow should be sent to PO Box 193484, San Francisco, CA 94119
            (&ldquo;Notice Address&rdquo;). The Notice must (i) describe the
            nature and basis of the claim or dispute and (ii) set forth the
            specific relief sought. If Homeflow and you do not resolve the claim
            within sixty (60) calendar days after the Notice is received, you or
            Homeflow may commence an arbitration proceeding. During the
            arbitration, the amount of any settlement offer made by Homeflow or
            you will not be disclosed to the arbitrator until after the arbitrator
            determines the amount, if any, to which you or Homeflow is
            entitled.</span
        >
      </p>
      <ol start={4}>
        <li>
          <span>Arbitration Procedures</span>
        </li>
      </ol>
      <p>
        <span
        >Arbitration will be conducted by a neutral arbitrator in accordance
            with the American Arbitration Association&rsquo;s (&ldquo;AAA&rdquo;)
            rules and procedures, including the AAA&rsquo;s Consumer Arbitration
            Rules (collectively, the &ldquo;AAA Rules&rdquo;), as modified by this
            Arbitration Agreement. For information on the AAA, please visit its
            website, http://www.adr.org. Information about the AAA Rules and fees
            for consumer disputes can be found at the AAA&rsquo;s consumer
            arbitration page, https://www.adr.org/consumer. If there is any
            inconsistency between any term of the AAA Rules and any term of this
            Arbitration Agreement, the applicable terms of this Arbitration
            Agreement will control unless the arbitrator determines that the
            application of the inconsistent Arbitration Agreement terms would not
            result in a fundamentally fair arbitration. The arbitrator must also
            follow the provisions of these Terms of Service as a court would. All
            issues are for the arbitrator to decide, including issues relating to
            the scope, enforceability, and arbitrability of this Arbitration
            Agreement. Although arbitration proceedings are usually simpler and
            more streamlined than trials and other judicial proceedings, the
            arbitrator can award the same damages and relief on an individual
            basis that a court can award to an individual under these Terms of
            Service and applicable law. Decisions by the arbitrator are
            enforceable in court and may be overturned by a court only for very
            limited reasons.</span
        >
      </p>
      <p>
        <span
        >Unless Homeflow and you agree otherwise, any arbitration hearings
            will take place in a reasonably convenient location for both parties
            with due consideration of their ability to travel and other pertinent
            circumstances. If the parties are unable to agree on a location, the
            determination will be made by AAA. If your claim is for $10,000 or
            less, Homeflow agrees that you may choose whether the arbitration will
            be conducted solely on the basis of documents submitted to the
            arbitrator, through a telephonic hearing, or by an in-person hearing
            as established by the AAA Rules. If your claim exceeds $10,000, the
            right to a hearing will be determined by the AAA Rules. Regardless of
            the manner in which the arbitration is conducted, the arbitrator will
            issue a reasoned written decision sufficient to explain the essential
            findings and conclusions on which the award is based.</span
        >
      </p>
      <ol start={5}>
        <li>
          <span>Costs of Arbitration</span>
        </li>
      </ol>
      <p>
        <span
        >Payment of all filing, administration, and arbitrator fees
            (collectively, the &ldquo;Arbitration Fees&rdquo;) will be governed by
            the AAA Rules, unless otherwise provided in this Arbitration
            Agreement. &nbsp;To the extent any Arbitration Fees are not
            specifically allocated to either Homeflow or you under the AAA Rules,
            Homeflow and you shall split them equally; provided that if you are
            able to demonstrate to the arbitrator that you are economically unable
            to pay your portion of such Arbitration Fees or if the arbitrator
            otherwise determines for any reason that you should not be required to
            pay your portion of any Arbitration Fees, Homeflow will pay your
            portion of such fees. In addition, if you demonstrate to the
            arbitrator that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, Homeflow will pay as much of the
            Arbitration Fees as the arbitrator deems necessary to prevent the
            arbitration from being cost-prohibitive. Any payment of
            attorneys&rsquo; fees will be governed by the AAA Rules.</span
        >
      </p>
      <ol start={6}>
        <li>
          <span>Confidentiality</span>
        </li>
      </ol>
      <p>
        <span
        >All aspects of the arbitration proceeding, and any ruling, decision,
            or award by the arbitrator, will be strictly confidential for the
            benefit of all parties.</span
        >
      </p>
      <ol start={7}>
        <li><span>Severability</span></li>
      </ol>
      <p>
        <span
        >If a court or the arbitrator decides that any term or provision of
            this Arbitration Agreement (other than the subsection (b) above titled
            &ldquo;Prohibition of Class and Representative Actions and
            Non-Individualized Relief&rdquo; above) is invalid or unenforceable,
            the parties agree to replace such term or provision with a term or
            provision that is valid and enforceable and that comes closest to
            expressing the intention of the invalid or unenforceable term or
            provision, and this Arbitration Agreement will be enforceable as so
            modified. If a court or the arbitrator decides that any of the
            provisions of subsection (b) above titled &ldquo;Prohibition of Class
            and Representative Actions and Non-Individualized Relief&rdquo; are
            invalid or unenforceable, then the entirety of this Arbitration
            Agreement will be null and void, unless such provisions are deemed to
            be invalid or unenforceable solely with respect to claims for public
            injunctive relief. The remainder of these Terms of Service will
            continue to apply.</span
        >
      </p>
      <ol start={8}>
        <li>
          <span>Future Changes to Arbitration Agreement</span>
        </li>
      </ol>
      <p>
        <span
        >Notwithstanding any provision in these Terms of Service to the
            contrary, Homeflow agrees that if it makes any future change to this
            Arbitration Agreement (other than a change to the Notice Address)
            while you are a user of the Service, you may reject any such change by
            sending Homeflow written notice within thirty (30) calendar days of
            the change to the Notice Address provided above. By rejecting any
            future change, you are agreeing that you will arbitrate any dispute
            between us in accordance with the language of this Arbitration
            Agreement as of the date you first accepted these Terms of Service (or
            accepted any subsequent changes to these Terms of Service).</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        Termination
      </Typography>
      <p>
        <span
        >You agree that Homeflow, in its sole discretion, may suspend or
            terminate your account (or any part thereof) or use of the Service and
            remove and discard any content within the Service, for any reason,
            including for lack of use or if Homeflow believes that you have
            violated or acted inconsistently with the letter or spirit of these
            Terms of Service. Any suspected fraudulent, abusive, or illegal
            activity that may be grounds for termination of your use of the
            Service, may be referred to appropriate law enforcement authorities.
            Homeflow may also in its sole discretion and at any time discontinue
            providing the Service, or any part thereof, with or without notice.
            You agree that any termination of your access to the Service under any
            provision of these Terms of Service may be effected without prior
            notice, and acknowledge and agree that Homeflow may immediately
            deactivate or delete your account and all related information and
            files in your account and/or bar any further access to such files or
            the Service. Further, you agree that Homeflow will not be liable to
            you or any third party for any termination of your access to the
            Service.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        User Disputes
      </Typography>
      <p>
        <span
        >You agree that you are solely responsible for your interactions with
            any other user in connection with the Service, and Homeflow will have
            no liability or responsibility with respect thereto. Homeflow reserves
            the right, but has no obligation, to become involved in any way with
            disputes between you and any other user of the Service.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        General
      </Typography>
      <p>
        <span
        >These Terms of Service (together with the terms incorporated by
            reference herein) constitute the entire agreement between you and
            Homeflow governing your access and use of the Service, and supersede
            any prior agreements between you and Homeflow with respect to the
            Service. You also may be subject to additional terms and conditions
            that may apply when you use Third-Party Services, third-party content
            or third-party software. These Terms of Service will be governed by
            the laws of the State of California without regard to its conflict of
            law provisions. With respect to any disputes or claims not subject to
            arbitration, as set forth above, you and Homeflow submit to the
            personal and exclusive jurisdiction of the state and federal courts
            located within San Francisco, California. The failure of Homeflow to
            exercise or enforce any right or provision of these Terms of Service
            will not constitute a waiver of such right or provision. If any
            provision of these Terms of Service is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties&rsquo; intentions
            as reflected in the provision, and the other provisions of these Terms
            of Service remain in full force and effect. You agree that regardless
            of any statute or law to the contrary, any claim or cause of action
            arising out of or related to use of the Service or these Terms of
            Service must be filed within one (1) year after such claim or cause of
            action arose or be forever barred. A printed version of these Terms of
            Service and of any notice given in electronic form will be admissible
            in judicial or administrative proceedings based upon or relating to
            these Terms of Service to the same extent and subject to the same
            conditions as other business documents and records originally
            generated and maintained in printed form. You may not assign these
            Terms of Service without the prior written consent of Homeflow, but
            Homeflow may assign or transfer these Terms of Service, in whole or in
            part, without restriction. The section titles in these Terms of
            Service are for convenience only and have no legal or contractual
            effect. As used in these Terms of Service, the words
            &ldquo;include&rdquo; and &ldquo;including,&rdquo; and variations
            thereof, will not be deemed to be terms of limitation, but rather will
            be deemed to be followed by the words &ldquo;without
            limitation.&rdquo; Notices to you may be made via either email or
            regular mail. The Service may also provide notices to you of changes
            to these Terms of Service or other matters by displaying notices or
            links to notices generally on the Service. Homeflow will not be in
            default hereunder by reason of any failure or delay in the performance
            of its obligations where such failure or delay is due to civil
            disturbances, riot, epidemic, hostilities, war, terrorist attack,
            embargo, natural disaster, acts of God, flood, fire, sabotage,
            fluctuations or unavailability of electrical power, network access or
            equipment, or any other circumstances or causes beyond
            Homeflow&rsquo;s reasonable control.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        Notice for California Users
      </Typography>
      <p>
        <span
        >Under California Civil Code Section 1789.3, users of the Service from
            California are entitled to the following specific consumer rights
            notice: The Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs may be
            contacted (a) via email at dca@dca.ca.gov; (b) in writing at:
            Department of Consumer Affairs, Consumer Information Division, 1625
            North Market Blvd., Suite N 112, Sacramento, CA 95834; or (c) by
            telephone at (800) 952-5210 or (800) 326-2297 (TDD). Sacramento-area
            consumers may call (916) 445-1254 or (916) 928-1227 (TDD). You may
            contact us at Rentr Insurance Services, LLC, PO Box 193484, San
            Francisco, CA 94119 or at (415) 322-0311.</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        U.S. Government Restricted Rights
      </Typography>
      <p>
        <span
        >The Service is made available to the U.S. government with
            &ldquo;RESTRICTED RIGHTS.&rdquo; Use, duplication, or disclosure by
            the U.S. government is subject to the restrictions contained in 48 CFR
            52.227-19 and 48 CFR 252.227-7013 et seq. or its successor. Access or
            use of the Service (including the Software) by the U.S. government
            constitutes acknowledgement of our proprietary rights in the Service
            (including the Software).</span
        >
      </p>
      <Typography variant="h4" sx={{ my: 2}}>
        Questions? Concerns? Suggestions?
      </Typography>
      <p>
        <span>Please contact us at </span>
        <span><a href="mailto:support@homeflow.me">support@homeflow.me</a></span>
        <span>&nbsp;or</span><span>&nbsp;</span>
        <span>
          PO Box 193484, San Francisco, CA 94119 to report any violations of
          these Terms of Service or to pose any questions regarding these Terms
          of Service or the Service.
        </span>
      </p>
      <p style={{ marginTop: 2, textAlign: 'center' }}>
        <span
        >*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*</span
        >
      </p>
    </Box>
  </Box>
);
