import { useCallback } from 'react';

import useAnalyticsContext from './context/useAnalyticsContext';
import { Events, PageLocations } from './index';

const useAnalytics = () => {
  const { analytics } = useAnalyticsContext();

  const identifyUser = useCallback(
    (userId: string, firstName: string, lastName: string, birthday: string) => {
      const eventProps = {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: birthday,
      };
      analytics?.identify(userId, { ...eventProps });
    },
    [analytics]
  );

  const pageViewed = useCallback(
    (name: string, category = 'App') => {
      analytics?.page(category, name);
    },
    [analytics]
  );

  const trackButtonClicked = useCallback(
    (text: string, page: string, location?: PageLocations) => {
      const eventProps = {
        text,
        page,
        location,
      };
      analytics?.track(Events.ButtonClicked, { ...eventProps });
    },
    [analytics]
  );

  const trackLeadCaptured = useCallback(
    (email: string, phone: string) => {
      analytics?.track(Events.LeadCaptured, { email, phone_number: phone });
      analytics?.identify({ email, phone_number: phone });
    },
    [analytics]
  );

  return {
    identifyUser,
    pageViewed,
    trackButtonClicked,
    trackLeadCaptured,
  };
};

export default useAnalytics;
