import { Box, Container, Theme, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/logo.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, WEBSITE_URL } from 'rentr-constants';

export const LandingNavbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: 1000,
      width: '100vw'
    }}>
      <Container sx={{ display: 'flex', flex: 1, maxWidth: { xs: '100vw', md: '1200px' }, py: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <a href={WEBSITE_URL}>
          <Box component={Logo} sx={{ width: { xs: 120, md: 140 } }}/>
        </a>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: { xs: 2, md: 6 }, textTransform: 'uppercase' }}>
          <a href={`${WEBSITE_URL}/about`}>
            <Typography sx={{ fontWeight: 'bold' }}>
              About
            </Typography>
          </a>
          <a href={`${WEBSITE_URL}/learn`}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Learn
            </Typography>
          </a>
          <Link to={ROUTES.CONTACT}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Contact
            </Typography>
          </Link>
          <Link to={ROUTES.LOGIN}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Agents
            </Typography>
          </Link>
        </Box>

        <Box
          component="button"
          onClick={() => setOpen(true)}
          sx={{
            display: { xs: 'flex', md: 'none'},
            p: 1.5,
            backgroundColor: t => t.palette.primary.main,
            border: 0,
            borderRadius: 2,
            flexDirection: 'column',
            gap: '6px',
            transition: 'all 0.2s ease-in-out',
            '&:hover, &:active': { backgroundColor: t => t.palette.primary.dark }
          }}
        >
          <Box sx={{ width: 20, height: 2, backgroundColor: '#fff' }}/>
          <Box sx={{ width: 20, height: 2, backgroundColor: '#fff' }}/>
          <Box sx={{ width: 20, height: 2, backgroundColor: '#fff' }}/>
        </Box>
      </Container>

      <Box
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          pointerEvents: open ? 'auto' : 'none',
        }}
      >
        <Box
          display="flex"
          gap={2}
          flexDirection="column"
          sx={{
            zIndex: 1,
            boxShadow: '0px 0px 20px rgba(53, 64, 82, 0.1)',
            padding: 4,
            backgroundColor: '#fff',
            width: '50%',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            height: '100%',
            transition: 'all 0.2s ease-in-out',
            transform: `translateX(${open ? '0' : '-100%'})`
          }}
        >
          <a href={WEBSITE_URL}>
            <Box component={Logo} sx={{ width: 160, mb: 2 }}/>
          </a>
          <a href={`${WEBSITE_URL}/about`}>
            <Typography
              variant="body2"
              sx={{ pb: 1, borderBottom: (t: Theme) => `1px solid ${t.palette.grey['500']}` }}
            >
              About
            </Typography>
          </a>
          <a href={`${WEBSITE_URL}/learn`}>
            <Typography
              variant="body2"
              sx={{ pb: 1, borderBottom: (t: Theme) => `1px solid ${t.palette.grey['500']}` }}
            >
              Learn
            </Typography>
          </a>
          <Link to={ROUTES.CONTACT}>
            <Typography
              variant="body2"
              sx={{ pb: 1, borderBottom: (t: Theme) => `1px solid ${t.palette.grey['500']}` }}
            >
              Contact
            </Typography>
          </Link>
          <Link to={ROUTES.LOGIN}>
            <Typography
              variant="body2"
              sx={{ pb: 1, borderBottom: (t: Theme) => `1px solid ${t.palette.grey['500']}` }}
            >
              Agents
            </Typography>
          </Link>
        </Box>

        <Box
          onClick={() => setOpen(false)}
          sx={{
            opacity: open ? 0.4 : 0,
            top: 0,
            left: 0,
            position: 'absolute',
            backgroundColor: '#000',
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        />
      </Box>
    </Box>
  );
};
