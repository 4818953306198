import { Box, RadioGroup, TextField } from '@mui/material';
import AddressAutocomplete, {
  TSelectedSuggestion,
} from 'components/AddressAutocomplete';
import { AddressDto } from 'dtos/address';
import { isArray, isUndefined } from 'lodash';
import { useVerifyAddress } from 'queries/useVerifyAddress';
import * as React from 'react';
import useState from 'react-usestateref';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  differentMailingAddress?: boolean;
  mailingAddress?: AddressDto;
  onSubmit: (
    differentMailingAddress: boolean,
    mailingAddress?: AddressDto
  ) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<boolean>, 'onSelect'>> = [
  {
    label: 'YES',
    value: true,
  },
  {
    label: 'NO',
    value: false,
  },
];

const addressSchema = yup.object().shape({
  addressLine1: yup.string().required('Invalid address'),
  addressLine2: yup.string().nullable(),
  city: yup.string().required('Invalid address'),
  state: yup.string().required('Invalid address'),
  zipCode: yup.string().required('Invalid address'),
});

const mapToAddress = (data: TSelectedSuggestion): AddressDto => ({
  addressLine1: `${data.streetNumber} ${data.street}`,
  addressLine2: data.suite,
  city: data.city as string,
  state: data.state as string,
  zipCode: data.zipCode as string,
});

export function MailingAddress({
  differentMailingAddress: differentMailingAddressProp,
  mailingAddress: _mailingAddressProp,
  onSubmit,
  updating = false,
}: Props) {
  const [
    differentMailingAddress,
    setDifferentMailingAddress,
    differentMailingAddressRef,
  ] = useState<boolean>(differentMailingAddressProp);
  const [error, setError] = React.useState<string>();
  const [displaySuiteField, setDisplaySuiteField] =
    React.useState<boolean>(false);
  const [suite, setSuite, suiteRef] = useState<string>('');
  const [addressValidated, setAddressValidated] =
    React.useState<boolean>(false);
  const selectionRef = React.useRef<TSelectedSuggestion | null>(null);
  const suiteInputRef = React.useRef<HTMLInputElement | null>(null);

  const { mutate: verifyAddressWithSmarty, isLoading: isVerifying } =
    useVerifyAddress({
      onSuccess: (response) => {
        const matchCode =
          isArray(response) && response?.length
            ? response[0].analysis?.dpv_match_code
            : 'Y';
        switch (matchCode) {
          case 'D':
            setDisplaySuiteField(true);
            break;
          default:
            setAddressValidated(true);
        }
      },
    });

  // Focus on suite field
  React.useEffect(
    function focusOnSuite() {
      if (displaySuiteField && suiteInputRef.current) {
        suiteInputRef.current.focus();
      }
    },
    [displaySuiteField, suiteInputRef]
  );

  React.useEffect(
    function handleSuiteUpdated() {
      if (displaySuiteField) {
        const address = mapToAddress(selectionRef.current);
        verifyAddressWithSmarty({ ...address, addressLine2: suite });
      }
    },
    [displaySuiteField, suite, verifyAddressWithSmarty]
  );

  // Handle selecting autocomplete
  const handleSelect = React.useCallback(
    async (selection: TSelectedSuggestion) => {
      try {
        setError(null);
        selectionRef.current = selection;
        const address = mapToAddress(selection);
        await addressSchema.validate(address);
        verifyAddressWithSmarty(address);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    },
    [verifyAddressWithSmarty]
  );

  const handleNext = React.useCallback(() => {
    if (differentMailingAddressRef.current) {
      const address = mapToAddress(selectionRef.current);
      if (suiteRef.current.length) {
        address.addressLine2 = suiteRef.current;
      }
      onSubmit(differentMailingAddressRef.current, address);
    } else {
      onSubmit(differentMailingAddressRef.current);
    }
  }, [differentMailingAddressRef, suiteRef, onSubmit]);

  const handleClear = React.useCallback(() => {
    selectionRef.current = null;
    setAddressValidated(false);
    setDisplaySuiteField(false);
    setSuite('');
  }, [setDisplaySuiteField, setSuite]);

  return (
    <SectionWrapper
      title="Do you have a different mailing address?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating || isVerifying}
      disableButtons={
        isUndefined(differentMailingAddress) ||
        (differentMailingAddress && (!addressValidated || !!error))
      }
      containerSx={{ maxWidth: { md: 800 } }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          sx={{ gap: { xs: 2, md: 4 }, width: { xs: '240px', md: '300px' } }}
        >
          {Options.map((option, idx) => (
            <RadioOption
              onSelect={setDifferentMailingAddress}
              selected={differentMailingAddress === option.value}
              key={idx}
              {...option}
            />
          ))}
        </RadioGroup>
        {differentMailingAddress && (
          <Box
            sx={{
              mt: 2,
              borderTop: (t) => `solid 1px ${t.palette.grey['500']}`,
              pt: 6,
              display: 'flex',
              flex: 1,
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: { xs: '100%', md: '600px' },
              maxWidth: { xs: '100%', md: 600 },
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: displaySuiteField ? '80%' : '100%',
                  transition: 'all 0.3s ease-in-out',
                },
              }}
            >
              <AddressAutocomplete
                label="Mailing Address"
                onSelect={handleSelect}
                onClear={handleClear}
              />
            </Box>
            {displaySuiteField && (
              <TextField
                label="Unit"
                value={suite}
                onChange={(e) => setSuite(e.target.value)}
                required
                inputRef={suiteInputRef}
                sx={{
                  width: { xs: '50%', md: '20%' },
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </SectionWrapper>
  );
}
