import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AddressDto } from 'dtos/address';
import { PrefillService } from 'services/Prefill';

export const useVerifyAddress = (options?: MutationOptions<any, AxiosError, AddressDto>) =>
  useMutation(
    (data: AddressDto) => PrefillService.verifyAddress(data),
    options,
  );
