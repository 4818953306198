import { Box, Typography } from '@mui/material';
import { Layout } from 'components/Layout';
import { ReactComponent as Globe } from 'images/icons/globe.svg';
import { TYPOGRAPHY } from 'rentr-theme';

export const NotAvailableStateYetView = () => {
  return (
    <Layout onLogoClickPath="/" alwaysContact>
      <Box sx={{ textAlign: 'center', maxWidth: 600, margin: '0 auto', mt: 6 }}>
        <Typography sx={() => ({ ...TYPOGRAPHY.heading2 })}>
          We are not available in your state yet.
        </Typography>
        <Typography sx={() => ({ ...TYPOGRAPHY.paragrafRegular, mt: 1 })}>
          Contact an agent or leave us your email address and we will contact you
          when we are available in your state.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Globe/>
        </Box>
      </Box>
    </Layout>
  );
};
