import { PageNames } from 'analytics';
import useAnalytics from 'analytics/useAnalytics';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { ROUTES } from 'rentr-constants';
import { FCC } from 'types';
import { ContactView } from 'views/contact';
import { DashboardView } from 'views/dashboard';
import { LeadView } from 'views/dashboard/views/lead';
import { DashboardRootView } from 'views/dashboard/views/root';
import { LicensesView } from 'views/licenses';
import { LoginView } from 'views/login';
import LoginCallbackView from 'views/login-callback';
import { NotAvailableStateYetView } from 'views/not-available';
import { NotFoundView } from 'views/not-found';
import { OnboardingView } from 'views/onboarding';
import { PrivacyPolicyView } from 'views/privacy-policy';
import { PurchaseView } from 'views/purchase';
import { TermsOfServiceView } from 'views/terms-of-service';

export const ViewsRoutes: FCC = () => {
  const { pageViewed } = useAnalytics();
  const location = useLocation();

  React.useEffect(() => {
    if (pageViewed && location?.pathname?.length) {
      const pathPrefix = location.pathname.split('/')[1];
      const routeKey = Object.keys(ROUTES).find(k => ROUTES[k] === `/${pathPrefix}`);
      if (routeKey && PageNames[routeKey]) {
        pageViewed(PageNames[routeKey]);
      }
    }
  }, [pageViewed, location]);

  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<Navigate to={`${ROUTES.ONBOARDING}/1`} />} />
      <Route path={ROUTES.CONTACT} element={<ContactView />} />
      <Route path={ROUTES.dashboard.ROOT} element={<DashboardView />}>
        <Route index element={<DashboardRootView />} />
        <Route path={ROUTES.dashboard.lead.ROOT + '/*'} element={<LeadView />} />
        <Route path='*' element={<div>This page doesn&apos;t exist in dashboard</div>} />
      </Route>
      <Route path={ROUTES.LICENSES} element={<LicensesView />} />
      <Route path={ROUTES.LOGIN} element={<LoginView />} />
      <Route path={ROUTES.LOGIN_CALLBACK} element={<LoginCallbackView />} />
      <Route path={ROUTES.NOT_AVAILABLE} element={<NotAvailableStateYetView />} />
      <Route path={`${ROUTES.ONBOARDING}/*`} element={<OnboardingView />} />
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyView />} />
      <Route path={ROUTES.PURCHASE} element={<PurchaseView />} />
      <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfServiceView />} />
      <Route path='*' element={<NotFoundView />} />
    </Routes>
  );
};
