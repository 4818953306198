import { Box, Typography } from '@mui/material';
import { BigLoaderWithMessage } from 'components/BigLoaderWithMessage';
import { Heading } from 'components/Heading';
import { QuoteBox } from 'components/QuoteBox';
import { QuoteDTO } from 'dtos';
import { startCase } from 'lodash';
import { useLead, useVisibleQuotes } from 'queries';
import { useEffect, useState } from 'react';

interface Props {
  leadId: string;
  onQuoteSelection: (quote: QuoteDTO) => void;
}

export const QuoteSelection = ({ onQuoteSelection, leadId}: Props) => {
  const { data: lead, isLoading: loadingLead } = useLead(leadId);
  const { data: quotes, isLoading: loadingQuotes } = useVisibleQuotes(leadId);
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showNoQuotes, setShowNoQuotes] = useState(false);
  const [sortedQuotes, setSortedQuotes] = useState<QuoteDTO[] | undefined>(undefined);

  useEffect(function sortQuotesByPremium() {
    if (loadingQuotes || loadingLead) {
      setShowSpinner(true);
      return;
    }

    if (quotes?.length) {
      const sorted = quotes?.sort((q1, q2) => q1.premium < q2.premium ? -1 : 1);
      const CaFairPlan = quotes.find(q => q.carrier.displayName === 'CA Fair Plan');
      if (CaFairPlan) {
        const idx = quotes.indexOf(CaFairPlan);
        const [caFairPlan] = sorted.splice(idx, 1);
        sorted.push(caFairPlan);
      }
      setSortedQuotes(sorted);
    } else {
      setShowNoQuotes(true);
    }

    setShowSpinner(false);
  }, [loadingQuotes, loadingLead, quotes]);

  return (
    <Box sx={{ my: {xs: 2, md: 0 } }}>
      {showSpinner ? (
        <BigLoaderWithMessage title={'Loading...'} description={''} />
      ) : showNoQuotes ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" sx={() => ({ mt: 8 })}>
            No quotes found
          </Typography>
        </Box>
      ) : (
        <>
          <Heading
            sx={{ maxWidth: 600, margin: '0 auto' }}
            title={`${startCase(lead.firstName)}, here are your insurance quotes!`}
            description="We have found you some great options. Let's get you covered!"
          />
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', gap: 2 }}>
            {sortedQuotes && sortedQuotes.map((quote, idx) => (
              <QuoteBox
                shouldRevealAllFields={show}
                onRevealChangeRequest={() => setShow(!show)}
                showLabels
                key={quote.id}
                quote={quote}
                idx={idx + 1}
                onSelect={onQuoteSelection}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: { xs: 2, md: 4} }}>
            <Typography variant="body1" sx={(t) => ({ fontSize: '14px', color: t.palette.grey['700'], maxWidth: { md: '80%' }, textAlign: 'center' })}>
              The premium and coverages shown are based on the accuracy of the information you provide and are not indicative of all coverages available. Actual coverage is subject to the terms and limitations of the insurance policy as issued. This estimate does not constitute a confirmation of coverage or a binder of insurance. The premium amounts, coverage, and coverage availability can change until the policy is purchased.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
