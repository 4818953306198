import { Typography } from '@mui/material';
import { StatusContainer } from 'components/StatusContainer';
import { ReactComponent as InflowPayment } from 'images/icons/inflow-payment.svg';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { SUPPORT_PHONE } from 'rentr-constants';

interface Props {
  onContinueClick: () => void;
}

export const InFlowPaymentSection = ({ onContinueClick }: Props) => {
  const [message, setMessage] = useState<string>('');

  useEffect(
    function checkTimeVsBusinessHours() {
      const nowInPacific = DateTime
        .local()
        .setZone('America/Los_Angeles');

      if (7 <= nowInPacific.hour && nowInPacific.hour < 17) {
        setMessage(`You can expect a phone call from a Homeflow agent in the next 30 minutes to complete your payment, or feel free to call us at ${SUPPORT_PHONE}`);
      } else {
        setMessage('You\'ve caught us outside our business hours (7AM-5PM PST), but you can expect a call from your agent at the start of the next business day.');
      }
    },
    []
  );

  return (
    <StatusContainer
      sx={{
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 16
      }}
      button={{
        label: 'Continue',
        onClick: onContinueClick,
        props: {
          sx: {
            minWidth: 200,
            mt: 2
          }
        }
      }}
      description={(
        <Typography sx={{ maxWidth: '60%' }}>
          {message}
        </Typography>
      )}
      title="You're almost there!"
      image={<InflowPayment/>}
    />
  );
};
