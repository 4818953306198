import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, BoxProps, Button, Tooltip, Typography } from '@mui/material';
import { QUOTE_RENDERERS, QuoteDTO, QuoteFieldRenderer, QuoteRendererKeys } from 'dtos';
import { CA_FAIR_PLAN_WARNING, ROOF_FIRE_LIGHTNING_ONLY_WARNING } from 'rentr-constants';

interface QuoteMetricProps {
  value: string;
  label: string;
  skipBorder?: boolean;
  showLabel?: boolean;
  tooltip?: string | null;
  sx?: BoxProps['sx'];
}

export const QuoteMetricPosition = ({ skipBorder, label, value, showLabel, tooltip, sx }: QuoteMetricProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderBottom: t => !skipBorder ? `1px solid ${t.palette.grey['500']}` : 'none',
        pb: 1,
        px: 2,
        ...sx,
      }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}>
        <Typography
          variant="body1"
          sx={(t) => ({
            color: t.palette.primary.main,
            display: { xs: 'block', md: showLabel ? 'block' : 'none' }
          })}>
          {label}
        </Typography>
        {tooltip?.length && (
          <Tooltip title={<Typography sx={{ fontSize: 14, color: t => t.palette.common.white }}>{tooltip}</Typography>} placement="right" arrow>
            <InfoOutlinedIcon sx={{ color: t => t.palette.primary.main, fontSize: 16, ml: 0.5 }} />
          </Tooltip>
        )}
      </Box>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

interface Props {
  quote: QuoteDTO;
  idx: number;
  showLabels?: boolean;
  onSelect: (quote: QuoteDTO) => void;
  shouldRevealAllFields?: boolean;
  onRevealChangeRequest: () => void;
}

const SKIP_RENDERERS: QuoteRendererKeys[] = ['premium', 'type', 'status', 'carrier'];
export const QuoteBox = ({ quote, showLabels, onSelect, shouldRevealAllFields, onRevealChangeRequest }: Props) => {
  const { logoUrl } = quote.carrier;
  const renderPosition = (key: string, value: string, label: string, tooltip?: string | null, skipBorder?: boolean) => {
    return (
      <QuoteMetricPosition
        key={key}
        value={value}
        label={label}
        skipBorder={skipBorder}
        showLabel={showLabels}
        tooltip={tooltip}
      />
    );
  };

  return (
    <Box sx={(t) => ({
      backgroundColor: '#fff',
      position: 'relative',
      flex: 1,
      pb: 5,
      pt: 2,
      px: 2,
      maxWidth: { md: 300 },
      border: `1px solid ${t.palette.grey['700']}`,
      borderRadius: 2
    })}>
      <Box sx={() => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        m: 4,
        height: '80px'
      })}>
        {logoUrl ? <img src={logoUrl} alt="logo" style={{ width: '100%', objectFit: 'contain' }}/> : null}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <Typography variant="h1" sx={{ fontSize: 36, color: t => t.palette.primary.main }}>
          ${(quote.premium)}
        </Typography>
        <Typography sx={{ color: t => t.palette.primary.main, fontWeight: 'bold', fontSize: 24 }}>
          /year
        </Typography>
        {quote.caFairPlanRequired && (
          <Tooltip title={<Typography sx={{ fontSize: 14, color: t => t.palette.common.white }}>{CA_FAIR_PLAN_WARNING}</Typography>} placement="right" arrow sx={{ backgroundColor: t => t.palette.primary.main }}>
            <Typography sx={{ color: t => t.palette.primary.main }}>
              <InfoOutlinedIcon sx={{ color: t => t.palette.primary.main, fontSize: 16, ml: 0.5 }} />
            </Typography>
          </Tooltip>
        )}
        {quote.roofFireAndLightningOnly && (
          <Tooltip title={<Typography sx={{ fontSize: 14, color: t => t.palette.common.white }}>{ROOF_FIRE_LIGHTNING_ONLY_WARNING}</Typography>} placement="right" arrow sx={{ backgroundColor: t => t.palette.primary.main }}>
            <Typography sx={{ color: t => t.palette.primary.main }}>
              <InfoOutlinedIcon sx={{ color: t => t.palette.primary.main, fontSize: 16, ml: 0.5 }} />
            </Typography>
          </Tooltip>
        )}
      </Box>

      <Box sx={(_) => ({ display: 'flex', alignItems: 'stretch', m: 2 })}>
        <Button
          variant="contained"
          sx={{ display: 'block', width: '100%' }}
          onClick={() => onSelect(quote)}
        >
          Select
        </Button>
      </Box>

      <Box sx={(_t) => ({ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 })}>
        {Object.keys(QUOTE_RENDERERS)
          .filter(k => !(SKIP_RENDERERS as string[]).includes(k))
          .map((key) => {
            const renderer: QuoteFieldRenderer | undefined = (QUOTE_RENDERERS as { [k: string]: QuoteFieldRenderer })[key];
            const showCaFairPlanWarningInSummary = (quote.caFairPlanRequired && key === 'caFairPlanRequired');
            const showRoofFireLightningWarningInSummary = (quote.roofFireAndLightningOnly && key === 'roofFireAndLightningOnly');

            if(renderer && (shouldRevealAllFields || renderer.includeInSummary || showCaFairPlanWarningInSummary || showRoofFireLightningWarningInSummary)) {
              const value = renderer.parsedValue(quote);
              const tooltip = renderer.tooltip;

              // HACK
              if (quote.caFairPlanRequired) {
                if (!shouldRevealAllFields && key === 'coverageB') {
                  return null;
                }
              } else {
                if (key === 'caFairPlanRequired') {
                  return null;
                }
              }
              // HACK
              if (quote.roofFireAndLightningOnly) {
                if (!shouldRevealAllFields && key === 'coverageB') {
                  return null;
                }
              } else {
                if (key === 'roofFireAndLightningOnly') {
                  return null;
                }
              }

              if(value) {
                return renderPosition(key, value, renderer.label, tooltip);
              }
            }

            return null;

          })
        }
      </Box>

      <Button
        sx={{ display: 'block', margin: '0 auto', width: 200, mt: 2 }}
        onClick={() => onRevealChangeRequest()}
      >
        Show {shouldRevealAllFields ? 'less' : 'more'}
      </Button>
    </Box>
  );
};
