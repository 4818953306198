export enum UserRole {
  ADMIN = 'admin',
  AGENT = 'agent',
  USER = 'user',
}

export interface UserDTO {
  id: string;
  role: UserRole;
  email: string;
}
