import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, IconButton, Modal as _Modal, ModalProps as _ModalProps, SxProps, Theme } from '@mui/material';
import * as React from 'react';
import { FCC } from 'types';

export interface ModalProps extends _ModalProps {
  boxSx?: BoxProps['sx'];
}

const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '80vh',
  overflow: 'auto',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  bgcolor: '#fff',
  borderRadius: 1,
  p: 1
};

export const Modal: FCC<ModalProps> = ({ children, boxSx, ...restProps }) => {
  return (
    <_Modal {...restProps}>
      <Box sx={{ ...modalStyle, ...boxSx }}>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', pb: 1 }}>
          <IconButton onClick={() => restProps.onClose({}, 'escapeKeyDown')}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{
          px: 2,
          pb: 2
        }}>
          {children}
        </Box>
      </Box>
    </_Modal>
  );
};
