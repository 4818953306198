import { QueryOptions, useQuery } from '@tanstack/react-query';
import { CarrierDTO } from 'dtos';
import { CarrierService } from 'services/Carrier';

export const CARRIER_KEY = 'carriers';

export const useCarriers = (options?: QueryOptions<CarrierDTO[]>) =>
  useQuery<CarrierDTO[]>([CARRIER_KEY], () => CarrierService.getAllCarriers(), {
    staleTime: 1000 * 60 * 5,
    ...options
  });
