import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import FireExtinguisherOutlinedIcon from '@mui/icons-material/FireExtinguisherOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import WaterDamageOutlinedIcon from '@mui/icons-material/WaterDamageOutlined';
import { Box } from '@mui/material';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<string>, 'onClick'>> = [
  {
    image: <DirectionsCarOutlinedIcon color="primary" sx={{ fontSize: { xs: 40, md: 80 } }} />,
    label: 'AUTO INSURANCE POLICY',
    description: 'Some carriers will give you a discount for having an auto insurance policy with any carrier',
    value: 'auto_insurance_policy'
  },
  {
    image: <LocalFireDepartmentIcon color="primary" sx={{ fontSize: { xs: 36, md: 80 } }} />,
    label: 'FIRE DETECTION SYSTEM',
    description: 'Helps notify occupants and possibly emergency services to fire risk',
    value: 'fire_detection_system'
  },
  {
    image: <SecurityOutlinedIcon color="primary" sx={{ fontSize: { xs: 36, md: 80 } }} />,
    label: 'BURGLAR ALARM SYSTEM',
    description: 'Protects against theft of your personal property (i.e. TVs, computers, jewelry',
    value: 'burglar_alarm_system'
  },
  {
    image: <FireExtinguisherOutlinedIcon color="primary" sx={{ fontSize: { xs: 36, md: 80 } }} />,
    label: 'FIRE EXTINGUISHER',
    description: 'Lowers the risk of major fire damage to the home',
    value: 'fire_extinguisher'
  },
  {
    image: <WaterDamageOutlinedIcon color="primary" sx={{ fontSize: { xs: 36, md: 80 } }} />,
    label: 'SMART WATER VALVE',
    description: 'Lowers risk of significant water damage to the home in the event of a leak',
    value: 'smart_water_valve'
  }
];

interface Props {
  values?: string[];
  onSubmit: (values: string[]) => void;
  updating?: boolean;
}

export const Discounts = ({ values: initialValues, onSubmit, updating = false}: Props) => {
  const [selections, setSelections] = React.useState<string[]>(initialValues);

  const handleNext = React.useCallback(() => {
    onSubmit(selections);
  }, [selections, onSubmit]);

  return (
    <SectionWrapper
      title="Do you have any of these in your home?"
      description="These will earn you discounts on your insurance policy"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleNext}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: { xs: 2, md: 4 },
          width: { xs: '100vw', md: '1200px' }
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            checkbox
            sx={{
              display: 'flex',
              flexGrow: { xs: 1, md: 0 },
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'start',
              maxWidth: { xs: '40%' },
              width: { md: '240px' },
              // height: { xs: '160px', md: '260px' },
              textAlign: 'left',
            }}
            selected={selections.includes(option.value)}
            onClick={(v) => {
              const newSelections = [...selections];
              if (selections.includes(v)) {
                const idx = selections.indexOf(v);
                newSelections.splice(idx, 1);
              } else {
                newSelections.push(v);
              }
              setSelections(newSelections);
            }}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
    </SectionWrapper>
  );
};
