import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SwitchProps,
  TextField,
  TextFieldProps
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Switch } from 'components/Switch';
import { isBoolean, snakeCase } from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';
import { Control, Controller, FieldErrors, FieldValues, Path } from 'react-hook-form';
import NumberFormat, { InputAttributes } from 'react-number-format';

export function useSwitchRendered<T>(control: Control<T>, _errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, options?: SwitchProps) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', '.MuiTypography-root': { width: '100%' } }}
            label={label}
            control={(
              <Switch
                sx={{ ml: 2 }}
                checked={Boolean(value)}
                onChange={onChange}
                // helperText={errors[name]?.message as string}
                // error={Boolean(errors[name] as any)}
                {...options}
              />
            )}
          />
        )}
      />
    );
  }, [control]);
}

function useTextFieldRendered<T>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, options?: TextFieldProps) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value === undefined ? '' : value}
            fullWidth
            label={label}
            required
            helperText={errors[name]?.message as string}
            error={Boolean(errors[name] as any)}
            {...options}
          />
        )}
      />
    );
  }, [control, errors]);
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      {...other}
    />
  );
});

function useNumberFieldRendered<T extends FieldValues>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value === undefined ? '' : value}
            fullWidth
            label={label}
            required
            helperText={errors[name]?.message as string}
            error={Boolean(errors[name] as any)}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                onValueChange: onChange,
              }
            }}
          />
        )}
      />
    );
  }, [control, errors]);
}

function useCurrencyFieldRendered<T extends FieldValues>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value === undefined ? '' : value}
            fullWidth
            label={label}
            required
            helperText={errors[name]?.message as string}
            error={Boolean(errors[name] as any)}
            onWheel={(e) => e.currentTarget.blur()}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                thousandSeparator: true,
                isNumericString: true
              },
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
        )}
      />
    );
  }, [control, errors]);
}

function usePercentageFieldRendered<T extends FieldValues>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, step = 5) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            type="number"
            onChange={onChange}
            value={value === undefined ? '' : value}
            fullWidth
            label={label}
            required
            helperText={errors[name]?.message as string}
            error={Boolean(errors[name] as any)}
            onWheel={(e) => e.currentTarget.blur()}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                isNumericString: true,
                maxLength: 3,
                step: `${step}`
              },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        )}
      />
    );
  }, [control, errors]);
}

function usePhoneNumberFieldRendered<T extends FieldValues>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, format = '(###) ###-####', props: TextFieldProps = {}) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value === undefined ? '' : value}
            fullWidth
            label={label}
            required
            helperText={errors[name]?.message as string}
            error={Boolean(errors[name] as any)}
            onWheel={(e) => e.currentTarget.blur()}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                isNumericString: true,
                format
              },
            }}
            {...props}
          />
        )}
      />
    );
  }, [control, errors]);
}

// TODO: Handle errors
function useCheckboxFieldRendered<T>(control: Control<T>, _errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, options?: CheckboxProps) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={<Checkbox onChange={onChange} checked={Boolean(value)} {...options}/>}
            label={label}
          />
        )}
      />
    );
  }, [control]);
}

function useDateFieldRendered<T>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((name: Path<T>, label: string, options?: TextFieldProps) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DesktopDatePicker
              onChange={onChange}
              value={value || null}
              label={label}
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='MM/DD/YYYY'
                  style={{ width: '100%', ...params.style }}
                  helperText={errors[name]?.message as string}
                  error={Boolean(errors[name] as any)}
                  {...options}
                />
              )}
            />
          )}
        />
      </LocalizationProvider>
    );
  }, [control, errors]);
}

interface Option<V> {
  value: V;
  label: string;
}

// TODO: Handle errors
function useSelectRendered<T, Value extends string | number | boolean>(control: Control<T>, errors: FieldErrors<T>) {
  return useCallback((options: Array<Option<Value>>, name: Path<T>, label: string, overriders?: SelectProps<Value>) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth>
            <InputLabel id={snakeCase(label)} required={overriders?.required || true}>{label}</InputLabel>
            <Select<Value>
              onChange={(v) => onChange(v.target.value as Value)}
              // helperText={errors[name]?.message as string}
              error={Boolean(errors[name] as any)}
              labelId={snakeCase(label)}
              value={(isBoolean(value) ? value + '' : value) || '' as any}
              label={label}
              fullWidth
              required
              {...overriders}
            >
              {options.map((item, key) => (
                <MenuItem key={key} value={isBoolean(item.value) ? item.value + '' : item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    );
  }, [control, errors]);
}

export {
  useCheckboxFieldRendered,
  useCurrencyFieldRendered,
  useDateFieldRendered,
  useNumberFieldRendered,
  usePercentageFieldRendered,
  usePhoneNumberFieldRendered,
  useSelectRendered,
  useTextFieldRendered,
};
