import { ActivatePolicyDTO, CheckoutStatusResponseDTO, CreatePolicyClientDTO, PolicyDTO } from 'dtos';

import RequestService from './Request';

class _PolicyService {
  getAllPoliciesForLead(leadId: string): Promise<PolicyDTO[]> {
    return RequestService.client.get<PolicyDTO[]>(`/policy/lead/${leadId}`).then((response) => response.data);
  }

  getPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.get<PolicyDTO>(`/policy/${policyId}`).then((response) => response.data);
  }

  selectQuote(data: CreatePolicyClientDTO): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>('/policy/select-quote', data).then((response) => response.data);
  }

  requestSigningPolicyLink(policyId: string): Promise<string> {
    return RequestService.client.post<string>(`/policy/${policyId}/sign-documents`).then((response) => response.data);
  }

  checkSigningStatus(policyId: string): Promise<boolean> {
    return RequestService.client.get<boolean>(`/policy/${policyId}/sign-documents`).then((response) => response.data);
  }

  requestPaymentPolicyLink(policyId: string): Promise<string> {
    return RequestService.client.post<string>(`/policy/${policyId}/payment`).then((response) => response.data);
  }

  checkBillingStatus(policyId: string): Promise<CheckoutStatusResponseDTO> {
    return RequestService.client.get<CheckoutStatusResponseDTO>(`/policy/${policyId}/payment`).then((response) => response.data);
  }

  activatePolicy(policyId: string, data: ActivatePolicyDTO): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/activate/${policyId}`, data).then((response) => response.data);
  }

  signPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/sign/${policyId}`).then((response) => response.data);
  }

  payForPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/pay/${policyId}`).then((response) => response.data);
  }
}

const PolicyService = new _PolicyService();

export { _PolicyService, PolicyService };
