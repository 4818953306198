import { Box, CircularProgress, Typography } from '@mui/material';
import { LeadStatus } from 'components/LeadStatus';
import dayjs from 'dayjs';
import { LeadDTO } from 'dtos';
import { useLeadHistory } from 'queries/useLead';

export interface Props {
  lead: LeadDTO;
}

export const HistorySection = ({ lead }: Props) => {
  const { data, isLoading } = useLeadHistory(lead.id);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <Box sx={() => ({ flex: 1 })}>
      <Typography sx={{ mb: 2 }} variant="h4">History</Typography>
      {data?.length === 0 && (
        <Typography sx={{ mb: 2 }}>No history available</Typography>
      )}

      {data && data.map((item, index) => (
        <Box key={index} sx={(t) => ({ ml: 2, pb: 10, display: 'flex', borderLeft: `2px solid ${t.palette.grey['600']}` })}>
          <Box sx={(t) => ({
            width: 30,
            height: 30,
            ml: '-21px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            borderRadius: '50%',
            border: '6px solid white',
            backgroundColor: index === 0 ? t.palette.primary.main : t.palette.primary.light,
          })}>
            {data.length - index}
          </Box>
          <Box sx={(_t) => ({})}>
            <Box sx={(_t) => ({ display: 'flex', alignItems: 'center', pt: 1, mb: 1 })}>
              <Typography
                variant='body2'
                sx={(t) => ({ color: t.palette.grey['800'], mr: 1 })}
              >
                {dayjs(item.createdAt).format('MM/DD/YYYY')}
              </Typography>
              <Typography
                variant='body1'
                sx={(t) => ({ color: t.palette.grey['700'], mr: 2 })}
              >
                {dayjs(item.createdAt).format('HH:mm')}
              </Typography>
              <LeadStatus status={item.status}/>
            </Box>
            <Typography>{item.description}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
