import { Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { InsuranceHolder, InsuranceType, LeadDTO, QuoteDeductibleType } from 'dtos';
import { isArray, isUndefined, startCase } from 'lodash';

export interface Props {
  lead: LeadDTO;
}

const renderSection = (label: string, value: string | string[]) => (
  <Box sx={() => ({ display: 'flex', flexDirection: 'column' })}>
    <Typography sx={{ fontWeight: 600 }}>
      {label}:
    </Typography>
    {isArray(value)
      ? value.map((v, idx) => <Typography variant="body1" key={idx}>{v}</Typography>)
      : <Typography variant="body1">{value}</Typography>}
  </Box>
);

const formatPhone = (phoneNumber: string): string => {
  const phoneDigits = [...(phoneNumber.split(''))];
  const areaCode = phoneDigits.splice(0, 3).join('');
  const phonePart1 = phoneDigits.splice(0, 3).join('');
  const phonePart2 = phoneDigits.splice(0, 4).join('');
  return `(${areaCode}) ${phonePart1}-${phonePart2}`;
};

export const QuoteSheetSection = ({ lead }: Props) => {
  return (
    <Box sx={() => ({ flex: 1 })}>
      <Box sx={() => ({ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' })}>
        {renderSection(
          'Type',
          `${startCase(lead.insuranceType)} (${startCase(lead.reason)})`
        )}
        {renderSection(
          'Effective date',
          dayjs(lead.effectiveDate).format('MMM DD, YYYY')
        )}
        {renderSection(
          'Level of coverage',
          startCase(lead.levelOfCoverage)
        )}
        {renderSection(
          'Deductible',
          !lead.deductible
            ? ''
            : lead.deductibleType === QuoteDeductibleType.PERCENTAGE
              ? `${lead.deductible}%`
              : `$${lead.deductible}`
        )}
        {lead.insuranceType === InsuranceType.LANDLORD && renderSection(
          'Rental Timeline',
          startCase(lead.rentalTime)
        )}
        {lead.insuranceType === InsuranceType.LANDLORD && renderSection(
          'Rental Income',
          `$${lead.rentalIncomePerMonth} / month`
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={() => ({ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' })}>
        {renderSection(
          lead.insuranceHolder === InsuranceHolder.COMPANY ? 'Primary' : 'Name',
          lead.insuranceHolder === InsuranceHolder.COMPANY
            ? [`${lead.firstName} ${lead.lastName}`, `(${lead.companyName})`]
            : `${lead.firstName} ${lead.lastName}`
        )}
        {renderSection(
          'Date of birth',
          `${dayjs(lead.birthDate).format('MMM DD, YYYY')}`
        )}
        {renderSection(
          'Email',
          lead.email
        )}
        {renderSection(
          'Phone',
          lead.phoneNumber?.length ? formatPhone(lead.phoneNumber) : ''
        )}
        {renderSection(
          'Address',
          [
            `${lead.propertyAddressStreet}${lead.propertyAddressStreet2 ? ` ${lead.propertyAddressStreet2}` : ''}`,
            `${lead.propertyAddressCity}, ${lead.propertyAddressState} ${lead.propertyAddressZipCode}`
          ]
        )}
        {renderSection(
          'Mailing Address',
          lead.differentMailingAddress
            ? [`${lead.mailingAddressStreet}${lead.mailingAddressStreet2 ? ` ${lead.mailingAddressStreet2}` : ''}`,
              `${lead.mailingAddressCity}, ${lead.mailingAddressState} ${lead.mailingAddressZipCode}`
            ] : '(same as property)'
        )}
        {lead.additionalInsured && renderSection(
          'Additional Insured',
          `${lead.additionalInsuredFirstName} ${lead.additionalInsuredLastName}`,
        )}
        {lead.additionalInsured && renderSection(
          'Date of birth (additional)',
          `${dayjs(lead.additionalInsuredDateOfBirth).format('MMM DD, YYYY')}`
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={() => ({ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' })}>
        {renderSection(
          'Property type',
          startCase(lead.propertyType)
        )}
        {renderSection(
          'Year built',
          `${lead.yearBuilt}`
        )}
        {renderSection(
          'Occupancy',
          startCase(lead.occupancy)
        )}
        {renderSection(
          'Size',
          lead.buildingSize ? `${lead.buildingSize} sq ft.` : ''
        )}
        {renderSection(
          'Fixtures & Finishes',
          startCase(lead.fixturesAndFinishes)
        )}
        {renderSection(
          '# Stories',
          lead.numberOfStories
            ? `${lead.numberOfStories} ${lead.numberOfStories > 1 ? 'levels' : 'level'}`
            : ''
        )}
        {renderSection(
          'Pool, jaccuzi, or spa',
          isUndefined(lead.questionPoolHotTubSpa)
            ? ''
            : lead.questionPoolHotTubSpa ? 'Yes' : 'No'
        )}
        {renderSection(
          'Construction type',
          startCase(lead.constructionType)
        )}
        {lead.underRenovation && renderSection(
          'Under renovation',
          lead.underRenovation ? 'Yes *' : 'No'
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={() => ({ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' })}>
        {renderSection(
          'Roof replaced',
          isUndefined(lead.questionRoofReplaced)
            ? ''
            : lead.questionRoofReplaced ? `Yes - ${lead.roofReplacementYear}` : 'No'
        )}
        {renderSection(
          'Plumbing replaced',
          lead.questionPlumbingReplaced
            ? `Yes - ${lead.plumbingReplacementYear}`
            : 'No'
        )}
        {renderSection(
          'Property has fuses',
          lead.questionFuses ? 'Yes' : 'No'
        )}
        {renderSection(
          'Business with foot traffic',
          lead.questionBusinessFootTraffic ? 'Yes' : 'No'
        )}
        {renderSection(
          'Previous claims on property',
          lead.questionInsuranceClaims
            ? `Yes - $${lead.insuranceClaimValue} (${dayjs(lead.insuranceClaimsOccurrence).format('MMM DD, YYYY')})`
            : 'No'
        )}
        {renderSection(
          'Unrepaired damage or known defects',
          lead.questionUnrepairedDamageKnownDefects
            ? 'Yes'
            : 'No'
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={() => ({ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' })}>
        {renderSection(
          'Auto Insurance Policy',
          lead.hasAutoInsurancePolicy ? 'Yes' : 'No'
        )}
        {renderSection(
          'Fire Extinguisher',
          lead.hasFireExtinguisher ? 'Yes' : 'No'
        )}
        {renderSection(
          'Fire Detection System',
          lead.hasFireDetectionSystem ? 'Yes' : 'No'
        )}
        {renderSection(
          'Fire System - Alerts Emergency Services',
          lead.fireDetectionSystemAlertsEmergencyServices ? 'Yes' : 'No'
        )}
        {renderSection(
          'Burglar Alarm System',
          lead.hasBurglarAlarmSystem ? 'Yes' : 'No'
        )}
        {renderSection(
          'Smart Water Valve',
          lead.hasSmartWaterValve ? 'Yes' : 'No'
        )}
      </Box>
    </Box>
  );
};
