import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material';
import { AddLeadButton } from 'components/AddLeadButton';
import { LeadStatus } from 'components/LeadStatus';
import dayjs from 'dayjs';
import { LeadDTO, LeadStatus as LEAD_STATUS } from 'dtos';
import { startCase } from 'lodash';
import { useLeads } from 'queries/useLead';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertIdToRoute, ROUTES, US_STATES } from 'rentr-constants';
import { FCC } from 'types';

const PAGE_SIZE = 8;

export const DashboardRootView: FCC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<LEAD_STATUS | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);
  const [q, setQ] = useState<string | undefined>(undefined);
  const { data, isLoading } = useLeads({
    query: q,
    ...(status ? { status: [status] } : {}),
    state,
    offset: page === 1 ? 0 : (page - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  const parsedData = data?.leads || [] as LeadDTO[];
  const isEmpty = parsedData.length === 0;

  return (
    <Box>
      <Box sx={(t) => ({ display: 'flex', mb: t.spacing(3), alignItems: 'center', justifyContent: 'space-between' })}>
        <Box sx={(t) => ({ display: 'flex', gap: t.spacing(2) })}>
          <FormControl sx={{ width: 400 }} variant="outlined">
            <InputLabel htmlFor="search">Search by name, email, phone</InputLabel>
            <OutlinedInput
              label="Search by name, email, phone"
              id="search"
              type="text"
              value={q || ''}
              onChange={(e) => {
                setPage(1);
                setQ(e.target.value as string);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              }
              endAdornment={
                q?.length ? (
                  <InputAdornment position="end" onClick={() => {
                    setPage(1);
                    setQ(undefined);
                  }}>
                    <ClearIcon/>
                  </InputAdornment>
                ) : null
              }
            />
          </FormControl>
          <FormControl sx={{ width: 220 }}>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select"
              id="status-select"
              value={status || ''}
              label="Status"
              onChange={(v) => {
                setPage(1);
                setStatus(v.target.value as LEAD_STATUS);
              }}
              startAdornment={
                status && (
                  <InputAdornment position="start" onClick={() => {
                    setPage(1);
                    setStatus(undefined);
                  }}>
                    <ClearIcon/>
                  </InputAdornment>
                )
              }
            >
              {Object.values(LEAD_STATUS).map((item, key) => (
                <MenuItem key={key} value={item}>{startCase(item)}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 120 }}>
            <InputLabel id="status-state">State</InputLabel>
            <Select
              labelId="status-state"
              id="status-state"
              value={state || ''}
              label="State"
              onChange={(v) => {
                setPage(1);
                setState(v.target.value as string);
              }}
              startAdornment={
                state && (
                  <InputAdornment position="start" onClick={() => {
                    setPage(1);
                    setState(undefined);
                  }}>
                    <ClearIcon/>
                  </InputAdornment>
                )
              }
            >
              {Object.values(US_STATES).map((item, key) => (
                <MenuItem key={key} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <AddLeadButton/>
      </Box>

      {!isLoading ? (
        <TableContainer sx={(t) => ({ border: `1px solid ${t.palette.grey['700']}`, borderRadius: t.spacing(1) })}>
          <Table sx={{ minWidth: 650, }} size='medium'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>State</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedData.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={(t) => ({
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { cursor: 'pointer', backgroundColor: t.palette.grey['300'] }
                  })}
                  onClick={() => navigate(insertIdToRoute(ROUTES.dashboard.lead.QUOTE_SHEET, row.id))}
                >
                  <TableCell>{row.firstName} {row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>
                    <LeadStatus status={row.status}/>
                  </TableCell>
                  <TableCell>{row.propertyAddressState}</TableCell>
                  <TableCell>{dayjs(row.createdAt).format('MM/DD/YY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isEmpty && (
            <Box sx={(t) => ({ p: t.spacing(2), textAlign: 'center', width: '100%' })}>
                No results found
            </Box>
          )}
        </TableContainer>)
        : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

      {data && (
        <Box sx={(t) => ({ display: 'flex', justifyContent: 'space-between', mt: t.spacing(2) })}>
          <Typography
            variant="body1"
            sx={(t) => ({ color: t.palette.grey['700'] })}
          ><b>{page === 1 ? 1 : PAGE_SIZE * (page - 1)}</b> to <b>{page * PAGE_SIZE}</b> of <b>{data.count}</b> results</Typography>

          <Pagination
            count={Math.round(data.count / PAGE_SIZE)}
            page={page}
            onChange={(e, v) => setPage(v)}
            variant="outlined"
            shape="rounded"
            size="small"
          />
        </Box>
      )}
    </Box>
  );
};
