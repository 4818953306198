import { Box, RadioGroup, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: string;
  onSubmit: (val: string) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<string>, 'onSelect'>> = [
  {
    label: 'This week',
    value: dayjs().add(5, 'days').format('MM/DD/YYYY'),
  },
  {
    label: 'A few weeks from now',
    value: dayjs().add(3, 'weeks').format('MM/DD/YYYY'),
  },
  {
    label: 'A couple months from now',
    value: dayjs().add(2, 'months').format('MM/DD/YYYY'),
  },
  {
    label: 'On a specific date',
    value: dayjs().add(1, 'day').format('MM/DD/YYYY'),
  },
];

export function EffectiveDate({ value: valueProp, onSubmit, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<string>(valueProp ? dayjs(valueProp).format('MM/DD/YYYY') : null);
  const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false);
  const [invalid, setInvalid] = React.useState(true);

  React.useEffect(() => {
    if (currentValue) {
      const parsed = dayjs(currentValue);
      setInvalid(parsed <= dayjs());
    }
  }, [currentValue]);

  const handleNext = React.useCallback(() => {
    onSubmit(currentValue);
  }, [currentValue, onSubmit]);

  return (
    <SectionWrapper
      title="When would you like coverage to begin?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={invalid}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: 3, width: { xs: '100%', md: '420px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={(v) => {
                if (option.label === 'On a specific date') {
                  setShowDatePicker(true);
                } else {
                  if (showDatePicker) setShowDatePicker(false);
                }
                setCurrentValue(v);
              }}
              selected={currentValue === option.value || (option.label === 'On a specific date' && showDatePicker)}
              key={idx}
            />
          ))}
        </RadioGroup>
        {showDatePicker && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disablePast
              onChange={setCurrentValue}
              value={currentValue || null}
              label={'Custom Date'}
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='MM/DD/YYYY'
                  style={{ ...params.style }}
                  error={invalid}
                  helperText={invalid ? 'Please select a date in the future' : undefined}
                  sx={{ my: 2, width: { xs: '100%', md: '420px', transition: 'opacity 0.3s ease-in-out' }}}
                />
              )}
            />
          </LocalizationProvider>
        )}
      </Box>
    </SectionWrapper>
  );
}
