import { Box } from '@mui/material';
import { LeadStatus as _LeadStatus,LeadStatus as LEAD_STATUS } from 'dtos';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import {
  GRAY_3,
  GRAY_7,
  GREEN_1,
  GREEN_3,
  PRIMARY_1,
  PRIMARY_2,
  PRIMARY_3,
  RED_1,
  RED_3,
  SECONDARY_1,
  SECONDARY_3
} from 'rentr-theme';

interface TaskDetails {
  background: string;
  border: string;
  color: string;
}

interface Props {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  status: _LeadStatus;
}

// HACK: hard code "submitted" status => "Quotes Requested"
const renderStatusText = (status: LEAD_STATUS) => {
  if (status === LEAD_STATUS.SUBMITTED) {
    return 'Quotes Requested';
  }
  else {
    return startCase(status);
  }
};

export const LeadStatus = ({ status, onClick }: Props) => {
  const styles = useMemo((): TaskDetails => {
    switch (status) {
      case LEAD_STATUS.BOUND:
        return {
          background: PRIMARY_2,
          border: PRIMARY_1,
          color: '#fff'
        };
      case LEAD_STATUS.QUOTES:
        return {
          background: PRIMARY_3,
          border: PRIMARY_2,
          color: PRIMARY_2,
        };
      case LEAD_STATUS.SIGNED:
        return {
          background: '#FDE3FC',
          border: '#D51FE5',
          color: '#D51FE5',
        };
      case LEAD_STATUS.QUOTE_SELECTED:
        return {
          background: '#FDF7E3',
          border: '#D99F0C',
          color: '#D99F0C',
        };
      case LEAD_STATUS.NO_QUOTES_FOUND:
        return {
          background: RED_3,
          border: RED_1,
          color: RED_1,
        };
      case LEAD_STATUS.SUBMITTED:
        return {
          background: GREEN_3,
          border: GREEN_1,
          color: GREEN_1,
        };
      case LEAD_STATUS.PAID:
        return {
          background: SECONDARY_3,
          border: SECONDARY_1,
          color: SECONDARY_1,
        };
      default:
        return {
          background: GRAY_7,
          border: GRAY_3,
          color: GRAY_3
        };
    }
  }, [status]);

  return (
    <Box
      onClick={onClick}
      sx={(t) => ({
        borderRadius: '6px',
        textAlign: 'center',
        display: 'inline-block',
        py: '2px',
        fontSize: 14,
        px: t.spacing(2),
        backgroundColor: styles.background,
        border: `1px solid ${styles.border}`,
        color: styles.color
      })}>
      {renderStatusText(status)}
    </Box>
  );
};
