import dayjs from 'dayjs';
import { CreateQuoteDTO, QuoteDTO, UpdateQuoteDTO } from 'dtos';

import RequestService from './Request';

class _QuoteService {
  getAllVisibleQuotesForLead(leadId: string): Promise<QuoteDTO[]> {
    return RequestService.client.get<QuoteDTO[]>(`/quote/lead/${leadId}`).then((response) => response.data);
  }

  getAllQuotesForLead(leadId: string): Promise<QuoteDTO[]> {
    return RequestService.client.get<QuoteDTO[]>(`/quote/${leadId}`).then((response) => response.data);
  }

  createQuote(data: CreateQuoteDTO): Promise<QuoteDTO> {
    data.effectiveDate = dayjs(data.effectiveDate).format('YYYY-MM-DD');
    data.expirationDate = dayjs(data.expirationDate).format('YYYY-MM-DD');
    return RequestService.client.post<QuoteDTO>('/quote', data).then((response) => response.data);
  }

  updateQuote(id: string, data: UpdateQuoteDTO): Promise<QuoteDTO> {
    data.effectiveDate = dayjs(data.effectiveDate).format('YYYY-MM-DD');
    data.expirationDate = dayjs(data.expirationDate).format('YYYY-MM-DD');
    return RequestService.client.patch<QuoteDTO>(`/quote/${id}`, data).then((response) => response.data);
  }

  deleteQuote(id: string): Promise<QuoteDTO> {
    return RequestService.client.delete<QuoteDTO>(`/quote/${id}`).then((response) => response.data);
  }
}

const QuoteService = new _QuoteService();

export { _QuoteService, QuoteService };
