import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_TAG_ID, SENTRY_DSN, SENTRY_ENVIRONMENT } from 'rentr-constants';

if (GOOGLE_ANALYTICS_TAG_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TAG_ID);
}

if (FACEBOOK_PIXEL_ID) {
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FACEBOOK_PIXEL_ID, null, options);
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
