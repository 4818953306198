import { Switch as _Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export const Switch = styled((props: SwitchProps) => (
  <_Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    color: theme.palette.grey[700],

    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#56CCF2',

      '& + .MuiSwitch-track': {
        backgroundColor: '#A9E4F6',
        opacity: 1,
        boxShadow: 'inset 0px 0px 0px 1px #56CCF2'
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      },

      '& .MuiSwitch-thumb': {
        '&::after': {
          content: '"Y"'
        }
      }
    },

    '&.Mui-disabled ': {
      opacity: 0.7,

      '&:hover': {
        cursor: 'not-allowed'
      }
    }
  },

  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: 22,
    height: 22,
    position: 'relative',

    '&::after': {
      content: '"N"',
      position: 'absolute',
      top: '44%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontSize: 11,
      borderRadius: '50%'
    }
  },

  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[600],
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[700]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 100
    })
  }
}));
