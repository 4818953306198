import { Box, BoxProps, FormControlLabel, FormControlLabelProps, Radio, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import * as React from 'react';

export interface RadioOptionProps<T> {
  label: string;
  value: T;
  onSelect: (v: T) => void;
  selected?: boolean;
  sx?: BoxProps['sx'];
  formControlLabelSx?: FormControlLabelProps['sx'];
  helperText?: string;
}

export function RadioOption<T>({
  label,
  value,
  onSelect,
  selected = false,
  sx,
  formControlLabelSx,
  helperText
}: RadioOptionProps<T>) {
  return (
    <>
      <Box
        onClick={() => onSelect(value)}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: (t: Theme) => t.palette.common.white,
          borderRadius: '4px',
          boxShadow: selected ? 'none' : '0px 0px 4px rgba(53, 64, 82, 0.1)',
          border: (t: Theme) => `solid 2px ${selected ? t.palette.primary.main : t.palette.grey['500']}`,
          '&:hover': {
            cursor: 'pointer',
            border: (t: Theme) => `solid 2px ${t.palette.primary.main}`
          },
          ...sx
        }}
      >
        <FormControlLabel
          value={value}
          checked={selected}
          onChange={(_e, _v) => onSelect(value)}
          control={<Radio />}
          label={
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: { xs: 16, md: 18 } }}>
              {label}
            </Typography>
          }
          sx={{ flex: 1, mx: 0, px: 4, py: 2, ...formControlLabelSx }}
        />
        {helperText?.length && (
          <Typography variant="body2" sx={{
            color: (t) => t.palette.grey['800'],
            fontSize: { xs: 10, md: 12 },
            mr: 4
          }}>
            {helperText}
          </Typography>
        )}
      </Box>
    </>
  );
}
