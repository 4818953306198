import { Box, Typography } from '@mui/material';
import { PersonalInformationForm } from 'components/forms/personal-information';
import { LeadDTO, UpdateLeadDTO } from 'dtos';
import { useUpdateLead } from 'queries/useLead';
import { useCallback } from 'react';

export interface Props {
  lead: LeadDTO;
}

export const CustomerDataSection = ({ lead }: Props) => {
  const { mutate, isLoading, isSuccess } = useUpdateLead(lead.id);

  const handleSubmit = useCallback((data: UpdateLeadDTO) => {
    mutate(data);
  }, [mutate]);

  return (
    <Box sx={() => ({ flex: 1 })}>
      <Typography sx={{ mb: 2 }} variant="h4">Personal Information</Typography>
      <Box sx={() => ({ display: 'flex', width: '100%' })}>
        <PersonalInformationForm
          submit={isSuccess ? 'Saved' : 'Save'}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          lead={lead}
        />
      </Box>
    </Box>
  );
};
