export enum MailingType {
  BETA = 'BETA',
  RENTER_INSURANCE = 'RENTER_INSURANCE',
  MULTI_PROPERTY = 'MULTI_PROPERTY',
  NOT_SUPPORTED_STATE = 'NOT_SUPPORTED_STATE',
}

export interface CreateMailingListDTO {
  email: string;
  type: MailingType;
  state?: string | null;
  data?: any | null;
}
