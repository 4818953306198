import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { AnalyticsProvider } from 'analytics/context';
import ScrollToTop from 'components/ScrollToTop';
import React from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { GOOGLE_ANALYTICS_TAG_ID, META, queryClient } from 'rentr-constants';
import { theme } from 'rentr-theme';
import { ViewsRoutes } from 'views';

function App() {
  const location = useLocation();

  React.useEffect(() => {
    if (GOOGLE_ANALYTICS_TAG_ID) {
      ReactGA.event('page_view', location.pathname);
    }
  }, [location]);

  return (
    <AnalyticsProvider>
      <QueryClientProvider client={queryClient}>
        <Helmet
          defaultTitle={META.title}
          titleTemplate={META.titleTemplate}
          titleAttributes={{ itemprop: 'name', lang: 'en' }}
        />
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <ViewsRoutes />
        </ThemeProvider>
      </QueryClientProvider>
    </AnalyticsProvider>
  );
}

export default App;
