import { MutationOptions, QueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CreateQuoteDTO, QuoteDTO, UpdateQuoteDTO } from 'dtos';
import { queryClient } from 'rentr-constants';
import { QuoteService } from 'services';

export const QUOTES_KEY = 'quotes';

export const useQuotes = (leadId: string, options?: QueryOptions<QuoteDTO[]>) =>
  useQuery<QuoteDTO[]>([QUOTES_KEY, leadId], () => QuoteService.getAllQuotesForLead(leadId), {
    ...options,
  });

export const useVisibleQuotes = (leadId: string, options?: QueryOptions<QuoteDTO[]>) =>
  useQuery<QuoteDTO[]>([QUOTES_KEY, leadId], () => QuoteService.getAllVisibleQuotesForLead(leadId), {
    ...options,
  });

export const useUpdateQuote = (options?: MutationOptions<QuoteDTO, AxiosError, { id: string, data: UpdateQuoteDTO }>) =>
  useMutation(({ id, data }) => QuoteService.updateQuote(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QUOTES_KEY, data.leadId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useCreateQuote = (options?: MutationOptions<QuoteDTO, AxiosError, CreateQuoteDTO>) =>
  useMutation((data: CreateQuoteDTO) => QuoteService.createQuote(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QUOTES_KEY]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });
