import { Box, BoxProps, Typography } from '@mui/material';
import { CircularProgress } from 'components/CircularProgress';

interface Props {
  title: string;
  description?: string;
  sx?: BoxProps['sx'];
}

export const BigLoaderWithMessage = ({ title, description, sx }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 600,
        margin: '0 auto',
        mt: 18,
        textAlign: 'center',
        ...sx,
      }}>
      <CircularProgress/>
      <Typography variant='body2' sx={() => ({ mt: 6 })}>
        {title}
      </Typography>
      {description ? (
        <Typography variant='body1' sx={(t) => ({ color: t.palette.grey['700'], mt: 1, maxWidth: 460 })}>
          {description}
        </Typography>
      ) : null}
    </Box>
  );
};
