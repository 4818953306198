import { Box, RadioGroup } from '@mui/material';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: string;
  onContinue: (val: string) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<string>, 'onSelect'>> = [
  {
    label: 'Buying a New Home',
    value: 'buying_new',
  },
  {
    label: 'Thinking of Switching',
    value: 'thinking_of_switching',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export function WhyAreYouHere({ value: initialValue, onContinue, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<string>(initialValue);

  const handleNext = React.useCallback(() => {
    onContinue(currentValue);
  }, [currentValue, onContinue]);

  return (
    <SectionWrapper
      title="Are you buying a new home or just thinking of switching insurers?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!currentValue}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 4 }, width: { xs: '100%', md: '480px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={setCurrentValue}
              selected={currentValue === option.value}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Box>
    </SectionWrapper>
  );
}
