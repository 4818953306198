import { Box, Container, Typography } from '@mui/material';
import { useStateLicenses } from 'queries';
import * as React from 'react';

export const HeaderSection = () => {
  const { data } = useStateLicenses();

  return (
    <Box sx={{
      background: 'linear-gradient(180deg, #D2E0E9 0%, #F7F2EE 43.75%)',
      position: 'relative'
    }}>
      <Container>
        <Box
          sx={{
            pt: { md: 24, xs: 18 },
            pb: { md: 10, xs: 8 },
            position: 'relative',
            maxWidth: 600,
            zIndex: 2
          }}
        >
          <Typography variant="h1" sx={{ mb: 2, fontSize: { md: 74, xs: 44 } }}>
            Licenses
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, maxWidth: 300 }}>
            Homeflow Insurance Services is currently licensed in{' '}
            <Box sx={{ display: 'inline-block', fontWeight: 'bold', color: t => t.palette.primary.main }}>{data?.length}</Box> states
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
