import { Box, BoxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as CheckIcon } from 'images/icons/check-small.svg';
import { ReactComponent as CheckboxIcon } from 'images/icons/checkbox.svg';
import { isString } from 'lodash';
import * as React from 'react';

export interface Props<T> {
  image: JSX.Element;
  label: string;
  description: string | JSX.Element;
  iconLast?: boolean;
  checkbox?: boolean;
  value: T;
  selected?: boolean;
  onClick: (v: T) => void;
  footer?: string | JSX.Element;
  sx?: BoxProps['sx'];
}

export function BlockOption<T>({
  image,
  label,
  description,
  iconLast,
  checkbox,
  value,
  selected,
  onClick,
  footer,
  sx,
}: Props<T>) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        p: { xs: 1, md: 2 },
        backgroundColor: '#fff',
        borderRadius: 2,
        boxShadow: selected ? 'none' : '0px 0px 14px rgba(53, 64, 82, 0.08)',
        border: (t: Theme) => `solid 2px ${selected ? t.palette.primary.main : t.palette.grey['300']}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          cursor: 'pointer',
          border: (t: Theme) => `solid 2px ${t.palette.primary.main}`,
        },
        ...sx,
      }}
      onClick={() => onClick(value)}
    >
      {iconLast ? null : image}
      <Box>
        <Typography variant="h3" sx={() => ({ fontSize: { xs: 14, md: 22 }, mb: 1, mt: !iconLast ? { xs: 2, md: 4 } : 0 })}>
          {label}
        </Typography>
        {isString(description) ? (
          <Typography variant="body2" sx={() => ({ fontSize: { xs: 10, md: 16 }, mt: { xs: 1, md: 1 }, mb: { xs: 1, md: 3 }})}>
            {description}
          </Typography>
        ): description}
        {isString(footer) ? (
          <Typography variant="body2" sx={() => ({
            color: (t) => t.palette.grey['800'],
            fontSize: { xs: 10, md: 12 },
            mb: iconLast ? { xs: 1, md: 4 } : 0
          })}>
            {footer}
          </Typography>
        ): footer}
      </Box>
      {iconLast ? image : null}

      {!checkbox ? (
        <Box
          component={CheckboxIcon}
          sx={{
            transition: 'all 0.3s ease-in-out',
            opacity: selected ? 1 : 0,
            transform: selected ? 'scale(1)' : 'scale(0)',
            pointerEvents: 'none',
            position: 'absolute',
            top: -28,
            width: 40,
            left: -20,
          }}
        />
      ) : (
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: { xs: 12, md: 16 },
          right: { xs: 12, md: 16 },
          width: { xs: 24, md: 28 },
          height: { xs: 24, md: 28 },
          borderRadius: 1,
          transition: 'all 0.1s ease-in-out',
          backgroundColor: t => selected ? t.palette.primary.main : '#fff',
          border: (t: Theme) => `1px solid ${selected ? t.palette.primary.main : t.palette.grey['700']}`
        }}>
          <CheckIcon/>
        </Box>
      )}
    </Box>
  );
}
