import { startCase, upperCase } from 'lodash';

export const Helpers = {
  getStatePageName: (fullStateName: string) => startCase(fullStateName),
  getCityPageName: (city: string, stateAbbreviation: string) =>
    `${startCase(city)}, ${upperCase(stateAbbreviation)}`,
};

export enum PageNames {
  CONTACT = 'Contact Us',
  LICENSES = 'Licenses',
  ONBOARDING = 'Onboarding',
  PURCHASE = 'Purchase',
  PRIVACY_POLICY = 'Privacy Policy',
  TERMS_OF_SERVICE = 'Terms of Service',
}

export enum PageLocations {
  Header = 'Header',
  Body = 'Body',
  Footer = 'Footer',
}

export enum Events {
  ButtonClicked = 'Button Clicked',
  LeadCaptured = 'Lead Captured',
}
