export enum BillingFlowType {
  HF = 'HF',
  IN_FLOW = 'IN_FLOW',
  OUT_OF_FLOW = 'OUT_OF_FLOW',
}

export enum SigningFlowType {
  HF = 'HF',
  DIRECT = 'DIRECT',
}

export interface CarrierDTO {
  id: string;
  name: string;
  displayName: string;
  licenseNumber: string | null;
  logoUrl: string | null;
  createdAt: Date;
  billingFlow: BillingFlowType;
  signingFlow: SigningFlowType;
}
