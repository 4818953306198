import { Box, Typography } from '@mui/material';
import { StatusContainer } from 'components/StatusContainer';
import { PolicyDTO } from 'dtos';
import { ReactComponent as OutofFlowpayment } from 'images/icons/outofflow-payment.svg';

interface Props {
  policy: PolicyDTO;
  onContinueClick: () => void;
}

export const OutOfFlowPaymentSection = ({ policy, onContinueClick }: Props) => {
  return (
    <StatusContainer
      sx={{
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 16
      }}
      button={{
        label: 'Continue',
        onClick: onContinueClick,
        props: {
          sx: {
            minWidth: 200,
            mt: 2
          }
        }
      }}
      descriptionSx={{ maxWidth: 300 }}
      description={(
        <Typography sx={{ maxWidth: 500 }}>
          <b>You can expect an email shortly {policy.carrier.displayName} to complete your payment.</b>
          Please be sure to pay within <Box component='span' color='primary.main'>24 hours</Box> to complete your purchase
        </Typography>
      )}
      title="You're almost there!"
      image={<OutofFlowpayment/>}
    />
  );
};
