import 'yup-phone';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box, BoxProps,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useDateFieldRendered, usePhoneNumberFieldRendered, useSelectRendered, useTextFieldRendered } from 'components/forms/helpers';
import dayjs from 'dayjs';
import { InsuranceHolder, LeadDTO, UpdateLeadDTO } from 'dtos';
import { isString, startCase } from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { US_STATES } from 'rentr-constants';
import * as yup from 'yup';

export interface Props {
  lead?: Partial<LeadDTO>;
  onSubmit: (data: UpdateLeadDTO) => void;
  isLoading?: boolean;
  submit?: string | JSX.Element;
  containerSx?: BoxProps['sx'];
  hideFields?: Array<keyof FormData>;
}

interface FormData {
  firstName: string;
  lastName: string;
  insuranceHolder: InsuranceHolder;
  email: string;
  phoneNumber: string;
  birthDate: string;
  effectiveDate: string;
  pastInsuranceClaims: number;
  companyName: string;
  differentMailingAddress: boolean;
  mailingAddressStreet?: string;
  mailingAddressStreet2?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  additionalInsured: boolean;
  additionalInsuredFirstName: string | null;
  additionalInsuredLastName: string | null;
  additionalInsuredDateOfBirth: string | null;
}

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  insuranceHolder: yup.string().required('Insurance holder is required'),
  email: yup.string().email('This doesn\t look like a valid email').required('Email is required'),
  phoneNumber: yup.string().phone('US', true, 'Invalid phone number').required('Phone number is required'),
  birthDate: yup.string().when('insuranceHolder', {
    is: InsuranceHolder.PERSON,
    then: yup.string().required('Date of Birth is required').nullable().test(
      'past-date',
      'Birth date must be in the past',
      (value) => {
        if(!value) {
          return true;
        }
        return dayjs(value).isBefore(dayjs());
      }
    ),
    otherwise: yup.string().nullable()
  }),
  effectiveDate: yup.string().required('Effective date is required').test(
    'future-date',
    'Effective date must be in the future',
    (value) => {
      return dayjs(value).isAfter(dayjs());
    }
  ),
  pastInsuranceClaims: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Insurance claims is required').min(0, 'Minimum 0'),
  companyName: yup.string().nullable(),

  differentMailingAddress: yup.boolean().required(),
  mailingAddressStreet: yup.string().when('differentMailingAddress', {
    is: true,
    then: yup.string().required('Street is required'),
    otherwise: yup.string().nullable(),
  }),
  mailingAddressStreet2: yup.string().nullable(),
  mailingAddressCity: yup.string().when('differentMailingAddress', {
    is: true,
    then: yup.string().required('City is required'),
    otherwise: yup.string().nullable(),
  }),
  mailingAddressState: yup.string().when('differentMailingAddress', {
    is: true,
    then: yup.string().required('State is required'),
    otherwise: yup.string().nullable(),
  }),
  mailingAddressZipCode: yup.string().when('differentMailingAddress', {
    is: true,
    then: yup.string().required('Zip code is required'),
    otherwise: yup.string().nullable(),
  }),

  additionalInsured: yup.boolean().required(),
  additionalInsuredFirstName: yup.string().when('additionalInsured', {
    is: true,
    then: yup.string().required('First name is required'),
    otherwise: yup.string().nullable()
  }),
  additionalInsuredLastName: yup.string().when('additionalInsured', {
    is: true,
    then: yup.string().required('Last name is required'),
    otherwise: yup.string().nullable()
  }),
  additionalInsuredDateOfBirth: yup.string().when('additionalInsured', {
    is: true,
    then: yup.string().required('Date of Birth is required').nullable(),
    otherwise: yup.string().nullable()
  })
});

export const PersonalInformationForm = ({ lead, isLoading, onSubmit, submit, containerSx, hideFields }: Props) => {
  const { control, handleSubmit, formState, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: lead?.firstName,
      lastName: lead?.lastName,
      insuranceHolder: lead?.insuranceHolder,
      email: lead?.email,
      phoneNumber: lead?.phoneNumber,
      birthDate: lead?.birthDate,
      effectiveDate: lead?.effectiveDate,
      companyName: lead?.companyName || '',
      pastInsuranceClaims: lead?.pastInsuranceClaims,
      differentMailingAddress: lead?.differentMailingAddress ? lead.differentMailingAddress : false,
      mailingAddressStreet: lead?.mailingAddressStreet,
      mailingAddressStreet2: lead?.mailingAddressStreet2,
      mailingAddressCity: lead?.mailingAddressCity,
      mailingAddressState: lead?.mailingAddressState,
      mailingAddressZipCode: lead?.mailingAddressZipCode,
      additionalInsured: lead?.additionalInsured || false,
      additionalInsuredFirstName: lead?.additionalInsuredFirstName,
      additionalInsuredLastName: lead?.additionalInsuredLastName,
      additionalInsuredDateOfBirth: lead?.additionalInsuredDateOfBirth
    }
  });
  const holder = watch('insuranceHolder');
  const showMailingAddress = watch('differentMailingAddress');
  const showAdditionalInsured = watch('additionalInsured');
  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderSelect = useSelectRendered(control, formState.errors);
  const renderDate = useDateFieldRendered(control, formState.errors);
  const renderPhoneNumber = usePhoneNumberFieldRendered(control, formState.errors);
  const shouldHide = useCallback((key: keyof FormData) => hideFields?.includes(key), [hideFields]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'grid',
          mb: 6,
          gridTemplateColumns: {
            sm: '1fr',
            md: '1fr 1fr'
          },
          gap: (t) => t.spacing(2),
          ...containerSx
        }}
      >
        {!shouldHide('insuranceHolder') && renderSelect(Object.values(InsuranceHolder).map(v => ({
          value: v,
          label: startCase(v)
        })), 'insuranceHolder', 'Insurance Holder')}

        {holder === InsuranceHolder.COMPANY && !shouldHide('companyName') && (
          renderTextField('companyName', 'Company Name')
        )}

        {!shouldHide('firstName') && renderTextField('firstName', 'First Name')}
        {!shouldHide('lastName') && renderTextField('lastName', 'Last Name')}
        {!shouldHide('email') && renderTextField('email', 'Email', { type: 'email' })}
        {!shouldHide('phoneNumber') && renderPhoneNumber('phoneNumber', 'Phone Number')}
        {!shouldHide('pastInsuranceClaims') && renderTextField('pastInsuranceClaims', 'Past Insurance Claims', { type: 'number', inputProps: { min: 0 }})}
        {holder === InsuranceHolder.PERSON && !shouldHide('birthDate') && renderDate('birthDate', 'Date of Birth')}

        {/* TODO: Owners mailing address */}
        {!shouldHide('differentMailingAddress') && (
          <Controller
            name="differentMailingAddress"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Checkbox onChange={onChange} checked={value}/>}
                label="Different mailing address?"
              />
            )}
          />
        )}
        {!shouldHide('effectiveDate') && renderDate('effectiveDate', 'When do you want coverage to begin?', { required: true })}

        {showMailingAddress && (
          <>
            {!shouldHide('mailingAddressStreet') && renderTextField('mailingAddressStreet', 'Mailing Street')}
            {!shouldHide('mailingAddressStreet2') && renderTextField('mailingAddressStreet2', 'Mailing Unit', { required: false })}
            {!shouldHide('mailingAddressCity') && renderTextField('mailingAddressCity', 'Mailing City')}
            {renderSelect(Object.values(US_STATES).map(v => ({
              value: v,
              label: startCase(v)
            })), 'mailingAddressState', 'Mailing State')}
            {!shouldHide('mailingAddressZipCode') && renderTextField('mailingAddressZipCode', 'Mailing Zip Code')}
          </>
        )}

        {!shouldHide('additionalInsured') && (
          <Controller
            name="additionalInsured"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Checkbox onChange={onChange} checked={value}/>}
                label="Additional insured?"
              />
            )}
          />
        )}
        {showAdditionalInsured && (
          <>
            {!shouldHide('additionalInsuredFirstName') && renderTextField('additionalInsuredFirstName', 'First Name')}
            {!shouldHide('additionalInsuredLastName') && renderTextField('additionalInsuredLastName', 'Last Name')}
            {!shouldHide('additionalInsuredDateOfBirth') && renderDate('additionalInsuredDateOfBirth', 'Date of Birth', { required: true })}
          </>
        )}
      </Box>

      {submit && !isString(submit) ? submit : (
        <Button
          sx={{ width: 200, margin: '0 auto', display: 'block' }}
          type="submit"
          disabled={isLoading}
          variant="contained"
        >
          {isLoading ? 'Loading...' : submit || 'Save'}
        </Button>
      )}
    </form>
  );
};
