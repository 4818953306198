import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel
} from '@mui/material';
import { PolicyDTO } from 'dtos';
import { usePayForPolicyAgent } from 'queries';
import * as React from 'react';

interface Props {
  policy: PolicyDTO;
}

export const IsPaidCheckbox = ({ policy }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { mutate } = usePayForPolicyAgent(policy.id, {
    onSuccess: () => {
      setOpen(false);
    }
  });

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={policy.isPaid} onClick={!policy.isPaid ? () => setOpen(true) : undefined} />}
        label="Is paid"
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark this policy as paid?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => mutate()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
