import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AddQuoteButton } from 'components/AddQuoteButton';
import { QuoteForm } from 'components/forms/quote';
import { Modal } from 'components/Modal';
import dayjs from 'dayjs';
import { LeadDTO, LeadStatus, QuoteDTO, QuoteStatus, UpdateQuoteDTO } from 'dtos';
import { capitalize } from 'lodash';
import { useQuotes, useUpdateLead, useUpdateQuote } from 'queries';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { LeadService } from 'services';
import { FCC } from 'types';

const Status: FCC<{ active: boolean }> = ({ active, children }) => {
  return (
    <Box
      sx={(t) => ({
        color: t.palette.grey[active ? '800' : '700'],
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <Box
        sx={(t) => ({
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: active
            ? t.palette.success.main
            : t.palette.grey['600'],
          mr: 1,
        })}
      />
      {children}
    </Box>
  );
};

export interface Props {
  lead: LeadDTO;
}

export const QuotesSection = ({ lead }: Props) => {
  const { data, isLoading } = useQuotes(lead.id);
  const [status, setStatus] = useState<QuoteStatus | null>(null);
  const [showToClient, _setShowToClient] = useState<boolean | null>(null);
  const [expiresIn, setExpiresIn] = useState<number | null>(null);
  const [selectedQuote, setSelectedQuote] = useState<QuoteDTO | null>(null);
  const [showEmailSent, setShowEmailSent] = useState<boolean>(false);
  const hasAnyFilter = useMemo(
    () => status !== null || showToClient !== null,
    [showToClient, status]
  );

  const { mutate: updateLead } = useUpdateLead(lead.id, {
    onSuccess: () => {
      setShowEmailSent(true);
      setTimeout(() => setShowEmailSent(false), 1000);
    }
  });

  const handleSendEmail = React.useCallback(() => {
    if (lead.status === LeadStatus.SUBMITTED) {
      updateLead({ status: LeadStatus.QUOTES });
    } else {
      LeadService.triggerQuoteEmail(lead.id);
      setShowEmailSent(true);
      setTimeout(() => setShowEmailSent(false), 3000);
    }
  }, [lead, updateLead]);

  const { mutate, isLoading: isCreating } = useUpdateQuote({
    onSuccess: () => {
      setSelectedQuote(null);
    },
  });

  const handleSubmit = useCallback(
    (data: UpdateQuoteDTO) => {
      if (selectedQuote) {
        mutate({ id: selectedQuote.id, data });
      }
    },
    [selectedQuote, mutate]
  );

  const parsedData = useMemo(() => {
    if (!data) {
      return [];
    }

    let filteredData = data;

    if (status) {
      filteredData = filteredData.filter((d) => d.status === status);
    }

    if (showToClient !== null) {
      filteredData = filteredData.filter(
        (d) => d.showToClient === showToClient
      );
    }

    if (expiresIn !== null) {
      filteredData = filteredData.filter(
        (d) => dayjs(d.expirationDate).diff(dayjs(), 'day') >= expiresIn
      );
    }

    return filteredData;
  }, [data, expiresIn, showToClient, status]);

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h4">
        Quotes
      </Typography>

      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box display="flex" sx={{ gap: 2 }}>
          <FormControl sx={{ width: 160 }}>
            <InputLabel id="quote-expires">Expires in</InputLabel>
            <Select<number | string>
              labelId="quote-expires"
              id="quote-expires"
              value={expiresIn || ''}
              label="Expires in"
              onChange={(v) => {
                setExpiresIn(v.target.value as number);
              }}
              startAdornment={
                expiresIn && (
                  <InputAdornment
                    position="start"
                    onClick={() => {
                      setExpiresIn(null);
                    }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                )
              }
            >
              <MenuItem key="7" value={7}>
                In 7 days
              </MenuItem>
              <MenuItem key="30" value={30}>
                In 30 days
              </MenuItem>
              <MenuItem key="60" value={60}>
                In 60 days
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: 160 }}>
            <InputLabel id="quote-status">Status</InputLabel>
            <Select<QuoteStatus | string>
              labelId="quote-status"
              id="quote-status"
              value={status ? status.toString() : ''}
              label="Status"
              onChange={(v) => {
                setStatus(v.target.value as QuoteStatus);
              }}
              startAdornment={
                status && (
                  <InputAdornment
                    position="start"
                    onClick={() => {
                      setStatus(null);
                    }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                )
              }
            >
              {Object.values(QuoteStatus).map((item, key) => (
                <MenuItem key={key} value={item}>
                  {capitalize(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* <FormControl sx={{ width: 160 }}>
          <InputLabel id="quote-show-to-client">Show to client</InputLabel>
          <Select<boolean | string>
            labelId="quote-show-to-client"
            id="quote-show-to-client"
            value={showToClient !== null ? showToClient : ''}
            label="Show to client"
            onChange={(v) => {
              setShowToClient(JSON.parse(v.target.value as string) as boolean);
            }}
            startAdornment={
              showToClient !== null && (
                <InputAdornment position="start" onClick={() => {
                  setShowToClient(null);
                }}>
                  <ClearIcon/>
                </InputAdornment>
              )
            }
          >
            <MenuItem key='yes' value={'true'}>Yes</MenuItem>
            <MenuItem key='no' value={'false'}>No</MenuItem>
          </Select>
        </FormControl> */}

        <Box display="flex" sx={{ gap: 2 }}>
          {parsedData?.length && (
            <Button
              disabled={showEmailSent}
              onClick={() => handleSendEmail()}
            >
              {showEmailSent
                ? (
                  <>
                  Email Sent!
                  </>
                ) : (
                  <>
                    <SendIcon sx={{ mr: 1 }} />
                    {lead.status === LeadStatus.SUBMITTED ? 'Publish' : 'Resend Email'}
                  </>
                )}
            </Button>
          )}
          <AddQuoteButton sx={{ ml: 'auto' }} lead={lead} />
        </Box>
      </Box>

      {!isLoading ? (
        <TableContainer
          sx={(t) => ({
            border: `1px solid ${t.palette.grey['700']}`,
            borderRadius: t.spacing(1),
          })}
        >
          <Table sx={(t) => ({ minWidth: 650, color: t.palette.grey['800'] })}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Carrier</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Premium</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Expires On</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Display</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedData?.map((row: QuoteDTO, idx) => (
                <TableRow
                  key={idx}
                  onClick={() => setSelectedQuote(row)}
                  sx={(t) => ({
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: t.palette.grey['300'],
                    },
                  })}
                >
                  <TableCell>
                    <Status active={row.status === QuoteStatus.ACTIVE}>
                      {capitalize(row.status)}
                    </Status>
                  </TableCell>
                  <TableCell>{row.carrier.displayName}</TableCell>
                  <TableCell>{row.productType.toUpperCase()}</TableCell>
                  <TableCell>${row.premium.toLocaleString()}</TableCell>
                  <TableCell>
                    {dayjs(row.expirationDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Status active={row.showToClient}>
                      {row.showToClient ? 'Yes' : 'No'}
                    </Status>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {parsedData?.length === 0 && (
            <Box
              sx={(t) => ({
                p: t.spacing(2),
                textAlign: 'center',
                width: '100%',
              })}
            >
              {hasAnyFilter
                ? 'No results for such filters'
                : 'No quotes so far'}
            </Box>
          )}
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Modal
        open={Boolean(selectedQuote)}
        onClose={() => setSelectedQuote(null)}
        boxSx={{ width: 800 }}
      >
        <div>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Update existing quote
          </Typography>

          <QuoteForm
            onSubmit={handleSubmit}
            isLoading={isCreating}
            quote={selectedQuote || {}}
          />
        </div>
      </Modal>
    </Box>
  );
};
