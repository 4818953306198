import { StateLicenseDTO } from 'dtos';

import RequestService from './Request';

class _StateLicenseService {
  getAllStateLicenses(): Promise<StateLicenseDTO[]> {
    return RequestService.client.get<StateLicenseDTO[]>('/license')
      .then((response) => response.data);
  }

  checkStateLicense(stateAbbreviation: string): Promise<boolean> {
    return RequestService.client.get<boolean>(`/license/check/${stateAbbreviation}`)
      .then((response) => response.data);
  }
}

const StateLicenseService = new _StateLicenseService();

export { _StateLicenseService, StateLicenseService };
