// import dayjs from 'dayjs';
import { CarrierDTO } from 'dtos/carrier';
import { CA_FAIR_PLAN_WARNING, ROOF_FIRE_LIGHTNING_ONLY_WARNING } from 'rentr-constants';

export enum QuoteStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export enum QuoteProductType {
  HO3 = 'ho-3',
  HO5 = 'ho-5',
  HO6 = 'ho-6',
  DP1 = 'dp-1',
  DP2 = 'dp-2',
  DP3 = 'dp-3',
}

export enum QuoteType {
  MANUAL = 'manual',
  API = 'api',
  CUSTOMIZED = 'customized',
}

export enum QuoteDeductibleType {
  DOLLARS = 'dollars',
  PERCENTAGE = 'percentage',
}

export interface QuoteDTO {
  id: string;
  carrier: CarrierDTO;
  carrierId: string;
  leadId: string;
  status: QuoteStatus;
  premium: number;
  productType: QuoteProductType;
  type: QuoteType;
  quoteDate: string;
  effectiveDate: string;
  expirationDate: string;
  coverageA: number;
  coverageB: number | null;
  coverageC: number | null;
  coverageD: number | null;
  coverageE: number | null;
  coverageF: number | null;
  extendedRebuildingCost: number | null;
  equipmentBreakdown: boolean;
  serviceLine: boolean;
  showToClient: boolean;
  pprc: boolean;
  waterBackup: boolean;
  earthquake: boolean;
  sinkholeCollapse: boolean;
  screenedEnclosure: number | null;
  catastrophicGroundCollapse: boolean;
  lossAssessment: boolean;
  limitedAnimalLiability: number | null;
  deductible: number;
  deductibleType: QuoteDeductibleType;
  windDeductible: number | null;
  windDeductibleType: QuoteDeductibleType | null;
  hurricaneDeductible: number | null;
  hurricaneDeductibleType: QuoteDeductibleType | null;
  mortgagePaymentProtection: boolean;
  domesticWorkersCoverage: boolean;
  caFairPlanRequired: boolean;
  roofFireAndLightningOnly: boolean;
}

const localeFixedNumber = (value: number, type: QuoteDeductibleType = QuoteDeductibleType.DOLLARS): string => `${type === QuoteDeductibleType.DOLLARS ? '$' : ''} ${value.toLocaleString()}${type === QuoteDeductibleType.PERCENTAGE ? ' %' : ''}`;

export interface QuoteFieldRenderer {
  parsedValue: (quote: QuoteDTO) => string | null;
  includeInSummary?: boolean;
  label: string;
  tooltip?: string;
}

export type QuoteRendererKeys = keyof Omit<QuoteDTO, 'id' | 'carrierId' | 'leadId' | 'showToClient' | 'deductibleType' | 'windDeductibleType' | 'hurricaneDeductibleType' | 'quoteDate' | 'effectiveDate' | 'expirationDate' >;
export const QUOTE_RENDERERS: { [key in QuoteRendererKeys]: QuoteFieldRenderer } = {
  caFairPlanRequired: {
    parsedValue: (quote) => quote.caFairPlanRequired ? 'Yes' : 'No',
    label: 'CA Fair Plan Required',
    tooltip: CA_FAIR_PLAN_WARNING
  },
  roofFireAndLightningOnly: {
    parsedValue: (quote) => quote.roofFireAndLightningOnly ? 'Yes' : 'No',
    label: 'Roof Fire & Lightning Only',
    tooltip: ROOF_FIRE_LIGHTNING_ONLY_WARNING
  },
  coverageA: {
    parsedValue: (quote) => localeFixedNumber(quote.coverageA),
    includeInSummary: true,
    label: 'Coverage A (Dwelling)',
  },
  coverageB: {
    parsedValue: (quote) => quote.coverageB ? localeFixedNumber(quote.coverageB) : null,
    includeInSummary: true,
    label: 'Coverage B (Other Structures)',
  },
  deductible: {
    includeInSummary: true,
    parsedValue: (quote) => quote.deductible ? localeFixedNumber(quote.deductible, quote.deductibleType) : null,
    label: 'Deductible',
  },
  premium: {
    parsedValue: (quote) => localeFixedNumber(quote.premium),
    includeInSummary: true,
    label: 'Premium',
  },
  status: {
    parsedValue: (quote) => quote.status,
    label: 'Status',
  },
  carrier: {
    parsedValue: (quote) => quote.carrier.displayName,
    label: 'Carrier',
  },
  coverageC: {
    parsedValue: (quote) => quote.coverageC ? localeFixedNumber(quote.coverageC) : null,
    label: 'Coverage C (Personal Property)',
  },
  coverageD: {
    parsedValue: (quote) => quote.coverageD ? localeFixedNumber(quote.coverageD) : null,
    label: 'Coverage D (Loss of Use)',
  },
  coverageE: {
    parsedValue: (quote) => quote.coverageE ? localeFixedNumber(quote.coverageE) : null,
    label: 'Coverage E (Personal Liability)',
  },
  coverageF: {
    parsedValue: (quote) => quote.coverageF ? localeFixedNumber(quote.coverageF) : null,
    label: 'Coverage F (Medical Payments)',
  },
  windDeductible: {
    parsedValue: (quote) => quote.windDeductible ? localeFixedNumber(quote.windDeductible, quote.windDeductibleType) : null,
    label: 'Wind Deductible',
  },
  hurricaneDeductible: {
    parsedValue: (quote) => quote.hurricaneDeductible ? localeFixedNumber(quote.hurricaneDeductible, quote.hurricaneDeductibleType) : null,
    label: 'Hurricane Deductible',
  },
  productType: {
    parsedValue: (quote) => quote.productType.toUpperCase(),
    label: 'Product Type',
  },
  type: {
    parsedValue: (quote) => quote.type.toUpperCase(),
    label: 'Quote Type',
  },
  extendedRebuildingCost: {
    // Hack percentage type
    parsedValue: (quote) => quote.extendedRebuildingCost ? localeFixedNumber(quote.extendedRebuildingCost, QuoteDeductibleType.PERCENTAGE) : null,
    label: 'Extended Replacement Cost',
  },
  equipmentBreakdown: {
    parsedValue: (quote) => quote.equipmentBreakdown ? 'Yes' : 'No',
    label: 'Equipment Breakdown',
  },
  serviceLine: {
    parsedValue: (quote) => quote.serviceLine ? 'Yes' : 'No',
    label: 'Service Line',
  },
  pprc: {
    parsedValue: (quote) => quote.pprc ? 'Yes' : 'No',
    label: 'Personal Property Replacement Cost',
  },
  earthquake: {
    parsedValue: (quote) => quote.earthquake ? 'Yes' : 'No',
    label: 'Earthquake',
  },
  sinkholeCollapse: {
    parsedValue: (quote) => quote.sinkholeCollapse ? 'Yes' : 'No',
    label: 'Sinkhole Collapse',
  },
  catastrophicGroundCollapse: {
    parsedValue: (quote) => quote.catastrophicGroundCollapse ? 'Yes' : 'No',
    label: 'Catastrophic Ground Collapse',
  },
  lossAssessment: {
    parsedValue: (quote) => quote.lossAssessment ? 'Yes' : 'No',
    label: 'Loss Assessment',
  },
  waterBackup: {
    parsedValue: (quote) => quote.waterBackup ? 'Yes' : 'No',
    label: 'Water backup',
  },
  screenedEnclosure: {
    parsedValue: (quote) => quote.screenedEnclosure ? localeFixedNumber(quote.screenedEnclosure) : null,
    label: 'Screened Enclosure',
  },
  limitedAnimalLiability: {
    parsedValue: (quote) => quote.limitedAnimalLiability ? localeFixedNumber(quote.limitedAnimalLiability) : null,
    label: 'Limited Animal Liability',
  },
  mortgagePaymentProtection: {
    parsedValue: (quote) => quote.mortgagePaymentProtection ? 'Yes' : 'No',
    label: 'Mortgage Payment Protection'
  },
  domesticWorkersCoverage: {
    parsedValue: (quote) => quote.domesticWorkersCoverage ? 'Yes' : 'No',
    label: 'Domestic Workers Coverage'
  },
};

export interface CreateQuoteDTO {
  carrierId: string;
  leadId: string;
  showToClient?: boolean;
  status: QuoteStatus;
  premium: number;
  productType: QuoteProductType;
  type: QuoteType;
  quoteDate: string;
  effectiveDate: string;
  expirationDate: string;
  coverageA: number;
  coverageB?: number;
  coverageC?: number;
  coverageD?: number;
  coverageE?: number;
  coverageF?: number;
  extendedRebuildingCost?: number;
  equipmentBreakdown: boolean | null;
  serviceLine: boolean | null;
  pprc: boolean;
  waterBackup?: boolean | null;
  earthquake: boolean;
  sinkholeCollapse: boolean;
  screenedEnclosure?: number;
  catastrophicGroundCollapse: boolean;
  lossAssessment: boolean;
  limitedAnimalLiability?: number;
  deductible: number;
  deductibleType: QuoteDeductibleType;
  windDeductible: number | null;
  windDeductibleType: QuoteDeductibleType | null;
  hurricaneDeductible: number | null;
  hurricaneDeductibleType: QuoteDeductibleType | null;
  caFairPlanRequired: boolean;
  roofFireAndLightningOnly: boolean;
}

export interface UpdateQuoteDTO {
  carrierId?: string;
  leadId?: string;
  showToClient?: boolean;
  status?: QuoteStatus;
  premium?: number;
  productType?: QuoteProductType;
  type?: QuoteType;
  quoteDate?: string;
  effectiveDate?: string;
  expirationDate?: string;
  coverageA?: number;
  coverageB?: number | null;
  coverageC?: number | null;
  coverageD?: number | null;
  coverageE?: number | null;
  coverageF?: number | null;
  extendedRebuildingCost?: number | null;
  screenedEnclosure?: number | null;
  limitedAnimalLiability?: number | null;
  waterBackup?: boolean | null;
  equipmentBreakdown?: boolean | null;
  serviceLine?: boolean | null;
  pprc?: boolean;
  earthquake?: boolean;
  sinkholeCollapse?: boolean;
  catastrophicGroundCollapse?: boolean;
  lossAssessment?: boolean;
  deductible?: number;
  deductibleType?: QuoteDeductibleType;
  windDeductible?: number | null;
  windDeductibleType?: QuoteDeductibleType | null;
  hurricaneDeductible?: number | null;
  hurricaneDeductibleType?: QuoteDeductibleType | null;
  caFairPlanRequired: boolean;
  roofFireAndLightningOnly: boolean;
}
