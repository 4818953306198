import { Box, RadioGroup } from '@mui/material';
import { isUndefined } from 'lodash';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: boolean;
  onContinue: (val: boolean) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<boolean>, 'onSelect'>> = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export function FireDetectionFollowUp({ value: initialValue, onContinue, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<boolean>(initialValue);

  const handleNext = React.useCallback(() => {
    onContinue(currentValue);
  }, [currentValue, onContinue]);

  return (
    <SectionWrapper
      title="Does your fire detection system automatically notify emergency services?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={isUndefined(currentValue)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 4 }, width: { xs: '240px', md: '420px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={setCurrentValue}
              selected={currentValue === option.value}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Box>
    </SectionWrapper>
  );
}
