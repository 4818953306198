import { Analytics,AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';

import { AnalyticsContext } from './context';

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;
  const [analytics, setAnalytics] = React.useState<Analytics | undefined>(undefined);
  const loadingRef = React.useRef(false);

  React.useEffect(() => {
    const loadAnalytics = async () => {
      loadingRef.current = true;
      const [response] = await AnalyticsBrowser.load({ writeKey: WRITE_KEY });
      setAnalytics(response);
    };

    if (WRITE_KEY && !analytics && !loadingRef.current) {
      loadAnalytics();
    }
  }, [WRITE_KEY, analytics]);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
