import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box } from '@mui/material';
import { useCurrencyFieldRendered } from 'components/forms/helpers';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  value?: number;
  onSubmit: (value: number) => void;
  updating?: boolean;
}

interface FormData {
  rentalIncome: number;
}

const schema = yup.object().shape({
  rentalIncome: yup.number().required('Rental income is required'),
});

export function RentPerMonth({ value: initialValue, onSubmit, updating = false }: Props) {
  const { getValues, control, formState } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      rentalIncome: initialValue,
    },
    mode: 'all'
  });

  const handleSubmit = React.useCallback(() => {
    const { rentalIncome } = getValues();
    onSubmit(rentalIncome);
  }, [getValues, onSubmit]);

  const renderDollarField = useCurrencyFieldRendered(control, formState.errors);

  return (
    <SectionWrapper
      title="How much do you charge for rent each month?"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleSubmit}
      disableButtons={!formState.isValid}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: 240 }}>
          {renderDollarField('rentalIncome', 'RENTAL INCOME')}
        </Box>
      </Box>
    </SectionWrapper>
  );
}
