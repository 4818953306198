import { QueryOptions, useQuery } from '@tanstack/react-query';
import { StateLicenseDTO } from 'dtos';
import { StateLicenseService } from 'services';

export const useStateLicenses = (options?: QueryOptions<StateLicenseDTO[]>) =>
  useQuery<StateLicenseDTO[]>(
    ['state-licenses'],
    () => StateLicenseService.getAllStateLicenses(),
    options,
  );
