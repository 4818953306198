import { CarrierDTO } from 'dtos';

import RequestService from './Request';

class _CarrierService {
  getAllCarriers(): Promise<CarrierDTO[]> {
    return RequestService.client.get<CarrierDTO[]>('/carrier').then((response) => response.data);
  }
}

const CarrierService = new _CarrierService();

export { _CarrierService, CarrierService };
