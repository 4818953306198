import { Step, StepButton, Stepper as _Stepper, StepperProps } from '@mui/material';

interface Props extends StepperProps {
  steps: string[];
  disabled?: boolean;
  completedIdxs: number[];
  onStepClick: (step: number) => void;
}

export const Stepper = ({ steps, completedIdxs, onStepClick, disabled, ...restProps }: Props) => {
  return (
    <_Stepper {...restProps}>
      {steps.map((label, index) => (
        <Step key={label} completed={completedIdxs.includes(index)}>
          <StepButton color="inherit" onClick={() => onStepClick(index)} disabled={disabled || index > (restProps?.activeStep || 0)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </_Stepper>
  );
};
