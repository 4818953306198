import { StatusContainer } from 'components/StatusContainer';
import { ReactComponent as Submitted } from 'images/icons/submitted.svg';

interface Props {
  onContinueClick: () => void;
}

// TODO: Handle failure
export const SignDocumentsSection = ({ onContinueClick }: Props) => {
  return (
    <StatusContainer
      sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mt: 10}}
      description="Proceed to payment to complete the purchase"
      title="Thanks for accepting our T&C!"
      image={<Submitted/>}
      button={{
        label: 'Continue',
        onClick: onContinueClick,
        props: {
          sx: { minWidth: 200 }
        }
      }}
    />
  );
};
