import { SendContactMessageDTO } from 'dtos';

import RequestService from './Request';

class _ContactService {
  sendContactMessage(data: SendContactMessageDTO): Promise<void> {
    return RequestService.client.post<void>('/contact', data).then((response) => response.data);
  }
}

const ContactService = new _ContactService();

export { _ContactService, ContactService };
