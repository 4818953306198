import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CreateMailingListDTO } from 'dtos';
import { MailingListService } from 'services';

export const useCreateMailingListEntry = (options?: MutationOptions<void, AxiosError, CreateMailingListDTO>) =>
  useMutation(
    (data: CreateMailingListDTO) => MailingListService.createMailingListEntry(data),
    options,
  );
