import { Box, Container, Typography } from '@mui/material';
import { useStateLicenses } from 'queries';
import * as React from 'react';

export const StatesSection = () => {
  const { data } = useStateLicenses();

  return (
    <Box>
      <Container>
        <Box
          sx={{
            py: { md: 10, xs: 4 },
            position: 'relative',
            zIndex: 2
          }}
        >
          <Typography variant="h1" sx={{ mb: 2 }}>
            Our licenses
          </Typography>
          {/*<Typography variant="body2" sx={{ mb: 6, maxWidth: 900, color: t => t.palette.grey['800'] }}>*/}
          {/*  Insurance Agency, Inc is licensed in all {data?.length} states and Washington, DC under the national producer number (NPN) 19627533.*/}
          {/*  You can verify the individual license status for any state using Sircon and NIPR.*/}
          {/*</Typography>*/}

          <Box display='grid' sx={{
            gap: 2,
            gridTemplateColumns: {
              sm: '1fr',
              md: '1fr 1fr 1fr 1fr'
            },
          }}>
            {data?.map((state) => (
              <Typography key={state.id} variant="body2" sx={{ color: t => t.palette.grey['800'], }}>
                {state.stateAbbreviation} #{state.licenseNumber}
              </Typography>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
