import { BillingFlowType, CarrierDTO, PolicyDTO, SigningFlowType } from 'dtos';

export enum Flow {
  'HF-HF' = 'HF-HF',
  'HF-IN_FLOW' = 'HF-IN_FLOW',
  'HF-OUT_OF_FLOW' = 'HF-HF-OUT_OF_FLOW',
  'DIRECT-IN_FLOW' = 'DIRECT-IN_FLOW',
  'DIRECT-OUT_OF_FLOW' = 'DIRECT-OUT_OF_FLOW',
  'DIRECT-HF' = 'DIRECT-HF',
}

export enum FormSection {
  QUOTES = 'QUOTES',
  SELECTED_QUOTE = 'SELECTED_QUOTE',
  HF_TERMS_AND_CONDITIONS = 'HF_TERMS_AND_CONDITIONS',
  DIRECT_TERMS_AND_CONDITIONS = 'DIRECT_TERMS_AND_CONDITIONS',
  HF_SIGN_DOCUMENTS = 'HF_SIGN_DOCUMENTS',
  HF_PAYMENT = 'HF_PAYMENT',
  IN_FLOW_PAYMENT = 'IN_FLOW_PAYMENT',
  OUT_OF_FLOW_PAYMENT = 'OUT_OF_FLOW_PAYMENT',
  FINISHED_FLOW = 'FINISHED_FLOW',
  YOURE_ALL_SET = 'YOURE_ALL_SET',
}

export const FORM_SECTIONS_FOR_FLOW: {
  [key in Flow]: { initSection: (policy: PolicyDTO, isCheckout?: boolean) => FormSection, sections: FormSection[] }
} = {
  /**
   * Carrier (async) handles T&Cs
   * Agent (async) handles Payment (via phone)
   */
  [Flow['DIRECT-IN_FLOW']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.DIRECT_TERMS_AND_CONDITIONS,
      FormSection.IN_FLOW_PAYMENT,
      FormSection.YOURE_ALL_SET,
      FormSection.FINISHED_FLOW
    ],
    initSection: (policy: PolicyDTO) => {
      if (policy.isPaid) {
        if (policy.isSigned) {
          return FormSection.FINISHED_FLOW;
        } else {
          return FormSection.YOURE_ALL_SET;
        }
      } else if (policy.isSigned) {
        return FormSection.YOURE_ALL_SET;
      }

      return FormSection.YOURE_ALL_SET;
    }
  },
  /**
   * Carrier (async) handles T&Cs
   * Homeflow handles Payment (ascend, in flow)
   */
  [Flow['DIRECT-HF']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.DIRECT_TERMS_AND_CONDITIONS,
      FormSection.HF_PAYMENT,
      FormSection.YOURE_ALL_SET,
      FormSection.FINISHED_FLOW,
    ],
    initSection: (policy: PolicyDTO, isCheckout?: boolean) => {
      if (policy.isSigned && policy.isPaid) {
        return FormSection.FINISHED_FLOW;
      } else if (policy.isPaid) {
        return FormSection.YOURE_ALL_SET;
      } else if (isCheckout) {
        return FormSection.HF_PAYMENT;
      }

      return FormSection.HF_PAYMENT;
    }
  },
  /**
   * Homeflow handles T&Cs (in flow)
   * Homeflow handles Payment (ascend, in flow)
   */
  [Flow['HF-HF']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.HF_TERMS_AND_CONDITIONS,
      FormSection.HF_SIGN_DOCUMENTS,
      FormSection.HF_PAYMENT,
      FormSection.FINISHED_FLOW,
    ],
    initSection: (policy: PolicyDTO, isCheckout?: boolean) => {
      if (policy.isPaid) {
        return FormSection.FINISHED_FLOW;
      } else if (policy.isSigned) {
        return FormSection.HF_SIGN_DOCUMENTS;
      } else if (isCheckout) {
        return FormSection.HF_PAYMENT;
      }

      return FormSection.HF_TERMS_AND_CONDITIONS;
    }
  },
  /**
   * Homeflow handles T&Cs (in flow)
   * Agent (async) handles Payment (via phone)
   */
  [Flow['HF-IN_FLOW']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.HF_TERMS_AND_CONDITIONS,
      FormSection.HF_SIGN_DOCUMENTS,
      FormSection.IN_FLOW_PAYMENT,
      FormSection.FINISHED_FLOW,
    ],
    initSection: (policy: PolicyDTO) => {
      if (policy.isPaid) {
        return FormSection.FINISHED_FLOW;
      } else if (policy.isSigned) {
        return FormSection.HF_SIGN_DOCUMENTS;
      }

      return FormSection.HF_TERMS_AND_CONDITIONS;
    }
  },
  /**
   * Homeflow handles T&Cs (in flow)
   * Agent (async) handles Payment (via email)
   */
  [Flow['HF-OUT_OF_FLOW']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.HF_TERMS_AND_CONDITIONS,
      FormSection.HF_SIGN_DOCUMENTS,
      FormSection.OUT_OF_FLOW_PAYMENT,
      FormSection.FINISHED_FLOW,
    ],
    initSection: (policy: PolicyDTO) => {
      if (policy.isPaid) {
        return FormSection.FINISHED_FLOW;
      } else if (policy.isSigned) {
        return FormSection.HF_SIGN_DOCUMENTS;
      }

      return FormSection.HF_TERMS_AND_CONDITIONS;
    }
  },
  /**
   * Carrier (async) handles T&Cs
   * Agent (async) handles Payment (via email)
   */
  [Flow['DIRECT-OUT_OF_FLOW']]: {
    sections: [
      FormSection.QUOTES,
      FormSection.SELECTED_QUOTE,
      FormSection.DIRECT_TERMS_AND_CONDITIONS,
      FormSection.OUT_OF_FLOW_PAYMENT,
      FormSection.YOURE_ALL_SET,
    ],
    initSection: (policy: PolicyDTO) => {
      if (policy.isPaid) {
        return FormSection.FINISHED_FLOW;
      } else if (policy.isSigned) {
        return FormSection.OUT_OF_FLOW_PAYMENT;
      }

      return FormSection.DIRECT_TERMS_AND_CONDITIONS;
    }
  }
};

export const findNextSection = (flow: Flow, currentSection: FormSection) => {
  const index = FORM_SECTIONS_FOR_FLOW[flow].sections.indexOf(currentSection);
  return FORM_SECTIONS_FOR_FLOW[flow].sections[index + 1];
};

export const findPreviousSection = (flow: Flow, currentSection: FormSection) => {
  const index = FORM_SECTIONS_FOR_FLOW[flow].sections.indexOf(currentSection);
  return FORM_SECTIONS_FOR_FLOW[flow].sections[index - 1];
};

interface StepToSections {
  name: string;
  sections: FormSection[];
}

const STEPS_GENERAL: StepToSections[] = [
  { name: 'Quote', sections: [FormSection.SELECTED_QUOTE] },
  {
    name: 'Terms and conditions',
    sections: [FormSection.HF_TERMS_AND_CONDITIONS, FormSection.DIRECT_TERMS_AND_CONDITIONS]
  },
  { name: 'Sign documents', sections: [FormSection.HF_SIGN_DOCUMENTS] },
  { name: 'Payment', sections: [FormSection.HF_PAYMENT, FormSection.IN_FLOW_PAYMENT, FormSection.OUT_OF_FLOW_PAYMENT] }
];

export const STEPS_FOR_FLOW = (flow: Flow) => {
  return STEPS_GENERAL.filter(step => step.sections.some(section => FORM_SECTIONS_FOR_FLOW[flow].sections.includes(section)));
};

export const getFlowForCarrier = ({ signingFlow, billingFlow }: CarrierDTO): Flow => {
  if (signingFlow === SigningFlowType.HF && billingFlow === BillingFlowType.HF) {
    return Flow['HF-HF'];
  } else if (signingFlow === SigningFlowType.HF && billingFlow === BillingFlowType.IN_FLOW) {
    return Flow['HF-IN_FLOW'];
  } else if (signingFlow === SigningFlowType.HF && billingFlow === BillingFlowType.OUT_OF_FLOW) {
    return Flow['HF-OUT_OF_FLOW'];
  } else if (signingFlow === SigningFlowType.DIRECT && billingFlow === BillingFlowType.HF) {
    return Flow['DIRECT-HF'];
  } else if (signingFlow === SigningFlowType.DIRECT && billingFlow === BillingFlowType.IN_FLOW) {
    return Flow['DIRECT-IN_FLOW'];
  } else if (signingFlow === SigningFlowType.DIRECT && billingFlow === BillingFlowType.OUT_OF_FLOW) {
    return Flow['DIRECT-OUT_OF_FLOW'];
  } else {
    throw new Error('Flow not found');
  }
};
