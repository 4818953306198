import dayjs from 'dayjs';
import {
  CreateLeadDTO,
  LeadDTO,
  LeadHistoryDTO,
  LeadQueryDTO,
  LeadsResponseDTO,
  UpdateLeadDTO,
  UpdateSourcesLeadDTO
} from 'dtos';
import * as qs from 'query-string';

import RequestService from './Request';

class _LeadService {
  getLead(id: string): Promise<LeadDTO> {
    return RequestService.client.get<LeadDTO>(`/lead/${id}`).then((response) => response.data);
  }

  getAllLeads(query: LeadQueryDTO): Promise<LeadsResponseDTO> {
    return RequestService.client.get<LeadsResponseDTO>(`/lead?${qs.stringify(query)}`).then((response) => response.data);
  }

  getLeadHistory(id: string): Promise<LeadHistoryDTO[]> {
    return RequestService.client.get<LeadHistoryDTO[]>(`/lead/${id}/history`).then((response) => response.data);
  }

  createLead(data: CreateLeadDTO): Promise<LeadDTO> {
    if (data.birthDate) {
      data.birthDate = dayjs(data.birthDate).format('YYYY-MM-DD');
    }
    if (data.effectiveDate) {
      data.effectiveDate = dayjs(data.effectiveDate).format('YYYY-MM-DD');
    }
    if (data.insuranceClaimsOccurrence) {
      data.insuranceClaimsOccurrence = dayjs(data.insuranceClaimsOccurrence).format('YYYY-MM-DD');
    }
    if (data.additionalInsuredDateOfBirth) {
      data.additionalInsuredDateOfBirth = dayjs(data.additionalInsuredDateOfBirth).format('YYYY-MM-DD');
    }
    return RequestService.client.post<LeadDTO>('/lead', data).then((response) => response.data);
  }

  updateLead(id: string, data: UpdateLeadDTO): Promise<LeadDTO> {
    if(data.birthDate) {
      data.birthDate = dayjs(data.birthDate).format('YYYY-MM-DD');
    }
    if(data.effectiveDate) {
      data.effectiveDate = dayjs(data.effectiveDate).format('YYYY-MM-DD');
    }
    if (data.insuranceClaimsOccurrence) {
      data.insuranceClaimsOccurrence = dayjs(data.insuranceClaimsOccurrence).format('YYYY-MM-DD');
    }
    if (data.additionalInsuredDateOfBirth) {
      data.additionalInsuredDateOfBirth = dayjs(data.additionalInsuredDateOfBirth).format('YYYY-MM-DD');
    }

    return RequestService.client.patch<LeadDTO>(`/lead/${id}`, data).then((response) => response.data);
  }

  updateLeadSource(id: string, data: UpdateSourcesLeadDTO): Promise<LeadDTO> {
    return RequestService.client.patch<LeadDTO>(`/lead/${id}/source`, data).then((response) => response.data);
  }

  triggerQuoteEmail(id: string): Promise<void> {
    return RequestService.client.post<null>(`/lead/${id}/triggers/email/quotes-ready`).then((response) => response.data);
  }
}

const LeadService = new _LeadService();

export { _LeadService, LeadService };
