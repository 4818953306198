import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'rentr-constants';
import { TokensService } from 'services/Tokens';

export interface IAuthenticate {
  password: string;
  email: string;
}

export interface ForgotPassword {
  email: string;
}

class _AuthService {
  private webAuth = new WebAuth({
    audience: AUTH0_AUDIENCE,
    clientID: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    redirectUri: `${window.location.origin}/login-callback`,
    responseType: 'token id_token',
    scope: 'openid email profile phone'
  });

  async loginWithAuth0(): Promise<void> {
    await this.webAuth.authorize({ mode: 'login' });
  }

  isAuthenticated() {
    return TokensService.loadAuthTokenFromLocalStorage() !== null;
  }

  saveTokensToLocalStorage = (authResult: Auth0DecodedHash): void => {
    const { accessToken } = authResult;

    if (accessToken) {
      TokensService.saveAuthTokenInLocalStorage(accessToken);
    }
  };

  async logout(returnTo?: string): Promise<void> {
    TokensService.removeAuthTokenFromStorage();

    return new Promise((resolve) => {
      if (returnTo?.length) {
        this.webAuth.logout({ returnTo });
      }

      // webAuth.logout doesn't return a promise and it needs some time
      // to resolve, that's why we need this timeout
      setTimeout(
        () => resolve(),
        1000
      );
    });
  }

  renewToken(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession(
        {},
        (error: Auth0Error | null, result: Auth0DecodedHash) => {
          if (error) {
            return reject(error);
          }

          this.saveTokensToLocalStorage(result);

          return resolve(result);
        }
      );
    });
  }

  getCurrentAuthTokens() {
    return TokensService.loadAuthTokenFromLocalStorage();
  }

  authenticate(tokens: Auth0DecodedHash) {
    this.saveTokensToLocalStorage(tokens);
  }

  handleAuthentication(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.saveTokensToLocalStorage(authResult);

          return resolve(authResult);
        } else if (err) {
          return reject(err);
        }

        return reject('Ops something went wrong...');
      });
    });
  }

  parseAuth0Error(error: Auth0Error): Error {
    // Common error types: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
    const errorDescription = typeof error.description === 'string'
      ? error.description
      : error.error_description ?? error.errorDescription;

    return Error(errorDescription);
  }

  loginWithEmailAndPassword(data: IAuthenticate): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.webAuth.crossOriginAuthentication.login(
        {
          email: data.email,
          password: data.password,
          realm: 'Username-Password-Authentication'
        },
        (error: Auth0Error | null) =>
          error ? reject(this.parseAuth0Error(error)) : resolve(undefined)
      );
    });
  }

  forgotPassword(data: ForgotPassword): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email: data.email
        },
        (error: Auth0Error | null) =>
          error ? reject(this.parseAuth0Error(error)) : resolve(undefined)
      );
    });
  }
}

const AuthService = new _AuthService();

export { AuthService };
