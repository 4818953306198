import { Box, TextField, Typography } from '@mui/material';
import AddressAutocomplete, { TSelectedSuggestion } from 'components/AddressAutocomplete';
import { PropertyType } from 'dtos';
import { AddressDto } from 'dtos/address';
import { isArray } from 'lodash';
import { useVerifyAddress } from 'queries/useVerifyAddress';
import * as React from 'react';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  firstName: string;
  propertyType: PropertyType;
  onSubmit: (address: AddressDto) => void;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  updating?: boolean;
}

export const SINGLE_UNIT_PROPERTY_TYPES: PropertyType[] = [
  PropertyType.SINGLE_FAMILY,
  PropertyType.CONDO_UNIT,
  PropertyType.OTHER
];

const addressSchema = yup.object().shape({
  addressLine1: yup.string().required('Invalid address'),
  addressLine2: yup.string().nullable(),
  city: yup.string().required('Invalid address'),
  state: yup.string().required('Invalid address'),
  zipCode: yup.string().required('Invalid address'),
});

const mapToAddress = (data: TSelectedSuggestion): AddressDto => ({
  addressLine1: `${data.streetNumber} ${data.street}`,
  addressLine2: data.suite,
  city: data.city as string,
  state: data.state as string,
  zipCode: data.zipCode as string,
});

export function PropertyAddress({
  firstName: _firstName,
  propertyType,
  addressLine1: _addressLine1,
  addressLine2: _addressLine2,
  city: _city,
  state: _state,
  zipCode: _zipCode,
  onSubmit,
  updating = false
}: Props) {
  const selectionRef = React.useRef<TSelectedSuggestion | null>(null);
  const [error, setError] = React.useState<string>();
  const [displaySuiteField, setDisplaySuiteField] = React.useState<boolean>(false);
  const [suite, setSuite] = React.useState<string>('');
  const suiteInputRef = React.useRef<HTMLInputElement | null>(null);
  const [addressValidated, setAddressValidated] = React.useState<boolean>(false);

  const { mutate: verifyAddressWithSmarty, isLoading: isVerifying } = useVerifyAddress({
    onSuccess: (response) => {
      const matchCode =
        isArray(response) && response?.length ? response[0].analysis?.dpv_match_code : 'Y';
      switch (matchCode) {
        case 'D':
          if (SINGLE_UNIT_PROPERTY_TYPES.includes(propertyType)) {
            setDisplaySuiteField(true);
          } else {
            setAddressValidated(true);
          }
          break;
        default:
          setAddressValidated(true);
      }
    },
  });

  // Focus on suite field
  React.useEffect(function focusOnSuite() {
    if (displaySuiteField && suiteInputRef.current) {
      suiteInputRef.current.focus();
    }
  }, [displaySuiteField, suiteInputRef]);

  React.useEffect(function handleSuiteUpdated() {
    if (displaySuiteField) {
      const address = mapToAddress(selectionRef.current);
      verifyAddressWithSmarty({ ...address, addressLine2: suite });
    }
  }, [displaySuiteField, suite, verifyAddressWithSmarty]);

  // Handle selecting autocomplete
  const handleSelect = React.useCallback(
    async (selection: TSelectedSuggestion) => {
      try {
        setError(null);
        selectionRef.current = selection;
        const address = mapToAddress(selection);
        await addressSchema.validate(address);
        verifyAddressWithSmarty(address);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    },
    [verifyAddressWithSmarty]
  );

  const handleClear = React.useCallback(
    () => {
      selectionRef.current = null;
      setAddressValidated(false);
      setDisplaySuiteField(false);
      setSuite('');
    },
    [setDisplaySuiteField, setSuite]
  );

  const handleSubmit = React.useCallback(() => {
    const address = mapToAddress(selectionRef.current);
    if (suite) {
      address.addressLine2 = suite;
    }
    onSubmit(address);
  },[onSubmit, suite]);

  return (
    <SectionWrapper
      title="What's the property address you're looking to protect?"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating || isVerifying}
      onPrimaryButtonClick={handleSubmit}
      disableButtons={!addressValidated || !!error}
    >
      <Box sx={{ mt: 2, display: 'flex', flex: 1, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', borderRadius: 2, minWidth: { xs: '100%', md: 300 }, maxWidth: { xs: '100%', md: 600 }, gap: 2 }}>
        <Box sx={{ width: { xs: '100%', md: displaySuiteField ? '75%' : '100%', transition: 'all 0.3s ease-in-out' }}}>
          <AddressAutocomplete onSelect={handleSelect} onClear={handleClear} />
        </Box>
        {displaySuiteField && (
          <TextField label="Unit" value={suite} onChange={(e) => setSuite(e.target.value)} required inputRef={suiteInputRef} sx={{ width: { xs: '50%', md: '25%' }, transition: 'all 0.3s ease-in-out' }} />
        )}
      </Box>
      {error && (
        <Typography variant="body1" sx={{ color: 'red', mt: 1 }}>
          {error}
        </Typography>
      )}
    </SectionWrapper>
  );
}
