import { CreateMailingListDTO } from 'dtos';

import RequestService from './Request';

class _MailingListService {
  createMailingListEntry(data: CreateMailingListDTO): Promise<void> {
    return RequestService.client.post<void>('/mailing', data)
      .then((response) => response.data);
  }
}

const MailingListService = new _MailingListService();

export { _MailingListService, MailingListService };
