import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PrefillData, PrefillPayloadDTO } from 'dtos';
import { PrefillService } from 'services/Prefill';

export const useLeadPrefill = (options?: MutationOptions<PrefillData, AxiosError, PrefillPayloadDTO>) =>
  useMutation(
    (data: PrefillPayloadDTO) => PrefillService.getLeadPrefill(data),
    options,
  );
