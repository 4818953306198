import {
  Box, BoxProps,
  Typography
} from '@mui/material';
import { ReactComponent as Check } from 'images/icons/check.svg';
import { ReactComponent as Globe } from 'images/icons/icon-globe.svg';
import { ReactComponent as Message } from 'images/icons/icon-message.svg';
import { ReactComponent as Person } from 'images/icons/icon-person.svg';
import { ReactComponent as Star } from 'images/icons/icon-star.svg';
import { ReactComponent as Twitter } from 'images/icons/icon-twitter.svg';
import { useLead, useUpdateLeadSource } from 'queries';
import * as React from 'react';

interface Option {
  value: string;
  label: string;
  icon: JSX.Element;
}

const OPTIONS: Option[] = [
  { label: 'Social media', value: 'social_media', icon: <Twitter/> },
  { label: 'Search engine', value: 'search_engine', icon: <Globe/> },
  { label: 'Word of mouth', value: 'word_of_mouth', icon: <Person/> },
  { label: 'Partner', value: 'partnership', icon: <Message/> },
  { label: 'Other', value: 'other', icon: <Star/> }
];

interface OptionBoxProps {
  option: Option;
  onClick: (o: Option) => void;
  checked?: boolean;
}

const OptionBox = ({ option, onClick, checked }: OptionBoxProps) => {
  const [hovering, setHovering] = React.useState(false);

  return (
    <Box
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => onClick(option)}
      key={option.value}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', height: 16 }}>
        {option.icon}
      </Box>
      <Typography sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
        {option.label}
      </Typography>
      <Box
        sx={{
          mt: 0.5,
          width: 16,
          height: 16,
          border: t => `1px solid ${hovering || checked ? t.palette.primary.main : t.palette.grey['700']}`,
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: checked ? 'primary.main' : 'transparent'
        }}
      >
        {checked && <Check/>}
      </Box>
    </Box>
  );
};

export interface Props {
  leadId: string;
  sx?: BoxProps['sx'];
}

export const LeadSourceForm = ({ leadId, sx }: Props) => {
  const { data } = useLead(leadId);
  const { mutate } = useUpdateLeadSource(leadId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 4,
        borderRadius: 2,
        p: 2,
        backgroundColor: 'white',
        border: t => `1px solid ${t.palette.grey['700']}`,
        boxShadow: '0px 0px 20px rgba(53, 64, 82, 0.1)',
        ...sx,
      }}
    >
      {OPTIONS.map((o) => (
        <OptionBox
          option={o}
          key={o.value}
          onClick={(o) => mutate({ source: o.value })}
          checked={data?.source === o.value}
        />
      ))}
    </Box>
  );
};
