import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { LeadStatus } from 'components/LeadStatus';
import dayjs from 'dayjs';
import { LeadStatus as _LeadStatus } from 'dtos';
import { useLead, useUpdateLead } from 'queries/useLead';
import { useCallback, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'rentr-constants';
import { TYPOGRAPHY } from 'rentr-theme';
import { FCC } from 'types';
import { AdditionalSection } from 'views/dashboard/views/lead/sections/additional';
import { HomeQuestionsSection } from 'views/dashboard/views/lead/sections/home-questions';

import { CustomerDataSection } from './sections/customer-data';
import { HistorySection } from './sections/history';
import { PolicySection } from './sections/policy';
import { PropertyDetailsSection } from './sections/property-details';
import { QuoteSheetSection } from './sections/quote-sheet';
import { QuotesSection } from './sections/quotes';

const STATUS_TO_COPY_BUTTON: { [key in _LeadStatus]: string | null } = {
  [_LeadStatus.EXPIRED]: null,
  [_LeadStatus.CANCELLED]: null,
  [_LeadStatus.NO_QUOTES_FOUND]: null,
  [_LeadStatus.SUBMITTED]: null,
  [_LeadStatus.QUOTES]: 'Quote selection link',
  [_LeadStatus.QUOTE_SELECTED]: 'Signing link',
  [_LeadStatus.SIGNED]: 'Payment link',
  [_LeadStatus.BOUND]: 'Policy link',
  [_LeadStatus.PAID]: 'Policy link',
  [_LeadStatus.PROSPECT]: null,
};

export const LeadView: FCC = () => {
  const { id } = useParams();
  const leadId = id as string;
  const { data, isLoading } = useLead(leadId);
  const { pathname } = useLocation();
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (ADMIN_STATUSES.length) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => setAnchorEl(null);

  const { mutate: updateLead, isLoading: isUpdating } = useUpdateLead(leadId);

  const handleResetLink = useCallback(() => {
    if (confirm('This will reset the customer link to before they selected a quote. Would you like to proceed?')) {
      updateLead({ status: _LeadStatus.QUOTES });
    }
  }, [updateLead]);

  const handleCopyLink = useCallback(() => {
    const link = insertIdToRoute(ROUTES.PURCHASE, leadId);
    navigator.clipboard.writeText(`${window.location.origin}${link}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [leadId]);

  const generateLink = useCallback((path: string, name: string) => {
    return (
      <Link to={path} style={{ textDecoration: 'none' }}>
        <Box
          sx={(t) => ({
            ...TYPOGRAPHY.paragrafLarge,
            backgroundColor: pathname.includes(path) ? t.palette.grey['500'] : undefined,
            px: 4,
            py: 1,
            color: t.palette.grey['800'],
            '&:hover': {
              backgroundColor: t.palette.grey['400']
            }
          })}>
          {name}
        </Box>
      </Link>
    );
  }, [pathname]);

  const ADMIN_STATUSES = useMemo(() => {
    if (!data) {
      return [];
    }
    switch (data.status) {
      case _LeadStatus.SUBMITTED:
        return [_LeadStatus.NO_QUOTES_FOUND];
      case _LeadStatus.QUOTE_SELECTED:
        return [_LeadStatus.BOUND];
      default:
        return [];
    }
  }, [data]);

  if (isLoading || !data) {
    return (
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <Box sx={(t) => ({ border: `1px solid ${t.palette.grey['700']}`, borderRadius: 2, display: 'flex' })}>
      <Box sx={(t) => ({
        display: 'flex',
        borderRight: `1px solid ${t.palette.grey['700']}`,
        flexDirection: 'column',
        width: 240,
        borderTopLeftRadius: t.spacing(2),
        borderBottomLeftRadius: t.spacing(2),
        p: t.spacing(4),
        pb: t.spacing(10),
        backgroundColor: t.palette.grey['300']
      })}>
        <Typography sx={{ mg: 1, color: (t) => t.palette.grey['800'] }} variant="subtitle1">{dayjs(data.createdAt).format('MMMM D, YYYY')}</Typography>
        <Typography sx={{ mb: 1 }} variant="h1">{data.firstName} {data.lastName}</Typography>
        <div>
          <LeadStatus status={data.status} onClick={handleClick}/>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {isUpdating && <CircularProgress sx={{ position: 'absolute', zIndex: 10, left: '40%', top: '40%' }}/>}
            {Object.values(_LeadStatus).filter((status) => ADMIN_STATUSES.includes(status)).map((status) => (
              <MenuItem key={status} onClick={() => updateLead({ status })} disabled={isUpdating}>
                <LeadStatus status={status}/>
              </MenuItem>
            ))}
          </Menu>
        </div>
        {data && STATUS_TO_COPY_BUTTON[data.status] ? (
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleCopyLink}>
            <ContentCopyIcon sx={{ mr: 1 }}/>
            {copied ? 'Copied to clipboard!' : STATUS_TO_COPY_BUTTON[data.status]}
          </Button>
        ) : null}
        <Box sx={{ my: 3, mx: -4 }}>
          {generateLink('quote-sheet', 'Quote sheet')}
          {generateLink('customer-data', 'Personal info')}
          {generateLink('property-details', 'Property details')}
          {generateLink('home-details', 'Home details')}
          {generateLink('additional', 'Additional info')}
          {generateLink('quotes', 'Quotes')}
          {generateLink('policy', 'Policy')}
          {generateLink('history', 'History')}
        </Box>
        {data && data.status === _LeadStatus.QUOTE_SELECTED ? (
          <Button onClick={() => handleResetLink()} color="error">Reset Link</Button>
        ): null}
      </Box>
      <Box sx={(t) => ({ p: t.spacing(4), flex: 1 })}>
        <Routes>
          <Route path="customer-data" element={<CustomerDataSection lead={data}/>}/>
          <Route path="property-details" element={<PropertyDetailsSection lead={data}/>}/>
          <Route path="home-details" element={<HomeQuestionsSection lead={data}/>}/>
          <Route path="additional" element={<AdditionalSection lead={data}/>}/>
          <Route path="quote-sheet" element={<QuoteSheetSection lead={data}/>}/>
          <Route path="history" element={<HistorySection lead={data}/>}/>
          <Route path="quotes" element={<QuotesSection lead={data}/>}/>
          <Route path="policy" element={<PolicySection lead={data}/>}/>
          <Route path="*" element={<Navigate to="customer-data" replace/>}/>
        </Routes>
      </Box>
    </Box>
  );
};
