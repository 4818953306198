const STORAGE_ITEM_KEY = 'rentr-tokens';

class _TokensService {
  saveAuthTokenInLocalStorage(token: string) {
    if (window !== undefined) {
      window.localStorage.setItem(
        STORAGE_ITEM_KEY,
        token
      );
    }
  }

  loadAuthTokenFromLocalStorage(): string | null  {
    if (window !== undefined) {
      try {
        return window.localStorage.getItem(STORAGE_ITEM_KEY);
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  isAuthenticatedInStorage(): boolean {
    return this.loadAuthTokenFromLocalStorage() !== null;
  }

  removeAuthTokenFromStorage()  {
    if (window !== undefined) {
      window.localStorage.removeItem(STORAGE_ITEM_KEY);
    }
  }
}

const TokensService = new _TokensService();

export { _TokensService, TokensService };

