import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, Container, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useTextFieldRendered } from 'components/forms/helpers';
import { SendContactMessageDTO } from 'dtos';
import mountainHouse from 'images/mountain.png';
import { useCallback } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ContactService } from 'services';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required().email(),
  message: yup.string().required()
});

export const HeaderSection = () => {
  const { control, handleSubmit, formState, reset } = useForm<SendContactMessageDTO>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      message: ''
    }
  });

  const { mutate, isLoading, isSuccess } = useMutation(
    (data: SendContactMessageDTO) => ContactService.sendContactMessage(data),
    {
      onSuccess: () => {
        reset();
      },
    }
  );

  const renderTextField = useTextFieldRendered(control, formState.errors);
  const handleSendForm = useCallback((data: SendContactMessageDTO) => {
    mutate(data);
  }, [mutate]);

  return (
    <Box sx={{
      background: 'linear-gradient(180deg, #D2E0E9 0%, #F7F2EE 43.75%)',
      position: 'relative'
    }}>
      <Container>
        <Box
          sx={{
            pt: { md: 28, xs: 18 },
            pb: { md: 28, xs: 8 },
            position: 'relative',
            maxWidth: 600,
            zIndex: 2
          }}
        >
          <Typography variant="h1" sx={{ mb: 2, fontSize: { md: 74, xs: 44 } }}>
            Contact us!
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Send us a message and we will respond via email
          </Typography>

          <Box sx={t => ({
            backgroundColor: '#fff',
            p: 4,
            border: `1px solid ${t.palette.grey['700']}`,
            borderRadius: 2
          })}>
            <form onSubmit={handleSubmit(handleSendForm)} style={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    sm: '1fr',
                    md: '1fr 1fr'
                  },
                  gap: 2
                }}
              >
                {renderTextField('name', 'Name')}
                {renderTextField('email', 'Email')}
              </Box>

              <Box sx={{ mt: 2, mb: 2 }}>
                {renderTextField('message', 'Message', { multiline: true, rows: 5 })}
              </Box>

              <Box
                display='flex'
                sx={{
                  gap: 2,
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { xs: 'center', md: isSuccess ? 'space-between' : 'flex-end' },
                  textAlign: 'center'
                }}
              >
                {isSuccess ? (
                  <Typography sx={{ mt: 2 }}>
                  Message sent!
                  </Typography>
                ) : null}
                <Button
                  sx={{ minWidth: 200, display: 'block' }}
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                >
                  {isLoading ? 'Loading...' : 'Send'}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>

        <Box
          src={mountainHouse}
          component="img"
          alt="House"
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '80%',
            maxHeight: '90%',
            zIndex: 1,
            pointerEvents: 'none',
            objectFit: 'contain',
            objectPosition: 'right bottom'
          }}
        />
      </Container>
    </Box>
  );
};
