import { Box, Button, Container, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/logo-white.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES, SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_PHONE_TEL } from 'rentr-constants';

export const LandingFooter = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ maxHeight: '100%', backgroundColor: t => t.palette.grey['900'] }}>
      <Container sx={{ py: { xs: 4, md: 6 }, textAlign: 'center' }}>
        <Typography variant="h2" sx={{ color: '#fff', mb: 2, fontSize: { xs: 30, md: 42 } }}>
          Get Your Insurance Quotes Now
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff', mb: { md: 4, xs: 3 }, fontSize: { xs: 14, md: 18 } }}>
          Let us find the best value on your insurance!
        </Typography>
        {/* {form} */}
        <Button variant="contained" sx={{ mt: 2, py: 2, px: 6, fontSize: 18, fontWeight: 600 }} onClick={() => navigate('/onboarding/1')}>
          Get Instant Quotes
        </Button>
      </Container>
      <Box sx={{ mb: 4, width: '100%', backgroundColor: '#C9C9C9', opacity: 0.2, height: '2px' }}/>
      <Container sx={{ color: '#fff' }}>
        <Box
          sx={{ mt: 4, gap: 4, flexDirection: { md: 'row', xs: 'column' } }}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex" sx={{ flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ width: { xs: 120, md: 140 } }}>
              <Link to={ROUTES.ROOT}>
                <Logo width="100%"/>
              </Link>
            </Box>
            <Box sx={{ mt: 2, pb: 8 }}>
              <Typography sx={{ color: '#fff' }}>
                © {new Date().getFullYear()} Homeflow. All rights reserved.
              </Typography>
            </Box>
          </Box>
          <Box display="flex" sx={{ gap: { md: 10, xs: 4 }, flexDirection: { md: 'row', xs: 'column' } }}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Resources</Typography>
              <Link to={ROUTES.ABOUT_US}>
                <Typography sx={{ color: '#fff' }}>About Us</Typography>
              </Link>
              <Link to={ROUTES.LICENSES}>
                <Typography sx={{ color: '#fff' }}>Licenses</Typography>
              </Link>
              <Link to={ROUTES.PRIVACY_POLICY}>
                <Typography sx={{ color: '#fff' }}>Privacy Policy</Typography>
              </Link>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Link to={ROUTES.CONTACT}>
                <Typography sx={{ color: '#fff' }}>Contact</Typography>
              </Link>
              <Typography
                sx={{ color: '#fff' }}
                component="a"
                href={`mailto:${SUPPORT_EMAIL}`}
              >
                {SUPPORT_EMAIL}
              </Typography>
              <Typography
                sx={{ color: '#fff' }}
                component="a"
                href={`tel:${SUPPORT_PHONE_TEL}`}
              >
                {SUPPORT_PHONE}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
