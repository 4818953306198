import { MutationOptions, QueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  CreateLeadDTO,
  LeadDTO,
  LeadHistoryDTO,
  LeadQueryDTO,
  LeadsResponseDTO,
  UpdateLeadDTO,
  UpdateSourcesLeadDTO
} from 'dtos';
import { queryClient } from 'rentr-constants';
import { LeadService } from 'services';

export const LEADS_KEY = 'leads';
export const LEAD_HISTORY_KEY = 'lead-history';
export const LEAD_KEY = 'lead';

export const useLeads = (query: LeadQueryDTO, options?: QueryOptions<LeadsResponseDTO>) =>
  useQuery<LeadsResponseDTO>([LEADS_KEY, query], () => LeadService.getAllLeads(query), {
    ...options,
  });

export const useLeadHistory = (id: string, options?: QueryOptions<LeadHistoryDTO[]>) =>
  useQuery<LeadHistoryDTO[]>([LEAD_HISTORY_KEY, id], () => LeadService.getLeadHistory(id), {
    ...options,
  });

export const useLead = (id: string, options?: QueryOptions<LeadDTO>) =>
  useQuery<LeadDTO>([LEAD_KEY, id], () => LeadService.getLead(id), {
    ...options,
  });

export const useUpdateLead = (id: string, options?: MutationOptions<LeadDTO, AxiosError, UpdateLeadDTO>) =>
  useMutation((data: UpdateLeadDTO) => LeadService.updateLead(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([LEAD_KEY, id]);
      await queryClient.invalidateQueries([LEAD_HISTORY_KEY, id]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useUpdateLeadSource = (id: string, options?: MutationOptions<LeadDTO, AxiosError, UpdateSourcesLeadDTO>) =>
  useMutation((data: UpdateSourcesLeadDTO) => LeadService.updateLeadSource(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([LEAD_KEY, id]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useCreateLead = (options?: MutationOptions<LeadDTO, AxiosError, CreateLeadDTO>) =>
  useMutation( (data: CreateLeadDTO) => LeadService.createLead(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([LEADS_KEY]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });
