import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as OutofFlowpayment } from 'images/icons/outofflow-payment.svg';
import { DateTime } from 'luxon';
import * as React from 'react';
import { SUPPORT_PHONE, SUPPORT_PHONE_TEL } from 'rentr-constants';

interface Props {
  firstName: string;
  onQuoteAnotherProperty: () => void;
}

export const End = ({ firstName, onQuoteAnotherProperty }: Props) => {
  const [message, setMessage] = React.useState('');

  React.useEffect(
    function checkTimeVsBusinessHours() {
      const nowInPacific = DateTime
        .local()
        .setZone('America/Los_Angeles');

      const isWeekday = nowInPacific.weekday <= 5;
      const isWithinBusinessHours = (7 <= nowInPacific.hour && nowInPacific.hour < 17);

      if (isWeekday && isWithinBusinessHours) {
        setMessage("We'll send you an email with your quotes when ready.  This usually takes less than 10 minutes.");
      } else {
        setMessage("You've caught us outside our business hours (Monday - Friday, 7AM - 5PM Pacific), but we'll send your quotes at the start of the next business day.");
      }
    },
    []
  );

  return (
    <Box
      display="flex"
      sx={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        my: 4,
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', maxWidth: 600 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h1" sx={{ fontSize: { xs: 24, md: 38 } }}>&#127881;</Typography>
          <Typography variant="h1" sx={{ fontSize: { xs: 24, md: 38 }, mx: { xs: 1, md: 2 } }}>
            You&apos;re all set{firstName?.length ? `, ${firstName}` : undefined}!
          </Typography>
          <Typography variant="h1" sx={{ fontSize: { xs: 24, md: 38 } }}>&#127881;</Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 400, fontSize: { xs: 16, md: 18 }, color: 'rgba(0, 0, 0, 0.9)', textAlign: 'center' }}
        >
          {message}
        </Typography>
        <Box sx={{ my: { xs: 4, md: 6 }, height: { xs: '20vh', md: 'unset' } }} component={OutofFlowpayment} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 400, fontSize: { xs: 16, md: 18 }, lineHeight: { xs: '24px', md: '28px' }, color: 'rgba(0, 0, 0, 0.9)', textAlign: 'center' }}
        >
          If you’d like to chat with an agent about your options, give us a call at
          <Box component="a" sx={{ fontWeight: 700, color: t => t.palette.primary.main }} href={`tel:${SUPPORT_PHONE_TEL}`}>
            {` ${SUPPORT_PHONE} `}
          </Box>
          or you can
          <Button
            variant="text"
            onClick={onQuoteAnotherProperty}
            sx={{
              textTransform: 'none',
              fontWeight: 700,
              px: 1,
              py: 0,
              fontSize: { xs: 16, md: 18 },
              color: (t) => t.palette.primary.main,
              '&:hover': {
                backgroundColor: 'inherit'
              }
            }}
          >
            quote another property
          </Button>
        </Typography>
      </Box>
    </Box>
  );
};