import { Box, CircularProgress } from '@mui/material';
import { Layout } from 'components/Layout';
import { useCurrentUser } from 'queries';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'rentr-constants';
import { AuthService } from 'services';

export const DashboardView = () => {
  const { isLoading } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if(!AuthService.isAuthenticated()) {
      console.warn('Unauthenticated user');
      navigate(ROUTES.LOGIN);
    }
  }, [navigate]);

  if (isLoading) {
    return (
      <Layout>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Outlet/>
    </Layout>
  );
};
