import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import GavelIcon from '@mui/icons-material/Gavel';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, Typography } from '@mui/material';
import { Switch } from 'components/Switch';
import { noop } from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

interface Props {
  hasUnrepairedDamage: boolean;
  hasKnownDefects: boolean;
  hasOpenOrPendingClaims: boolean;
  isPartOfLawsuit: boolean;
  isUnderForeclosure: boolean;
  onSubmit: (values: FormData) => void;
  updating: boolean;
}

interface FormData {
  hasUnrepairedDamage: boolean;
  hasKnownDefects: boolean;
  hasOpenOrPendingClaims: boolean;
  isPartOfLawsuit: boolean;
  isUnderForeclosure: boolean;
}

const schema = yup.object().shape({
  hasUnrepairedDamage: yup.boolean().nullable(),
  hasKnownDefects: yup.boolean().nullable(),
  hasOpenOrPendingClaims: yup.boolean().nullable(),
  isPartOfLawsuit: yup.boolean().nullable(),
  isUnderForeclosure: yup.boolean().nullable()
});

const renderSwitch = (control, name) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <Switch
        checked={Boolean(value)}
        onChange={onChange}
        color="error"
      />
    )}
  />
);

const renderCard = (control, name, label, description, icon, onLabelClick = noop) => (
  <Box
    sx={{
      width: { xs: '80vw', md: 660 },
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: { xs: 'stretch', md: 'space-between' },
      alignItems: 'center',
      py: { xs: 2, md: 2 },
      px: { xs: 2, md: 4 },
      backgroundColor: '#fff',
      borderRadius: 1,
      boxShadow: '0px 0px 10px rgba(53, 64, 82, 0.1)',
      border: (t) => t.palette.grey['500'],
    }}
  >
    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: { xs: 2, md: 4 } }}>
      {renderSwitch(control, name)}
    </Box>
    <Box display="flex" onClick={onLabelClick} sx={{ '&:hover': { cursor: 'pointer' }}}>
      <Box sx={{ display: { xs: 'none', md: 'flex'}, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mr: 4 }}>
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: 'rgba(0, 0, 0, 0.7)',
            // fontWeight: 600,
            fontSize: { xs: 16, md: 22 },
            mb: 0.5
          }}>
          {label}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: t => t.palette.grey['800'],
            fontSize: { xs: 14, md: 16 }
          }}>
          {description}
        </Typography>
      </Box>
    </Box>
    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: { xs: 2, md: 4 } }}>
      {renderSwitch(control, name)}
    </Box>
  </Box>
);

export const RedFlags = ({
  hasUnrepairedDamage = false,
  hasKnownDefects = false,
  hasOpenOrPendingClaims = false,
  isPartOfLawsuit = false,
  isUnderForeclosure = false,
  onSubmit,
  updating = false
}: Props) => {
  const { control, getValues, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      hasUnrepairedDamage: hasUnrepairedDamage,
      hasKnownDefects: hasKnownDefects,
      hasOpenOrPendingClaims: hasOpenOrPendingClaims,
      isPartOfLawsuit: isPartOfLawsuit,
      isUnderForeclosure: isUnderForeclosure
    }
  });

  const handleNext = React.useCallback(() => onSubmit(getValues()), [onSubmit, getValues]);

  const toggleValue = React.useCallback((name: keyof FormData) => setValue(name, !getValues(name)), [setValue, getValues]);

  return (
    <SectionWrapper
      title="Do any of the following apply to your home?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 3 } }}>
        {renderCard(
          control,
          'hasUnrepairedDamage',
          'UNREPAIRED DAMAGE',
          'The property has visible damage that has not yet been repaired',
          <LocalFireDepartmentIcon sx={{ color: (t) => t.palette.grey['700'], fontSize: { xs: 40, md: 54 } }} />,
          () => toggleValue('hasUnrepairedDamage')
        )}
        {renderCard(
          control,
          'hasKnownDefects',
          'KNOWN DEFECTS',
          'There are known defects with the structural integrity of the property',
          <BrokenImageIcon sx={{ color: (t) => t.palette.grey['700'], fontSize: { xs: 40, md: 54 } }} />,
          () => toggleValue('hasKnownDefects')
        )}
        {renderCard(
          control,
          'hasOpenOrPendingClaims',
          'OPEN / PENDING CLAIMS',
          'The property has outstanding insurance claims associated with it',
          <PendingActionsIcon sx={{ color: (t) => t.palette.grey['700'], fontSize: { xs: 40, md: 54 } }} />,
          () => toggleValue('hasOpenOrPendingClaims')
        )}
        {renderCard(
          control,
          'isPartOfLawsuit',
          'LAWSUIT',
          'The property itself is part of an ongoing lawsuit',
          <GavelIcon sx={{ color: (t) => t.palette.grey['700'], fontSize: { xs: 40, md: 54 } }} />,
          () => toggleValue('isPartOfLawsuit')
        )}
        {renderCard(
          control,
          'isUnderForeclosure',
          'FORECLOSURE',
          'The mortgage company is in the process of repossessing the home',
          <AccountBalanceIcon sx={{ color: (t) => t.palette.grey['700'], fontSize: { xs: 40, md: 54 } }} />,
          () => toggleValue('isUnderForeclosure')
        )}
      </Box>
    </SectionWrapper>
  );
};