import { Box } from '@mui/material';
import { PropertyType } from 'dtos';
import { ReactComponent as ApartmentIcon } from 'images/icons/apartment.svg';
import { ReactComponent as CondoUnitIcon } from 'images/icons/condo-unit.svg';
import { ReactComponent as LandIcon } from 'images/icons/land.svg';
import { ReactComponent as SingleHouseIcon } from 'images/icons/single-house.svg';
import { ReactComponent as TriplexIcon } from 'images/icons/triplex.svg';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<PropertyType>, 'onClick'>> = [
  {
    image: <Box sx={{ height: { xs: 50, md: 80 } }}><Box sx={{ width: '100%' }} component={SingleHouseIcon} /></Box>,
    label: 'Single Family',
    description: 'House, Townhome, or Rowhome',
    value: PropertyType.SINGLE_FAMILY
  },
  {
    image: <Box sx={{ height: { xs: 50, md: 80 } }}><Box sx={{ width: '100%' }} component={ApartmentIcon} /></Box>,
    label: 'Condo Unit',
    description: 'Condo / Apartment',
    value: PropertyType.CONDO_UNIT
  },
  {
    image: <Box sx={{ height: { xs: 50, md: 80 } }}><Box sx={{ width: '100%' }} component={TriplexIcon} /></Box>,
    label: 'Multifamily (2-4)',
    description: 'Duplex, Triplex, or Quadplex',
    value: PropertyType.DUPLEX
  },
  {
    image: <Box sx={{ height: { xs: 50, md: 80 } }}><Box sx={{ width: '100%' }} component={CondoUnitIcon} /></Box>,
    label: 'Multifamily (5+)',
    description: 'Multifamily with 5+ units',
    value: PropertyType.MULTIFAMILY
  },
  {
    image: <Box sx={{ height: { xs: 50, md: 80 } }}><Box sx={{ width: '100%' }} component={LandIcon} /></Box>,
    label: 'Other',
    description: 'Land, Mobile Home, etc.',
    value: PropertyType.OTHER
  },
];

interface Props {
  value?: PropertyType;
  onSubmit: (value: PropertyType) => void;
  updating?: boolean;
}

export const PropertyTypeSection = ({ value: initialValue, onSubmit, updating = false}: Props) => {
  const [selection, setSelection] = React.useState<PropertyType>(initialValue);

  const handleNext = React.useCallback(() => {
    onSubmit(selection);
  }, [selection, onSubmit]);

  return (
    <SectionWrapper
      title="What type of property is this?"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleNext}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: { xs: 2, md: 4 },
          width: { xs: '100vw' }
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            sx={{
              display: 'flex',
              flexGrow: { xs: 1, md: 0 },
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: { xs: '40%' },
              width: { md: '25%' },
              pt: { xs: 3, md: 4 }
            }}
            selected={selection === option.value}
            onClick={(v) => {
              setSelection(v);
            }}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
    </SectionWrapper>
  );
};
