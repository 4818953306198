import { Box } from '@mui/material';
import { FixturesAndFinishes, PropertyType } from 'dtos';
import { ReactComponent as Basic } from 'images/icons/house-basic.svg';
import { ReactComponent as Top } from 'images/icons/house-premium.svg';
import { ReactComponent as Extra } from 'images/icons/house-standard.svg';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<FixturesAndFinishes>, 'onClick'>> = [
  {
    image: <Box component={Basic} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'Just the basics',
    description: 'Example: medium grade flooring, carpeting, or cabinets, and other common details',
    value: FixturesAndFinishes.SIMPLE,
    footer: '50% choose this',
  },
  {
    image: <Box component={Extra} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'A few extras',
    description: 'Example: built-in bookshelves, marble/granite counters, above average appliances',
    value: FixturesAndFinishes.EXTRA,
    footer: '45% choose this',
  },
  {
    image: <Box component={Top} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'Top of the line',
    description: 'Example: marble flooring, crystal chandeliers, commercial or built-in appliances, ornate finishes',
    value: FixturesAndFinishes.TOP,
    footer: '5% choose this',
  }
];

interface Props {
  propertyType: PropertyType;
  value?: FixturesAndFinishes;
  onSubmit: (val: FixturesAndFinishes) => void;
  updating?: boolean;
}

export const SINGLE_UNIT_PROPERTY_TYPES: PropertyType[] = [
  PropertyType.SINGLE_FAMILY,
  PropertyType.CONDO_UNIT,
];

export const PropertyConditions = ({
  propertyType,
  value: initialValue,
  onSubmit,
  updating = false
}: Props) => {
  const [selection, setSelection] = React.useState<FixturesAndFinishes>(initialValue);
  const [isMultiFamily, _] = React.useState(
    !SINGLE_UNIT_PROPERTY_TYPES.includes(propertyType)
  );
  const handleNext = React.useCallback(() => {
    onSubmit(selection);
  }, [selection, onSubmit]);

  return (
    <SectionWrapper
      title={`How would you describe your ${isMultiFamily ? 'property' : 'home'}'s exterior and interior?`}
      description="This helps us estimate replacement costs in case of damage"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleNext}
      disableButtons={!selection}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: 1, md: 4},
          justifyContent: 'center',
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            sx={{
              flex: 1,
              maxWidth: { xs: '40%' },
              py: { xs: 2, md: 4 }
            }}
            selected={selection === option.value}
            onClick={setSelection}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
    </SectionWrapper>
  );
};
