import { Box, Typography } from '@mui/material';
import { Modal } from 'components/Modal';
import { InsuranceType, MailingType } from 'dtos';
import { useMailingListSignup } from 'hooks/useMailingListSignup';
import { ReactComponent as Calendar } from 'images/icons/calendar.svg';
import { ReactComponent as House } from 'images/icons/home.svg';
import { ReactComponent as Keys } from 'images/icons/keys.svg';
import { ReactComponent as Support } from 'images/icons/support.svg';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<InsuranceType>, 'onClick'>> = [
  {
    image: <Box component={Keys} sx={{ height: { xs: 60, md: 175 } }}/>,
    label: 'Homeowner',
    description: 'I own and live in this property',
    value: InsuranceType.HOMEOWNER
  },
  {
    image: <Box component={Calendar} sx={{ height: { xs: 60, md: 175 } }}/>,
    label: 'Landlord',
    description: 'I own this property and rent it out',
    value: InsuranceType.LANDLORD
  },
  {
    image: <Box component={House} sx={{ height: { xs: 60, md: 175 } }}/>,
    label: 'Renter',
    description: 'I live in this property, but I do not own it',
    value: InsuranceType.RENTER,
  },
];

interface Props {
  value?: InsuranceType;
  onSubmit: (val: InsuranceType) => void;
  updating?: boolean;
}

export const KindOfInsuranceSection = ({ value: initialValue, onSubmit, updating = false}: Props) => {
  const [insuranceType, setInsuranceType] = React.useState<InsuranceType>(initialValue);
  const [open, setOpen] = React.useState(false);

  const { form } = useMailingListSignup({
    type: MailingType.RENTER_INSURANCE,
    containerProps: { width: '100%', mt: 4, mb: 4 },
    initMessage: 'Submit',
    successMessage: 'Thank you!'
  });

  const handleNext = React.useCallback(() => {
    onSubmit(insuranceType);
  }, [insuranceType, onSubmit]);

  return (
    <SectionWrapper
      title="What kind of insurance do you need?"
      description={'Let\'s get you the right kind of coverage'}
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleNext}
      disableButtons={!insuranceType}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', md: 'space-between' },
          gap: { xs: 2, md: 4 },
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            sx={{
              flex: 1,
              maxWidth: { xs: '40%', md: '100%' },
              px: { md: 4 }
            }}
            selected={insuranceType === option.value}
            onClick={(val: InsuranceType) => {
              if (val === InsuranceType.RENTER) {
                setOpen(true);
              } else {
                setInsuranceType(val);
              }
            }}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} boxSx={{ width: 400 }}>
        <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
              We are not selling renter&apos;s insurance yet.
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 320, textAlign: 'center' }}>
              Unfortunately, we are not selling renter&apos;s insurance at this time. Please feel free to leave us your
              email, and we will let you know when we are!
          </Typography>
          {form}
          <Box sx={{ mt: 4 }}>
            <Support/>
          </Box>
        </Box>
      </Modal>
    </SectionWrapper>
  );
};
