import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { includes, startCase } from 'lodash';
import throttle from 'lodash/throttle';
import * as React from 'react';
import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { GOOGLE_MAPS_API_KEY } from 'rentr-constants';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export interface TSelectedSuggestion {
  formattedAddress: string;
  zipCode?: string;
  city?: string;
  street?: string;
  suite?: string;
  state?: string;
  country?: string;
  streetNumber?: string;
  location: {
    lat: number,
    lng: number
  };
}

const extractComponent = (components: google.maps.GeocoderAddressComponent[], type: string) => (
  components.find((component: google.maps.GeocoderAddressComponent) => (
    includes(component.types, type)
  ))
);

export const parseSuggestion = (suggestion: google.maps.GeocoderResult): TSelectedSuggestion => {
  const components = suggestion.address_components;
  const formattedAddress = suggestion.formatted_address;

  const streetNumber = extractComponent(components, 'street_number');
  const street = extractComponent(components, 'route');
  const suite = extractComponent(components, 'subpremise');
  const city = extractComponent(components, 'locality') || extractComponent(components, 'sublocality') || extractComponent(components, 'neighborhood');
  const state = extractComponent(components, 'administrative_area_level_1');
  const zipCode = extractComponent(components, 'postal_code');
  const country = extractComponent(components, 'country');

  return {
    formattedAddress,
    streetNumber: streetNumber ? streetNumber.long_name : undefined,
    street: street ? street.short_name : undefined,
    suite: suite ? startCase(suite.long_name) : undefined,
    city: city ? city.long_name : undefined,
    state: state ? state.short_name : undefined,
    zipCode: zipCode ? zipCode.long_name : undefined,
    country: country ? country.long_name : undefined,
    location: {
      lat: suggestion.geometry.location.lat(),
      lng: suggestion.geometry.location.lng()
    }
  };
};

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

let AutocompleteService: google.maps.places.AutocompleteService | null = null;
let GeocoderService: google.maps.Geocoder | null = null;

interface Props {
  label?: string;
  onSelect: (suggestion: TSelectedSuggestion) => void;
  initInputValue?: string;
  onClear?: () => void;
  inputOptions?: TextFieldProps
}

export default function AddressAutocomplete({ label = 'Property Address', onSelect, initInputValue, onClear, inputOptions }: Props) {
  const [value, setValue] = React.useState<AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = React.useState(initInputValue || '');
  const [options, setOptions] = React.useState<readonly AutocompletePrediction[]>([]);
  const loaded = React.useRef(false);

  // Load the script
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const handleSelect = useCallback((event: React.SyntheticEvent, value: AutocompletePrediction | null) => {
    setValue(value);

    if (!value && onClear) {
      onClear();
    }

    if (AutocompleteService) {
      AutocompleteService.getPlacePredictions(
        { input: value.description, componentRestrictions: { country: ['US'] }},
        (results?: readonly AutocompletePrediction[]) => {
          const bestResult = results.map(x => x).sort((r1, r2) => r1.matched_substrings > r2.matched_substrings ? 1 : -1).at(results.length - 1);
          if (GeocoderService) {
            GeocoderService.geocode({ placeId: bestResult?.place_id }, (res) => {
              if(res && res[0]) {
                const parsed = parseSuggestion(res[0]);
                onSelect(parsed);
              }
            });
          }
        }
      );
    }

  }, [onSelect, onClear]);

  // Handle fetching on input change
  const fetch = React.useMemo(() => throttle(
    (
      request: { input: string },
      callback: (results?: readonly AutocompletePrediction[]) => void
    ) => {
      if (AutocompleteService) {
        AutocompleteService.getPlacePredictions(
          { ...request, componentRestrictions: { country: ['US'] }},
          callback
        );
      }
    },
    200
  ), []);

  React.useEffect(() => {
    let active = true;

    if (!AutocompleteService && window.google) {
      AutocompleteService = new window.google.maps.places.AutocompleteService();
    }

    if (!GeocoderService && window.google) {
      GeocoderService = new window.google.maps.Geocoder();
    }

    if (!AutocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly AutocompletePrediction[]) => {
      if (active) {
        let newOptions: readonly AutocompletePrediction[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, value, fetch]);

  return (
    <Autocomplete
      sx={{ backgroundColor: '#fff', width: '100%', borderRadius: 2 }}
      id="google-auto-complete"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={handleSelect}
      onInputChange={(event, newInputValue) => {
        if (!inputValue?.length && newInputValue?.length) {
          ReactGA.event('type_auto_complete_address');
        }
        setInputValue(newInputValue);
      }}
      noOptionsText="Start typing your address..."
      renderInput={(params) => (
        <TextField {...params} label={label} InputLabelProps={{ shrink: true }} fullWidth {...inputOptions} />
      )}
      freeSolo
      renderOption={(props, option) => (
        <li {...props} key={option.place_id}>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                component={LocationOnOutlinedIcon}
                sx={{ color: 'text.secondary', mr: 2 }}
              />
            </Grid>
            <Grid item xs>
              {option.structured_formatting.main_text}
              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}
