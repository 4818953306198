import { Box, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AuthService } from 'services';
import { FCC } from 'types';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey['400'],
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  width: '100vw'
}));

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  gap: theme.spacing(2)
}));

export const LoginView: FCC = () => {
  const {
    mutate,
    isLoading
  } = useMutation<void, AxiosError, void>(() => AuthService.loginWithAuth0());

  return (
    <Container>
      <Card sx={{ m: 2, p: { xs: 4, md: 10 } }}>
        <Heading>
          <Typography variant="h2">Welcome to Homeflow</Typography>
          <Typography variant="body1" sx={(t) => ({ color: t.palette.grey['800'] })}>
            Sign in to your account below.
          </Typography>
        </Heading>

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button sx={{ width: 200 }} disabled={isLoading} onClick={() => mutate()} variant="contained">
            {isLoading ? 'Signing in...' : 'Sign in'}
          </Button>
        </Box>
      </Card>
    </Container>
  );
};
