import { CarrierDTO } from 'dtos/carrier';

export enum PolicyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export enum PaymentStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export interface CheckoutStatusResponseDTO {
  status: PaymentStatus;
}

export interface PolicyDTO {
  id: string;
  quoteId: string;
  carrierId: string;
  carrier: CarrierDTO;
  leadId: string;
  externalId: string | null;
  isPaid: boolean;
  isSigned: boolean;
  policyNumber: string | null;
  premium: number;
  status: PolicyStatus;
  deductibles: Record<string, unknown>;
  coverages: Record<string, unknown>;
  expirationDate: string;
  effectiveDate: string;
}

export interface CreatePolicyClientDTO {
  quoteId: string;
}

export interface ActivatePolicyDTO {
  policyNumber: string;
}
