import { BigLoaderWithMessage } from 'components/BigLoaderWithMessage';
import { ErrorTypography } from 'components/ErrorTypography';
import { StatusContainer } from 'components/StatusContainer';
import { PaymentStatus, PolicyDTO } from 'dtos';
import { ReactComponent as Money } from 'images/icons/money.svg';
import {
  useCheckPolicyBillingStatus,
  useCreatePolicyClient,
  useRequestPaymentPolicyLink,
} from 'queries/usePolicy';
import qs from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface QueryParams {
  checkout?: 'success';
}

interface Props {
  quoteId: string;
  policy?: PolicyDTO;
  refetch: () => void;
}

// TODO: Handle error of the same email
export const PaymentSection = ({ quoteId, policy, refetch }: Props) => {
  const { search } = useLocation();
  const data: QueryParams = qs.parse(search);
  const [policyId, setPolicyId] = React.useState<string>(policy?.id);

  const {
    mutate: getPaymentLink,
    isLoading,
    error,
  } = useRequestPaymentPolicyLink({
    onSuccess: (link) => {
      window.location.replace(link);
    },
  });

  const { mutate: createPolicy, isLoading: _isCreatingPolicy } =
    useCreatePolicyClient({
      onSuccess: (policy) => {
        setPolicyId(policy.id);
        getPaymentLink(policy.id);
      },
    });

  useCheckPolicyBillingStatus(policyId, {
    enabled: data.checkout === 'success',
    refetchInterval: 2000,
    onSuccess: (data) => {
      if (data.status === PaymentStatus.COMPLETED) {
        refetch();
      }
    },
  });

  if (data.checkout === 'success') {
    return (
      <BigLoaderWithMessage
        title="We are waiting for payment confirmation. It may take a minute."
        description="Did you know that America Ferrera had her smile insured for $10,000,000?"
      />
    );
  }

  return (
    <StatusContainer
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 10,
      }}
      description="Please provide payment to finalize your coverage"
      title="You are almost there!"
      image={<Money />}
      headerFirst
      button={{
        label: isLoading ? 'Preparing payment...' : 'Continue with Payment',
        onClick: () => policy ? getPaymentLink(policy.id) : createPolicy({ quoteId }),
        props: {
          sx: { minWidth: 200 },
          disabled: isLoading,
        },
      }}
    >
      {<ErrorTypography error={error} />}
    </StatusContainer>
  );
};
