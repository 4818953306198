import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from 'rentr-constants';
import { TokensService } from 'services';

export const NotFoundView = () => {
  const isAuthenticated = TokensService.isAuthenticatedInStorage();
  const redirectUrl = isAuthenticated ? ROUTES.dashboard.ROOT : ROUTES.LOGIN;
  const redirectText = isAuthenticated ? 'dashboard' : 'login';

  return (
    <Container>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h2">Page not found</Typography>
        <Typography variant="body1">
          Please navigate to{' '}
          <Link to={redirectUrl}>{redirectText}</Link>
        </Typography>
      </Box>
    </Container>
  );
};
