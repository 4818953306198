import { Box } from '@mui/material';
import { LandingFooter } from 'components/LandingFooter';
import { LandingNavbar } from 'components/LandingNavbar';

import { HeaderSection } from './sections/Header';

export const ContactView = () => {
  return (
    <Box>
      <LandingNavbar/>
      <HeaderSection/>
      <LandingFooter/>
    </Box>
  );
};
