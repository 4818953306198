import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserDTO } from 'dtos';
import { AuthService, UserService } from 'services';

export const USER_KEY = 'current-user';

export const useCurrentUser = (options?: UseQueryOptions<UserDTO>) => useQuery<UserDTO>([USER_KEY], () => UserService.getCurrentUser(), {
  staleTime: 1000 * 60 * 5,
  enabled: AuthService.isAuthenticated(),
  ...options,
});

