import { Box, RadioGroup } from '@mui/material';
import { OccupancyHomeowner } from 'dtos';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: string;
  onSubmit: (val: string) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<string>, 'onSelect'>> = [
  {
    label: 'Primary Residence',
    value: OccupancyHomeowner.FULL_TIME,
  },
  {
    label: 'Secondary / Vacation',
    value: OccupancyHomeowner.VACATION,
  },
  {
    label: 'Operating a Business',
    value: 'operating_a_business',
  },
];

export function PropertyUse({ value: initialValue, onSubmit, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<string>(initialValue);

  const handleNext = React.useCallback(() => {
    onSubmit(currentValue);
  }, [currentValue, onSubmit]);

  return (
    <SectionWrapper
      title="What do you use this property for?"
      description="This helps us determine which type of policy or endorsements you'll need"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!currentValue}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 4 }, width: { xs: '100%', md: '480px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={setCurrentValue}
              selected={currentValue === option.value}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Box>
    </SectionWrapper>
  );
}
