import { Box } from '@mui/material';
import { InsuranceHolder } from 'dtos';
import { ReactComponent as HolderCompany } from 'images/icons/holder-company.svg';
import { ReactComponent as HolderPerson } from 'images/icons/holder-person.svg';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<InsuranceHolder>, 'onClick'>> = [
  {
    iconLast: true,
    image: <Box component={HolderPerson} sx={{ height: { xs: 75, md: 175 } }} />,
    label: 'Person',
    description: 'Quote for an individual',
    value: InsuranceHolder.PERSON,
  },
  {
    iconLast: true,
    image: <Box component={HolderCompany} sx={{ height: { xs: 75, md: 175 } }} />,
    label: 'Company',
    description: 'Quote a corporation or LLC',
    value: InsuranceHolder.COMPANY,
  },
];

interface Props {
  value?: InsuranceHolder;
  onSubmit: (val: InsuranceHolder) => void;
  updating?: boolean;
}

export const WhoIsGettingInsuredSection = ({ value: initialValue, onSubmit, updating = false }: Props) => {
  const [insuranceHolder, setInsuranceHolder] = React.useState<InsuranceHolder>(initialValue);
  const handleNext = React.useCallback(() => onSubmit(insuranceHolder), [insuranceHolder, onSubmit]);

  return (
    <SectionWrapper
      title="Who is getting insured?"
      description="Are you a person or a company?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!insuranceHolder}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { xs: 'space-between', md: 'center' },
          gap: { xs: 2, md: 4 },
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            sx={{
              flex: 1,
              maxWidth: { xs: '50%' },
              p: { xs: 2, md: 4 },
              justifyContent: 'space-between'
            }}
            selected={insuranceHolder === option.value}
            onClick={setInsuranceHolder}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
    </SectionWrapper>
  );
};
