import { BigLoaderWithMessage } from 'components/BigLoaderWithMessage';
import { Layout } from 'components/Layout';

interface Props {
  title: string;
  onLogoClickPath?: string;
  description?: string;
}

export const LoaderLayout = ({ title, onLogoClickPath, description }: Props) => {
  return (
    <Layout onLogoClickPath={onLogoClickPath} alwaysContact>
      <BigLoaderWithMessage title={title} description={description} />
    </Layout>
  );
};
