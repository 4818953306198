import EmailIcon from '@mui/icons-material/Email';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { Box, ModalProps, Typography } from '@mui/material';
import { Modal } from 'components/Modal';
import { ReactComponent as Support } from 'images/icons/support.svg';
import { CSSProperties } from 'react';
import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_PHONE_TEL } from 'rentr-constants';

const LINK_STYLES: CSSProperties = {
  display: 'flex', alignItems: 'center', color: 'inherit', justifyContent: 'center'
};

export const ContactModal = (props: Omit<ModalProps, 'children'>) => {
  return (
    <Modal {...props} boxSx={{ width: 420 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ mb: 2 }}>Need help?</Typography>
        <Typography variant="body1" sx={{ maxWidth: 280, textAlign: 'center' }}>
          Call us to have one of our licensed agents guide you through the process.
        </Typography>

        <Box sx={{ mt: 4, display: 'flex', gap: 1, flexDirection: 'column', justifyContent: 'center', color: t => t.palette.primary.main }}>
          <a href={`tel:${SUPPORT_PHONE_TEL}`} style={LINK_STYLES}>
            <PermPhoneMsgIcon sx={{ mr: 1 }}/> {SUPPORT_PHONE}
          </a>
          <a href={`mailto:${SUPPORT_EMAIL}`} style={LINK_STYLES}>
            <EmailIcon sx={{ mr: 1 }}/> {SUPPORT_EMAIL}
          </a>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Support/>
        </Box>
      </Box>
    </Modal>
  );
};
