import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, InputAdornment, TextFieldProps, Typography } from '@mui/material';
import {
  useCheckboxFieldRendered,
  useCurrencyFieldRendered,
  useDateFieldRendered,
  usePercentageFieldRendered,
  useSelectRendered,
  useTextFieldRendered
} from 'components/forms/helpers';
import dayjs from 'dayjs';
import { LeadDTO, QuoteDeductibleType, QuoteDTO, QuoteProductType, UpdateQuoteDTO } from 'dtos';
import { isUndefined, startCase } from 'lodash';
import { useCarriers } from 'queries';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface Props {
  lead?: Partial<LeadDTO>
  quote?: Partial<QuoteDTO>;
  onSubmit: (data: UpdateQuoteDTO) => void;
  isLoading?: boolean;
  submitLabel?: string;
}

interface FormData {
  carrierId?: string;
  showToClient?: boolean;
  premium?: number;
  productType?: QuoteProductType;
  effectiveDate?: string;
  expirationDate?: string;
  coverageA?: number;
  coverageB?: number | null;
  coverageC?: number | null;
  coverageD?: number | null;
  coverageE?: number | null;
  coverageF?: number | null;
  extendedRebuildingCost?: number | null;
  equipmentBreakdown: boolean;
  serviceLine: boolean;
  pprc?: boolean;
  waterBackup: boolean;
  earthquake?: boolean;
  sinkholeCollapse?: boolean;
  screenedEnclosure?: number | null;
  catastrophicGroundCollapse?: boolean;
  lossAssessment?: boolean;
  limitedAnimalLiability?: number | null;
  deductible?: number;
  deductibleType: QuoteDeductibleType;
  windDeductible?: number | null;
  windDeductibleType: QuoteDeductibleType | null;
  hurricaneDeductible?: number | null;
  hurricaneDeductibleType: QuoteDeductibleType | null;
  mortgagePaymentProtection: boolean;
  domesticWorkersCoverage: boolean;
  caFairPlanRequired: boolean;
  roofFireAndLightningOnly: boolean;
}

const schema = yup.object().shape({
  carrierId: yup.string().required(),
  effectiveDate: yup.string().required(),
  expirationDate: yup.string().required(),
  showToClient: yup.boolean(),
  equipmentBreakdown: yup.boolean().nullable(),
  serviceLine: yup.boolean().nullable(),
  pprc: yup.boolean(),
  earthquake: yup.boolean(),
  sinkholeCollapse: yup.boolean(),
  catastrophicGroundCollapse: yup.boolean(),
  lossAssessment: yup.boolean(),
  premium: yup.number().required(),
  coverageA: yup.number().required(),
  coverageB: yup.number().required().nullable(),
  coverageC: yup.number().required().nullable(),
  coverageD: yup.number().required().nullable(),
  coverageE: yup.number().required().nullable(),
  coverageF: yup.number().required().nullable(),
  extendedRebuildingCost: yup.number().required(),
  waterBackup: yup.boolean().nullable(),
  // screenedEnclosure: yup.number().required(),
  // windDeductible: yup.number().required(),
  // limitedAnimalLiability: yup.number().required(),
  // hurricaneDeductible: yup.number().required(),
  deductible: yup.number().required(),
  productType: yup.string().required(),
  deductibleType: yup.string().required(),
  // windDeductibleType: yup.string().required(),
  // hurricaneDeductibleType: yup.string().required(),
  mortgagePaymentProtection: yup.boolean().nullable(),
  domesticWorkersCoverage: yup.boolean().nullable(),
  caFairPlanRequired: yup.boolean(),
  roofFireAndLightningOnly: yup.boolean()
});

const PERCENTAGE_INPUT_OPTIONS: TextFieldProps = {
  type: 'number',
  inputProps: { min: 0 },
  InputProps: {
    startAdornment: <InputAdornment position="start">%</InputAdornment>
  }
};

const DATE_FORMAT = 'YYYY-MM-DD';

const FieldGroup: React.FC<React.PropsWithChildren & { columns?: number }> = ({ children, columns = 2 }) => (
  <Box sx={{
    display: 'grid',
    mb: 3,
    gridTemplateColumns: {
      sm: '1fr',
      md: `${Array.from(Array(columns).keys()).map((_) => '1fr').join(' ')}`
    },
    gap: (t) => t.spacing(2)
  }}>
    {children}
  </Box>
);

export const QuoteForm = ({ lead, quote, isLoading, onSubmit, submitLabel }: Props) => {
  const { data: carriers } = useCarriers();
  const defaultEffectiveDate = lead?.effectiveDate || dayjs(new Date()).format(DATE_FORMAT);
  const defaultExpirationDate = dayjs(new Date()).add(30, 'days').format(DATE_FORMAT);
  const defaultDeductibleType = QuoteDeductibleType.DOLLARS;

  const { control, handleSubmit, formState, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      carrierId: quote?.carrierId,
      effectiveDate: quote?.effectiveDate || defaultEffectiveDate,
      expirationDate: quote?.expirationDate || defaultExpirationDate,
      showToClient: isUndefined(quote?.showToClient) ? true : quote?.showToClient,
      equipmentBreakdown: quote?.equipmentBreakdown,
      serviceLine: quote?.serviceLine,
      pprc: quote?.pprc || false,
      earthquake: quote?.earthquake || false,
      sinkholeCollapse: quote?.sinkholeCollapse || false,
      catastrophicGroundCollapse: quote?.catastrophicGroundCollapse || false,
      lossAssessment: quote?.lossAssessment || false,
      premium: quote?.premium,
      coverageA: quote?.coverageA,
      coverageB: quote?.coverageB,
      coverageC: quote?.coverageC,
      coverageD: quote?.coverageD,
      coverageE: quote?.coverageE,
      coverageF: quote?.coverageF,
      extendedRebuildingCost: isUndefined(quote?.extendedRebuildingCost) ? 0 : quote?.extendedRebuildingCost,
      waterBackup: quote?.waterBackup,
      screenedEnclosure: quote?.screenedEnclosure,
      windDeductible: quote?.windDeductible,
      limitedAnimalLiability: quote?.limitedAnimalLiability,
      hurricaneDeductible: quote?.hurricaneDeductible,
      deductibleType: quote?.deductibleType || defaultDeductibleType,
      deductible: quote?.deductible,
      productType: quote?.productType,
      windDeductibleType: quote?.windDeductibleType,
      hurricaneDeductibleType: quote?.hurricaneDeductibleType,
      mortgagePaymentProtection: quote?.mortgagePaymentProtection,
      domesticWorkersCoverage: quote?.domesticWorkersCoverage,
      caFairPlanRequired: quote?.caFairPlanRequired || false,
      roofFireAndLightningOnly: quote?.roofFireAndLightningOnly || false
    }
  });
  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderSelect = useSelectRendered(control, formState.errors);
  const renderCheckbox = useCheckboxFieldRendered(control, formState.errors);
  const renderDate = useDateFieldRendered(control, formState.errors);
  const renderCurrency = useCurrencyFieldRendered(control, formState.errors);
  const renderPercentage = usePercentageFieldRendered(control, formState.errors);
  const watchedDeductibleType = watch('deductibleType');
  // const windDeductibleType = watch('windDeductibleType');
  // const hurricaneDeductibleType = watch('hurricaneDeductibleType');

  return (
    <form onSubmit={handleSubmit(onSubmit, () => console.log(schema.validate(control._formValues)))} style={{ width: '100%' }}>
      <Box>
        <FieldGroup>
          {renderSelect(carriers ? carriers.map(c => ({ value: c.id, label: c.displayName })) : [], 'carrierId', 'Carrier')}
          {renderCheckbox('showToClient', 'Show to Client')}
          {renderSelect(Object.values(QuoteProductType).map(v => ({
            value: v,
            label: v.toUpperCase()
          })), 'productType', 'Product Type')}
          {renderDate('effectiveDate', 'Effective Date', { required: true })}
          {/*renderDate('expirationDate', 'Expiration Date',  { required: true })*/}
          {renderCurrency('premium', 'Premium')}
        </FieldGroup>
        <Typography variant="h4" sx={{ mb: 2, grid: 2 }}>
          Deductible
        </Typography>
        <FieldGroup>
          {renderSelect(Object.values(QuoteDeductibleType).map(v => ({
            value: v,
            label: startCase(v)
          })), 'deductibleType', 'Deductible Type')}
          {watchedDeductibleType === QuoteDeductibleType.DOLLARS
            ? renderCurrency('deductible', 'Deductible')
            : renderTextField('deductible', 'Deductible', PERCENTAGE_INPUT_OPTIONS)}
        </FieldGroup>
        {/* {renderTextField('windDeductible', 'Wind Deductible', getCorrectInputOptions(windDeductibleType))}
        {renderSelect(Object.values(QuoteDeductibleType).map(v => ({
          value: v,
          label: startCase(v)
        })), 'windDeductibleType', 'Wind Deductible Type')}
        {renderTextField('hurricaneDeductible', 'Hurricane Deductible', getCorrectInputOptions(hurricaneDeductibleType))}
        {renderSelect(Object.values(QuoteDeductibleType).map(v => ({
          value: v,
          label: startCase(v)
        })), 'hurricaneDeductibleType', 'Hurricane Deductible Type')} */}

        <Typography variant="h4" sx={{ mb: 2 }}>
          Coverages
        </Typography>
        <FieldGroup>
          {renderCurrency('coverageA', 'Coverage A (Dwelling)')}
          {renderCurrency('coverageB', 'Coverage B (Other Structures)')}
          {renderCurrency('coverageC', 'Coverage C (Personal Property)')}
          {renderCurrency('coverageD', 'Coverage D (Loss of Use)')}
          {renderCurrency('coverageE', 'Coverage E (Personal Liability)')}
          {renderCurrency('coverageF', 'Coverage F (Medical Payments)')}
        </FieldGroup>

        <Typography variant="h4" sx={{ mb: 2 }}>
          Extended Coverages
        </Typography>
        <FieldGroup>
          {/* {renderTextField('screenedEnclosure', 'Screened Enclosure', { ...DOLLAR_INPUT_OPTIONS, required: false })} */}
          {/* {renderTextField('limitedAnimalLiability', 'Limited Animal Liability', { ...DOLLAR_INPUT_OPTIONS, required: false })} */}
          {renderPercentage('extendedRebuildingCost', 'Extended Replacement Cost')}
          {renderCheckbox('pprc', 'Personal Property Replacement Cost')}
          {renderCheckbox('waterBackup', 'Water backup', { required: false })}
          {renderCheckbox('equipmentBreakdown', 'Equipment Breakdown', { required: false })}
          {renderCheckbox('serviceLine', 'Service Line', { required: false })}
          {renderCheckbox('mortgagePaymentProtection', 'Mortgage Payment Protection', { required: false })}
          {renderCheckbox('domesticWorkersCoverage', 'Domestic Workers', { required: false })}
          {renderCheckbox('lossAssessment', 'Loss Assessment')}
          {renderCheckbox('earthquake', 'Earthquake')}
          {renderCheckbox('sinkholeCollapse', 'Sinkhole Collapse')}
          {renderCheckbox('catastrophicGroundCollapse', 'Catastrophic Ground Collapse')}
          {renderCheckbox('caFairPlanRequired', 'CA Fair Plan Required')}
          {renderCheckbox('roofFireAndLightningOnly', 'Roof Fire & Lightning Only')}
        </FieldGroup>
        <Button sx={{ minWidth: 200, margin: '0 auto', display: 'block' }} type="submit" disabled={isLoading} variant="contained">
          {isLoading ? 'Loading...' : submitLabel || 'Save'}
        </Button>
      </Box>
    </form>
  );
};
