import { Box, Divider, Theme, Typography } from '@mui/material';
import { PolicyDTO } from 'dtos';
import { ReactComponent as CheckIcon } from 'images/icons/check-small.svg';
// import { ReactComponent as Checkbox } from 'images/icons/checkbox.svg';

interface Props {
  policy: PolicyDTO;
}

export const YoureAllSetSection = ({ policy }: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 4,
      }}
    >
      <Box sx={{ flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: { xs: '90vw', sm: '500px' } }}>
        <Typography variant="h1" align="center" sx={{ mb: 2 }}>
          &#127881; You&apos;re all set! &#127881;
        </Typography>
        <Typography align="center" sx={{ fontSize: 14 }}>
          Just make sure to complete these items to ensure there are no issues with getting your policy issued.
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ width: { xs: '90vw', sm: '600px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'start', my: 4 }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 40,
            height: 40,
            borderRadius: 2,
            transition: 'all 0.2s ease-in-out',
            mr: { xs: 2, sm: 4 },
            backgroundColor: t => policy.isSigned ? t.palette.primary.main : t.palette.background.default,
            border: (t: Theme) => `2px solid ${t.palette.primary.main}`
          }}
          >
            {policy.isSigned && <CheckIcon/>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
            <Typography variant="h4" sx={(t) => ({ color: policy.isSigned ? '#000' : t.palette.grey['700'] })}>Sign Terms & Conditions</Typography>
            <Typography sx={(t) => ({ color: policy.isSigned ? '#000' : t.palette.grey['700'] })}>You can expect an email shortly from {policy.carrier.displayName} to sign your terms & conditions.</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'start', my: 4 }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 40,
            height: 40,
            borderRadius: 2,
            transition: 'all 0.2s ease-in-out',
            mr: { xs: 2, sm: 4 },
            backgroundColor: t => policy.isPaid ? t.palette.primary.main : t.palette.background.default,
            border: (t: Theme) => `2px solid ${t.palette.primary.main}`
          }}
          >
            {policy.isPaid && <CheckIcon/>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
            <Typography variant="h4" sx={(t) => ({ color: policy.isPaid ? '#000' : t.palette.grey['700'] })}>Complete Payment</Typography>
            <Typography sx={(t) => ({ color: policy.isPaid ? '#000' : t.palette.grey['700'] })}>
              {
                policy.carrier.billingFlow !== 'HF'
                  ? 'You can expect a call shortly from one of our agents to collect payment information.'
                  : policy.isPaid
                    ? 'You have provided payment information online.'
                    : 'You still need to provide payment information online.'
              }
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
