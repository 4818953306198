import { Box, BoxProps, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  title: string;
  description?: string;
  sx?: BoxProps['sx'];
}

export const Heading = ({ title, description, sx }: Props) => {
  return (
    <Box sx={{ textAlign: 'center', mb: { xs: 2, md: 4 }, ...sx }}>
      <Typography variant='h1' sx={{ fontSize: { xs: 26, md: 32 }}}>
        {title}
      </Typography>

      {description ? (
        <Typography variant='body1' sx={{ mt: 1, fontSize: { xs: 12, md: 16 } }}>
          {description}
        </Typography>
      ) : null}
    </Box>
  );
};
