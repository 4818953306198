import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
// import { useSelectRendered } from 'components/forms/helpers';
import { ConstructionType, LeadDTO, PropertyType } from 'dtos';
import { isBoolean, noop, snakeCase, startCase } from 'lodash';
import { useLeadPrefill } from 'queries/usePrefill';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

interface FormData {
    propertyType: PropertyType;
    constructionType: ConstructionType;
    yearBuilt: number;
    buildingSize: number;
    numberOfStories: number;
    fuses: boolean;
    pool: boolean;
    plumbingReplacedYear: number;
    roofReplacedYear: number
}

interface Props {
    lead: Partial<LeadDTO>;
    onSubmit: (data: FormData) => void;
    updating: boolean;
}

const schema = yup.object().shape({
  propertyType: yup.mixed<PropertyType>().oneOf(Object.values(PropertyType)).required('Required'),
  constructionType: yup.mixed<ConstructionType>().oneOf(Object.values(ConstructionType)).required('Required'),
  yearBuilt: yup.number().nullable().required('Required'),
  buildingSize: yup.number().nullable().required('Required'),
  numberOfStories: yup.number().nullable().required('Required'),
  fuses: yup.boolean().required(),
  pool: yup.boolean().required(),
  plumbingReplacedYear: yup.number().nullable().required('Required'),
  roofReplacedYear: yup.number().nullable().required('Required')
});

const renderTextInput = (name, control, label, errors, options = {} ) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <TextField
        variant="standard"
        onChange={onChange}
        value={value === undefined ? '' : value}
        fullWidth
        label={label}
        helperText={errors[name]?.message as string}
        error={Boolean(errors[name] as any)}
        sx={{ fontWeight: 700 }}
        InputLabelProps={{
          shrink: true
        }}
        {...options}
      />
    )}
  />
);

const renderNumberInput = (name, control, label, errors, options = {}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <TextField
        variant="standard"
        onChange={onChange}
        value={value === undefined ? '' : value}
        fullWidth
        label={label}
        helperText={errors[name]?.message as string}
        error={Boolean(errors[name] as any)}
        InputLabelProps={{
          shrink: true
        }}
        {...options}
      />
    )}
  />
);

const renderSelectInput = (options, name, control, label, errors, toolTip = '') => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControl fullWidth>
        <InputLabel variant="standard" id={snakeCase(label)} shrink error={Boolean(errors[name] as any)}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {label}
            {toolTip?.length ? (
              <Tooltip title={toolTip} placement="right" arrow>
                <HelpOutlineIcon sx={{ height: 20, width: 20, mx: 1, color: 'gray[600]'}} />
              </Tooltip>
            ) : undefined}
          </Box>
        </InputLabel>
        <Select
          variant="standard"
          onChange={(v) => onChange(v.target.value)}
          error={Boolean(errors[name] as any)}
          labelId={snakeCase(label)}
          value={(isBoolean(value) ? value + '' : value) || '' as any}
          label={label}
        >
          {options.map((item, key) => (
            <MenuItem key={key} value={isBoolean(item.value) ? item.value + '' : item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {Boolean(errors[name] as any) && (
          <FormHelperText variant="standard" sx={{ color: (t) => t.palette.error.main }}>{String(errors[name]?.message)}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);

export const PropertyData = ({
  lead = {
    propertyType: undefined,
    constructionType: undefined,
    yearBuilt: undefined,
    buildingSize: undefined,
    numberOfStories: undefined
  },
  onSubmit,
  updating = false,
}: Props) => {
  const {
    propertyType: initialPropertyType,
    constructionType: initialConstructionType,
    yearBuilt: initialYearBuilt,
    buildingSize: initialBuildingSize,
    numberOfStories: initialNumberOfStories
  } = lead;
  const requestedRef = React.useRef<boolean>(false);
  const [loadingPrefill, setLoadingPrefill] = React.useState<boolean>(true);
  const { getValues, control, formState, reset, trigger } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      propertyType: initialPropertyType,
      constructionType: initialConstructionType,
      yearBuilt: initialYearBuilt || 0,
      buildingSize: initialBuildingSize || 0,
      numberOfStories: initialNumberOfStories > 0 ? initialNumberOfStories : 1,
      fuses: false,
      pool: false,
      plumbingReplacedYear: initialYearBuilt || 0,
      roofReplacedYear: initialYearBuilt || 0
    },
    mode: 'all'
  });

  const { mutate: getPrefill } = useLeadPrefill({
    retry: false,
    onSuccess: (data) => {
      const {
        propertyType,
        constructionType,
        numberOfStories,
        yearBuilt,
        buildingSize
      } = data;
      reset({
        ...getValues(),
        propertyType,
        constructionType,
        numberOfStories: numberOfStories > 0 ? numberOfStories : 1,
        yearBuilt,
        buildingSize,
        plumbingReplacedYear: yearBuilt,
        roofReplacedYear: yearBuilt
      });
      triggerValidationAsync(() => setLoadingPrefill(false));
    }
  });

  const triggerValidationAsync = React.useCallback(async (cb: () => void = noop) => {
    const keys = Object.keys(getValues());
    let shouldFocus = true;
    for (let i = 0; i < keys.length; i++) {
      const fieldKey = keys[i];
      const isValid = await trigger(fieldKey as keyof FormData, { shouldFocus });
      shouldFocus = isValid;
    }
    cb();
  }, [trigger, getValues]);

  React.useEffect(
    function initialRender() {
      if (!requestedRef.current) {
        setLoadingPrefill(true);
        requestedRef.current = true;
        getPrefill({
          addressLine1: lead.propertyAddressStreet,
          addressLine2: lead.propertyAddressStreet2,
          city: lead.propertyAddressCity,
          state: lead.propertyAddressState,
          zipCode: lead.propertyAddressZipCode
        });
      }
    },
    [lead, setLoadingPrefill, getPrefill]
  );

  const handleNext = React.useCallback(() => onSubmit(getValues()), [getValues, onSubmit]);

  if (loadingPrefill) {
    return (
      <Box sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 16,
        gap: 4,
        transition: 'all 0.5s ease-in-out'
      }}>
        <CircularProgress size={60} />
        <Typography variant="body2">Loading property data...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ transition: 'all 0.3s ease-in-out' }}>
      <SectionWrapper
        title="Let's make sure we got everything right..."
        primaryButtonLabel="Looks Good"
        onPrimaryButtonClick={handleNext}
        primaryButtonLoading={updating}
        disableButtons={!formState.isValid}
      >
        <Box
          display="grid"
          sx={{
            width: 700,
            backgroundColor: 'rgba(217, 217, 217, 0.3)',
            borderRadius: 2,
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
            gap: { xs: 4, md: 6 },
            p: { xs: 3, md: 6 }
          }}
        >
          <Box>
            {renderSelectInput(Object.values(PropertyType).map(v => ({
              value: v,
              label: startCase(v)
            })), 'propertyType', control, 'PROPERTY TYPE', formState.errors)}
          </Box>
          <Box>
            {renderNumberInput('buildingSize', control, 'SQUARE FEET', formState.errors)}
          </Box>
          <Box>
            {renderTextInput('numberOfStories', control, 'STORIES', formState.errors)}
          </Box>
          <Box>
            {renderNumberInput('yearBuilt', control, 'YEAR BUILT', formState.errors)}
          </Box>
          <Box>
            {renderNumberInput('roofReplacedYear', control, 'ROOF BUILT / REPLACED', formState.errors)}
          </Box>
          <Box>
            {renderNumberInput('plumbingReplacedYear', control, 'PLUMBING INSTALLED / REPLACED', formState.errors)}
          </Box>
          <Box>
            {renderSelectInput(Object.values(ConstructionType).map(v => ({
              value: v,
              label: startCase(v)
            })), 'constructionType', control, 'CONSTRUCTION TYPE', formState.errors)}
          </Box>
          <Box>
            {renderSelectInput([{ label: 'Yes', value: true }, { label: 'No', value: false }], 'pool', control, 'POOL, HOT TUB, OR SPA', formState.errors)}
          </Box>
          <Box>
            {renderSelectInput([{ label: 'Yes', value: true }, { label: 'No', value: false }], 'fuses', control, 'FUSES', formState.errors, 'Fuses are seen as an increased fire risk by insurance carriers, due to homeowners replacing them with larger fuses over time.')}
          </Box>
        </Box>
      </SectionWrapper>
    </Box>
  );
};