import { Button, Typography } from '@mui/material';
import { PersonalInformationForm } from 'components/forms/personal-information';
import { PropertyDetailsForm } from 'components/forms/property-details';
import { Modal } from 'components/Modal';
import { CreateLeadDTO, LeadStatus, UpdateLeadDTO } from 'dtos';
import { useCreateLead } from 'queries/useLead';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'rentr-constants';

enum Step {
  PROPERTY = 'property',
  PERSONAL = 'personal',
}

export const AddLeadButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [step, setStep] = useState<Step>(Step.PROPERTY);
  const [data, setData] = useState<Partial<CreateLeadDTO>>({});

  const { mutate, isLoading } = useCreateLead({
    onSuccess: (data) => {
      navigate(insertIdToRoute(ROUTES.dashboard.lead.ROOT, data.id));
    }
  });

  const handleSubmit = useCallback((d: UpdateLeadDTO) => {
    mutate({ ...data, ...d, status: LeadStatus.SUBMITTED } as CreateLeadDTO);
  }, [data, mutate]);

  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained">Create new lead</Button>
      <Modal open={open} onClose={() => setOpen(false)} boxSx={{ width: 700 }}>
        <div>
          <Typography variant="h2" sx={{ mb: 2, }}>
            Create a new lead
          </Typography>

          {step === Step.PROPERTY && (
            <PropertyDetailsForm
              submit='Next'
              onSubmit={(d) => {
                setData({ ...data, ...d });
                setStep(Step.PERSONAL);
              }}
            />
          )}

          {step === Step.PERSONAL && (
            <PersonalInformationForm
              isLoading={isLoading}
              onSubmit={handleSubmit}
              submit="Create"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
