import { PrefillData, PrefillPayloadDTO } from 'dtos';
import { AddressDto } from 'dtos/address';
import qs from 'query-string';

import RequestService from './Request';

class _PrefillService {
  getLeadPrefill(data: PrefillPayloadDTO): Promise<PrefillData> {
    const query = qs.stringify(data);

    return RequestService.client.get<PrefillData>(`/prefill/lead-data?${query}`)
      .then((response) => response.data);
  }

  verifyAddress(data: AddressDto): Promise<any> {
    const query = qs.stringify(data);

    return RequestService.client.get<any>(`/prefill/verify-address?${query}`)
      .then((response) => response.data);
  }
}

const PrefillService = new _PrefillService();

export { _PrefillService, PrefillService };
