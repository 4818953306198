import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, RadioGroup, TextField } from '@mui/material';
import { isUndefined } from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  previousClaims?: boolean;
  previousClaimsExplanation?: string;
  onSubmit: (previousClaims: boolean, previousClaimsExplanation?: string) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<boolean>, 'onSelect'>> = [
  {
    label: 'YES',
    value: true,
  },
  {
    label: 'NO',
    value: false
  }
];

interface FormData {
  previousClaims: boolean;
  previousClaimsExplanation?: string;
}

const schema = yup.object().shape({
  previousClaims: yup.string().required(),
  previousClaimsExplanation: yup.string().nullable().when('previousClaims', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  })
});

export function PreviousClaims({ previousClaims: previousClaimsProp, previousClaimsExplanation, onSubmit, updating = false }: Props) {
  const [previousClaims, setPreviousClaims] = React.useState<boolean>(previousClaimsProp);
  const [showExplanationInput, setShowExplanationInput] = React.useState<boolean>(previousClaimsProp);

  const { control, getValues, formState, setValue, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      previousClaims: previousClaimsProp,
      previousClaimsExplanation
    },
    mode: 'all'
  });

  const watchedExplanation = watch('previousClaimsExplanation');

  React.useEffect(function toggleExplanation() {
    if (!isUndefined(previousClaims)) {
      if (previousClaims) {
        setShowExplanationInput(true);
      } else {
        setShowExplanationInput(false);
        setValue('previousClaimsExplanation', null);
      }
    }
  }, [previousClaims, setValue, setShowExplanationInput, clearErrors]);

  const handleNext = React.useCallback(() => {
    onSubmit(getValues('previousClaims'), getValues('previousClaimsExplanation'));
  }, [onSubmit, getValues]);

  return (
    <SectionWrapper
      title="Did you, or anyone you lived with, file a home insurance claim in the last 5 years?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!formState.isValid || (showExplanationInput && !(watchedExplanation?.length))}
      containerSx={{ maxWidth: { md: 800 } }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 4 }, width: { xs: '240px', md: '300px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              onSelect={(v) => {
                setValue('previousClaims', v, { shouldValidate: true });
                setPreviousClaims(v);
              }}
              selected={previousClaims === option.value}
              key={idx}
              {...option}
            />
          ))}
        </RadioGroup>
        {showExplanationInput && (
          <Controller
            name={'previousClaimsExplanation'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="filled"
                onChange={onChange}
                value={value === undefined ? '' : value}
                inputRef={(input) => {
                  if (input != null) {
                    input.focus();
                  }
                }}
                fullWidth
                multiline
                label={'Please tell us more'}
                helperText={formState.errors['previousClaimsExplanation']?.message as string}
                error={Boolean(formState.errors['previousClaimsExplanation'] as any)}
                rows={5}
                placeholder='i.e. There was a bad storm, which caused damage to our roof.  We filed a claim roughly 14 months ago for $15,000'
                sx={{ maxWidth: { xs: '100%', md: '300px' }}}
              />
            )}
          />
        )}
      </Box>
    </SectionWrapper>
  );
}
