import {
  Box, Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { QuoteMetricPosition } from 'components/QuoteBox';
import { LeadDTO, PolicyDTO, PolicyStatus } from 'dtos';
import { isNumber, startCase } from 'lodash';
import { useCarriers } from 'queries';
import { useActivatePolicy, useLeadPolicies } from 'queries/usePolicy';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { IsPaidCheckbox } from './components/IsPaidCheckbox';
import { IsSignedCheckbox } from './components/IsSignedCheckbox';

export interface Props {
  lead: LeadDTO;
}

export const PolicySection = ({ lead }: Props) => {
  const [policy, setPolicy] = useState<PolicyDTO | null>(null);
  const [policyNumber, setPolicyNumber] = useState('');
  const { mutate } = useActivatePolicy();
  const { data } = useCarriers();
  const carrier = data?.find(c => c.id === policy?.carrierId);

  const { isLoading } = useLeadPolicies(
    lead.id,
    {
      onSuccess: (data) => {
        const activePolicies = data.filter(p => p.status === PolicyStatus.ACTIVE);
        const draftPolicies = data.filter(p => p.status === PolicyStatus.DRAFT);

        if (activePolicies.length > 0) {
          setPolicy(activePolicies[0]);
        } else if (draftPolicies.length > 0) {
          setPolicy(draftPolicies[0]);
        } else {
          setPolicy(null);
        }
      }
    }
  );

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPolicyNumber(event.target.value);
  }, []);

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h4">Policy</Typography>

      {!isLoading ? (
        <Box>
          {policy ? (
            <Box>
              <Box sx={{ my: 2 }}>
                <IsPaidCheckbox policy={policy}/>
                <IsSignedCheckbox policy={policy}/>
              </Box>

              {policy && !policy.policyNumber ? (
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    onChange={handleChange}
                    value={policyNumber}
                    fullWidth
                    label="Policy number"
                    required
                  />
                  <Button
                    sx={{ minWidth: 200 }}
                    variant="contained"
                    onClick={() => mutate({ id: policy.id, data: { policyNumber } })}
                  >
                    Confirm policy
                  </Button>
                </Box>
              ) : null}

              <Box
                sx={(_t) => ({
                  display: 'grid',
                  gridTemplateColumns: {
                    sm: '1fr',
                    md: '1fr 1fr'
                  },
                  gap: 2,
                  pt: 2,
                  m: -2
                })}
              >
                <QuoteMetricPosition value={`$${policy.premium.toLocaleString()}`} label="Premium" skipBorder
                  showLabel/>
                <QuoteMetricPosition value={policy.policyNumber || '-'} label="Policy Number" skipBorder showLabel/>
                <QuoteMetricPosition value={startCase(policy.status)} label="Status" skipBorder showLabel/>
                {carrier && <QuoteMetricPosition value={carrier.displayName} label="Carrier Name" skipBorder showLabel/>}
                <QuoteMetricPosition value={policy.effectiveDate} label="Effective Date" skipBorder showLabel/>
                <QuoteMetricPosition value={policy.expirationDate} label="Expiration Date" skipBorder showLabel/>
                {Object.keys(policy.coverages).map(coverage => (
                  <QuoteMetricPosition
                    key={coverage}
                    value={`$${policy?.coverages[coverage] || 0}`}
                    label={startCase(coverage)}
                    skipBorder
                    showLabel
                  />
                ))}

                {Object.keys(policy.deductibles).map(ded => (
                  <QuoteMetricPosition
                    key={ded}
                    value={isNumber(policy?.deductibles[ded]) ? `$${policy?.deductibles[ded]}` : startCase(policy?.deductibles[ded] as string || '-')}
                    label={startCase(ded)}
                    skipBorder
                    showLabel
                  />
                ))}
              </Box>
            </Box>
          ) : <Box>No policies so far</Box>}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress/>
        </Box>
      )}
    </Box>
  );
};
