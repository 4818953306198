import { PrefillData, PropertyDetails } from 'dtos/prefill';
import { QuoteDeductibleType } from 'dtos/quote';

export enum LeadSortBy {
  CLIENT_NAME = 'client_name',
  EMAIL_ADDRESS = 'email_address',
  STATUS = 'status',
  STATE = 'state',
}

export enum LeadSortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface LeadQueryDTO {
  query?: string;
  status?: LeadStatus[];
  state?: string;
  sortBy?: LeadSortBy;
  sortOrder?: LeadSortOrder;
  offset?: number;
  limit?: number;
}

export interface LeadsResponseDTO {
  leads: LeadDTO[];
  count: number;
}

export interface LeadDTO {
  id: string;
  levelOfCoverage: LevelOfCoverage;
  insuranceHolder: InsuranceHolder;
  companyName?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  rentalIncomePerMonth: number;
  birthDate: string;
  pastInsuranceClaims: number;
  propertyAddressStreet: string;
  propertyAddressStreet2: string;
  propertyAddressCity: string;
  propertyAddressState: string;
  propertyAddressZipCode: string;
  differentMailingAddress: boolean;
  mailingAddressStreet?: string;
  mailingAddressStreet2?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  rentalTime: RentalTime;
  propertyType: PropertyType;
  constructionType: ConstructionType;
  yearBuilt: number;
  buildingSize: number;
  numberOfStories: number;
  occupancy: Occupancy;
  status: LeadStatus;
  createdAt: Date;
  source: string | null;
  sourceMore: string | null;
  updatedAt: Date;
  insuranceType: InsuranceType;
  fixturesAndFinishes: FixturesAndFinishes;
  underRenovation: boolean;
  earthquakeCoverage: boolean;
  floodCoverage: boolean;
  effectiveDate: string;
  questionUnrepairedDamageKnownDefects: boolean;
  questionFuses: boolean;
  questionBusinessFootTraffic: boolean;
  questionPoolHotTubSpa: boolean;
  questionPlumbingReplaced: boolean;
  plumbingReplacementYear: number | null;
  questionRoofReplaced: boolean;
  roofReplacementYear: number | null;
  questionInsuranceClaims: boolean;
  insuranceClaimsOccurrence: string | null;
  insuranceClaimValue: number | null;
  additionalInsured: boolean;
  additionalInsuredFirstName: string | null;
  additionalInsuredLastName: string | null;
  additionalInsuredDateOfBirth: string | null;
  deductibleType: QuoteDeductibleType;
  deductible: number;
  hasAutoInsurancePolicy?: boolean | null;
  hasFireDetectionSystem?: boolean | null;
  fireDetectionSystemAlertsEmergencyServices?: boolean | null;
  hasBurglarAlarmSystem?: boolean | null;
  hasFireExtinguisher?: boolean | null;
  hasSmartWaterValve?: boolean | null;
  hasUnrepairedDamage?: boolean | null;
  hasKnownDefects?: boolean | null;
  hasOpenOrPendingClaims?: boolean | null;
  isPartOfLawsuit?: boolean | null;
  isUnderForeclosure?: boolean | null;
  reason?: string | null;
}

export enum InsuranceType {
  LANDLORD = 'landlord',
  HOMEOWNER = 'homeowner',
  RENTER = 'renter',
}

export enum LevelOfCoverage {
  BASIC = 'basic',
  STANDARD = 'standard',
  PREMIUM = 'premium',
}

export enum FixturesAndFinishes {
  SIMPLE = 'simple',
  EXTRA = 'extra',
  TOP = 'top',
}

export enum InsuranceHolder {
  PERSON = 'person',
  COMPANY = 'company',
}

export enum RentalTime {
  SIX_MONTHS_PLUS = 'six_months_plus',
  SHORT_TERM = 'short_term',
}

export enum PropertyType {
  SINGLE_FAMILY = 'single_family',
  DUPLEX = 'duplex',
  TRIPLEX = 'triplex',
  QUADPLEX = 'quadplex',
  APARTMENT = 'apartment',
  CONDO_UNIT = 'condo_unit',
  TOWNHOME = 'townhome',
  MOBILE_HOME = 'mobile_home',
  LAND = 'land',
  MULTIFAMILY = 'multifamily',
  OTHER = 'other',
}

export enum ConstructionType {
  FRAME = 'frame',
  BRICK_VENEER = 'brick_veneer',
  MASONRY = 'masonry',
  CONCRETE = 'concrete',
  STEEL = 'steel',
  OTHER = 'other',
}

export type Occupancy = OccupancyLandlord | OccupancyHomeowner;

export enum OccupancyLandlord {
  FULLY_OCCUPIED = 'fully_occupied',
  SOME_VACANCY = 'some_vacancy',
  VACANT = 'vacant',
}

export enum OccupancyHomeowner {
  FULL_TIME = 'full_time',
  VACATION = 'vacation',
}

export enum LeadStatus {
  PROSPECT = 'prospect',
  SUBMITTED = 'submitted',
  NO_QUOTES_FOUND = 'no_quotes_found',
  QUOTES = 'quotes',
  QUOTE_SELECTED = 'quote_selected',
  PAID = 'paid',
  SIGNED = 'signed',
  BOUND = 'bound',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export interface CreateLeadDTO {
  levelOfCoverage: LevelOfCoverage;
  insuranceHolder: InsuranceHolder;
  companyName?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  rentalIncomePerMonth: number;
  birthDate?: string; // mm/dd/yyyy
  pastInsuranceClaims: number;
  propertyAddressStreet: string;
  propertyAddressStreet2?: string;
  propertyAddressCity: string;
  rawPrefillResponse?: PropertyDetails;
  prefillData?: PrefillData;
  propertyAddressState: string;
  propertyAddressZipCode: string;
  differentMailingAddress: boolean;
  mailingAddressStreet?: string;
  mailingAddressStreet2?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  rentalTime: RentalTime;
  propertyType: PropertyType;
  constructionType: ConstructionType;
  yearBuilt: number;
  buildingSize: number;
  numberOfStories: number;
  occupancy: Occupancy;
  status?: LeadStatus;
  insuranceType: InsuranceType;
  fixturesAndFinishes: FixturesAndFinishes;
  underRenovation: boolean;
  earthquakeCoverage: boolean;
  floodCoverage: boolean;
  effectiveDate: string;
  questionUnrepairedDamageKnownDefects?: boolean;
  questionFuses?: boolean;
  questionBusinessFootTraffic?: boolean;
  questionPoolHotTubSpa?: boolean;
  questionPlumbingReplaced?: boolean;
  plumbingReplacementYear?: number | null;
  questionRoofReplaced?: boolean;
  roofReplacementYear?: number | null;
  questionInsuranceClaims?: boolean;
  insuranceClaimsOccurrence?: string | null;
  insuranceClaimValue?: number | null;
  additionalInsured: boolean;
  additionalInsuredFirstName?: string | null;
  additionalInsuredLastName?: string | null;
  additionalInsuredDateOfBirth?: string | null;
  deductibleType?: QuoteDeductibleType;
  deductible: number;
  hasUnrepairedDamage?: boolean | null;
  hasKnownDefects?: boolean | null;
  hasOpenOrPendingClaims?: boolean | null;
  isPartOfLawsuit?: boolean | null;
  isUnderForeclosure?: boolean | null;
  reason?: string | null;
}

export interface UpdateSourcesLeadDTO {
  source: string;
  sourceMore?: string;
}

export interface UpdateLeadDTO {
  levelOfCoverage?: LevelOfCoverage;
  insuranceHolder?: InsuranceHolder;
  companyName?: string | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  rentalIncomePerMonth?: number;
  birthDate?: string; // mm/dd/yyyy
  pastInsuranceClaims?: number;
  propertyAddressStreet?: string;
  propertyAddressStreet2?: string;
  propertyAddressCity?: string;
  propertyAddressState?: string;
  propertyAddressZipCode?: string;
  differentMailingAddress?: boolean;
  mailingAddressStreet?: string;
  mailingAddressStreet2?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  rentalTime?: RentalTime;
  propertyType?: PropertyType;
  constructionType?: ConstructionType;
  yearBuilt?: number;
  buildingSize?: number;
  numberOfStories?: number;
  occupancy?: Occupancy;
  status?: LeadStatus;
  insuranceType?: InsuranceType;
  fixturesAndFinishes?: FixturesAndFinishes;
  underRenovation?: boolean;
  earthquakeCoverage?: boolean;
  floodCoverage?: boolean;
  effectiveDate?: string;
  questionUnrepairedDamageKnownDefects?: boolean;
  questionFuses?: boolean;
  questionBusinessFootTraffic?: boolean;
  questionPoolHotTubSpa?: boolean;
  questionPlumbingReplaced?: boolean;
  plumbingReplacementYear?: number | null;
  questionRoofReplaced?: boolean;
  roofReplacementYear?: number | null;
  questionInsuranceClaims?: boolean;
  insuranceClaimsOccurrence?: string | null;
  insuranceClaimValue?: number | null;
  additionalInsured?: boolean;
  additionalInsuredFirstName?: string | null;
  additionalInsuredLastName?: string | null;
  additionalInsuredDateOfBirth?: string | null;
  deductibleType?: QuoteDeductibleType;
  deductible?: number;
  hasAutoInsurancePolicy?: boolean | null;
  hasFireDetectionSystem?: boolean | null;
  fireDetectionSystemAlertsEmergencyServices?: boolean | null;
  hasBurglarAlarmSystem?: boolean | null;
  hasFireExtinguisher?: boolean | null;
  hasSmartWaterValve?: boolean | null;
  hasUnrepairedDamage?: boolean | null;
  hasKnownDefects?: boolean | null;
  hasOpenOrPendingClaims?: boolean | null;
  isPartOfLawsuit?: boolean | null;
  isUnderForeclosure?: boolean | null;
  reason?: string | null;
}

export interface LeadHistoryDTO {
  id: string;
  description: string;
  status: LeadStatus;
  leadId: string;
  createdAt: Date;
}
