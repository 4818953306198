import { Box } from '@mui/material';
import { LandingFooter } from 'components/LandingFooter';
import { LandingNavbar } from 'components/LandingNavbar';
import * as React from 'react';
import { StatesSection } from 'views/licenses/sections/States';

import { HeaderSection } from './sections/Header';

export const LicensesView = () => {
  return (
    <Box>
      <LandingNavbar/>
      <HeaderSection/>
      <StatesSection/>
      <LandingFooter/>
    </Box>
  );
};
