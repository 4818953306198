import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_URL } from 'rentr-constants';
import { AuthService } from 'services/Auth';
import { TokensService } from 'services/Tokens';

export class RequestService {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
    });

    this.setAuthenticationInterceptor();
    this.setReauthenticateInterceptor();
  }

  setAuthenticationInterceptor() {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = TokensService.loadAuthTokenFromLocalStorage();
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      return config;
    });
  }

  setReauthenticateInterceptor() {
    this.client.interceptors.response.use(undefined, async (error) => {
      if (error.response && error.response.status === 401) {
        return AuthService.renewToken()
          .then(() => this.client(error.config))
          .catch(() => {
            return AuthService.logout();
          });
      }

      throw error;
    });
  }
}

export default new RequestService();
