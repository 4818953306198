import { Box, ButtonProps } from '@mui/material';
import { ReactComponent as Carret } from 'images/icons/carret.svg';
import { TYPOGRAPHY } from 'rentr-theme';

interface Props {
  onClick: () => void;
  label?: string;
  sx?: ButtonProps['sx']
}

export const PreviousStepButton = ({ onClick, sx, label }:Props) => {
  return (
    <Box
      component='button'
      onClick={onClick}
      sx={{
        ...sx,
        py: 1,
        paddingLeft: 0,
        border: 'none',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        textTransform: 'none',
        textDecoration: 'underline',
        ...TYPOGRAPHY.paragrafLarge,
        backgroundColor: 'transparent',
        color: t => t.palette.grey['800'],
        transition: 'all 0.2s ease-in-out',
        '&:hover': { color: t => t.palette.grey['900'] }
      }}
    >
      <Box component={Carret} sx={{ mr: 1 }}/>
      {label || 'Previous step'}
    </Box>
  );
};
