import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import { PreviousStepButton } from 'components/PreviousStepButton';
import { QuoteMetricPosition } from 'components/QuoteBox';
import {
  QUOTE_RENDERERS,
  QuoteDTO,
  QuoteFieldRenderer,
  QuoteRendererKeys,
} from 'dtos';
import { useCallback } from 'react';
import {
  CA_FAIR_PLAN_ACKNOWLEDGEMENT,
  CA_FAIR_PLAN_WARNING,
  ROOF_FIRE_LIGHTNING_ONLY_ACKNOWLEDGEMENT,
  ROOF_FIRE_LIGHTNING_ONLY_WARNING,
} from 'rentr-constants';

interface Props {
  quote: QuoteDTO;
  onConfirm: () => void;
  onPreviousStepClick: () => void;
}

const SKIP_RENDERERS: QuoteRendererKeys[] = [
  'premium',
  'type',
  'status',
  'carrier',
];
export const SelectedQuoteSection = ({
  quote,
  onConfirm,
  onPreviousStepClick,
}: Props) => {
  const { logoUrl } = quote.carrier;

  const handleConfirm = useCallback(() => {
    if (quote.caFairPlanRequired) {
      if (!confirm(CA_FAIR_PLAN_ACKNOWLEDGEMENT)) {
        return;
      }
    }
    if (quote.roofFireAndLightningOnly) {
      if (!confirm(ROOF_FIRE_LIGHTNING_ONLY_ACKNOWLEDGEMENT)) {
        return;
      }
    }
    onConfirm();
  }, [onConfirm, quote.caFairPlanRequired, quote.roofFireAndLightningOnly]);

  const renderPosition = (
    key: string,
    value: string,
    label: string,
    tooltip?: string | null,
    sx?: BoxProps['sx']
  ) => {
    return (
      <QuoteMetricPosition
        key={key}
        value={value}
        sx={sx}
        label={label}
        skipBorder
        showLabel={true}
        tooltip={tooltip}
      />
    );
  };

  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        maxWidth: '100%',
        borderRadius: 2,
      }}
    >
      <PreviousStepButton
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          mb: 4
        }}
        onClick={onPreviousStepClick}
        label="Review Quotes"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: 6,
          py: 2,
          backgroundColor: '#fff',
          border: (t) => `1px solid ${t.palette.grey['700']}`,
        }}
      >
        <Box
          sx={(_t) => ({
            display: 'grid',
            flexDirection: 'column',
            gap: 2,
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
            },
          })}
        >
          {logoUrl ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: { xs: '60px', md: '80px' },
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{ width: '50%', objectFit: 'contain' }}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'start' },
              ml: { xs: 0, md: 8, lg: 16 },
            }}
          >
            <Typography
              variant="h1"
              sx={(t) => ({ color: t.palette.primary.main, xs: { textAlign: 'center' } })}
            >
              ${quote.premium.toFixed(2)}/year
            </Typography>
            {quote.caFairPlanRequired && (
              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 14, color: (t) => t.palette.common.white }}
                  >
                    {CA_FAIR_PLAN_WARNING}
                  </Typography>
                }
                placement="right"
                arrow
              >
                <Typography sx={{ color: (t) => t.palette.primary.main }}>
                  <InfoOutlinedIcon
                    sx={{
                      color: (t) => t.palette.primary.main,
                      fontSize: 16,
                      ml: 0.5,
                    }}
                  />
                </Typography>
              </Tooltip>
            )}
            {quote.roofFireAndLightningOnly && (
              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 14, color: (t) => t.palette.common.white }}
                  >
                    {ROOF_FIRE_LIGHTNING_ONLY_WARNING}
                  </Typography>
                }
                placement="right"
                arrow
              >
                <Typography sx={{ color: (t) => t.palette.primary.main }}>
                  <InfoOutlinedIcon
                    sx={{
                      color: (t) => t.palette.primary.main,
                      fontSize: 16,
                      ml: 0.5,
                    }}
                  />
                </Typography>
              </Tooltip>
            )}
          </Box>
          <Divider sx={{ gridColumnStart: { md: 'span 2' }, my: 1 }} />
          {Object.keys(QUOTE_RENDERERS)
            .filter((k) => !(SKIP_RENDERERS as string[]).includes(k))
            .filter((k) => {
              const renderer: QuoteFieldRenderer | undefined = (
                QUOTE_RENDERERS as { [k: string]: QuoteFieldRenderer }
              )[k];
              return renderer ? true : false;
            })
            .map((key) => {
              const renderer: QuoteFieldRenderer | undefined = (
                QUOTE_RENDERERS as { [k: string]: QuoteFieldRenderer }
              )[key];
              const value = renderer.parsedValue(quote);
              const tooltip = renderer.tooltip;

              // HACK
              if (!quote.caFairPlanRequired && key === 'caFairPlanRequired') {
                return null;
              }
              // HACK
              if (
                !quote.roofFireAndLightningOnly &&
                key === 'roofFireAndLightningOnly'
              ) {
                return null;
              }

              if (value) {
                return renderPosition(key, value, renderer.label, tooltip, {
                  ml: { xs: 0, md: 8, lg: 14 },
                });
              }
            })}
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{ display: 'block', margin: '0 auto', width: 200 }}
        onClick={() => handleConfirm()}
      >
        Confirm selection
      </Button>
    </Box>
  );
};
