import { Box } from '@mui/material';
import { LevelOfCoverage as LevelOfCoverageDto } from 'dtos';
import { ReactComponent as Crown } from 'images/icons/crown.svg';
import { ReactComponent as Star } from 'images/icons/star.svg';
import { ReactComponent as Tick } from 'images/icons/tick.svg';
import * as React from 'react';
import { BlockOption, Props as BlockProps } from 'views/onboarding/components/BlockOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

const OPTIONS: Array<Omit<BlockProps<LevelOfCoverageDto>, 'onClick'>> = [
  {
    image: <Box component={Tick} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'Basic',
    description: 'Minimum replacement coverage. Additional coverages (equipment breakdown, service line, etc.) not typically included.',
    value: LevelOfCoverageDto.BASIC,
  },
  {
    image: <Box component={Star} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'Standard',
    description: 'Standard coverage for home. Equipment breakdown and service line coverage typically included.',
    value: LevelOfCoverageDto.STANDARD,
  },
  {
    image: <Box component={Crown} sx={{ height: { xs: 75, md: 175 } }}/>,
    label: 'Premium',
    description: 'High coverage for home. Equipment breakdown, service line, mortgage payment protection, and domestic workers typically covered.',
    value: LevelOfCoverageDto.PREMIUM,
  },
];

interface Props {
  value?: LevelOfCoverageDto;
  onSubmit: (val: LevelOfCoverageDto) => void;
  updating?: boolean;
}

export const LevelOfCoverage = ({ value: initialValue, onSubmit, updating = false}: Props) => {
  const [selection, setSelection] = React.useState<LevelOfCoverageDto>(initialValue);

  const handleNext = React.useCallback(() => {
    onSubmit(selection);
  }, [selection, onSubmit]);

  return (
    <SectionWrapper
      title="What level of coverage are you looking for?"
      description="This helps us understand the general level of protection you need"
      primaryButtonLabel="Next"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleNext}
      disableButtons={!selection}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: 1, md: 4 },
          justifyContent: 'center',
        }}
      >
        {OPTIONS.map((option, optionIdx) => (
          <BlockOption
            sx={{
              flex: 1,
              maxWidth: { xs: '40%' },
            }}
            selected={selection === option.value}
            onClick={setSelection}
            key={optionIdx}
            {...option}
          />
        ))}
      </Box>
    </SectionWrapper>
  );
};
