import { createTheme } from '@mui/material';

const BLUE = '#56CCF2';
const BG = '#FCFCFB';
const LIGHT = '#b5e9f9';
const BLUE_DARK = '#00b4f1';

export const PRIMARY_1 = BLUE_DARK;
export const PRIMARY_2 = BLUE;
export const PRIMARY_3 = LIGHT;
export const SECONDARY_1 = '#1F88E5';
export const SECONDARY_2 = '#86C6FF';
export const SECONDARY_3 = '#E3F2FD';

export const GREEN_1 = '#4EAE8B';
export const GREEN_2 = '#C2EDDB';
export const GREEN_3 = '#E9F9F2';

export const RED_1 = '#EA596B';
export const RED_2 = '#F7BDC4';
export const RED_3 = '#FCE5E7';

export const GRAY_1 = '#232830';
export const GRAY_2 = '#686D76';
export const GRAY_3 = '#ACAEB1';
export const GRAY_4 = '#D4D7D7';
export const GRAY_5 = '#EDEDED';
export const GRAY_6 = '#F2F2F2';
export const GRAY_7 = '#F7F7F7';

export const TYPOGRAPHY = {
  heading0: {
    color: GRAY_1,
    fontWeight: 'bold',
    fontSize: 40,
  },
  heading1: {
    color: GRAY_1,
    fontWeight: 'bold',
    fontSize: 32,
  },
  heading2: {
    color: GRAY_1,
    fontWeight: 'bold',
    fontSize: 28,
  },
  heading3: {
    color: GRAY_1,
    fontWeight: 'bold',
    fontSize: 22,
  },
  heading4: {
    color: GRAY_1,
    fontWeight: 'bold',
    fontSize: 18,
  },
  subheading1: {
    color: GRAY_1,
    fontSize: 32,
  },
  subheading2: {
    color: GRAY_1,
    fontSize: 26,
  },
  subheading3: {
    color: GRAY_1,
    fontSize: 22,
  },
  paragrafExtraLarge: {
    color: GRAY_1,
    fontSize: 24,
  },
  paragrafLarge: {
    color: GRAY_1,
    fontSize: 20,
  },
  paragrafRegular: {
    color: GRAY_1,
    fontSize: 16,
  },
  paragrafSmall: {
    color: GRAY_1,
    fontSize: 14,
  }
};

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 12,
          paddingRight: 12,
        },
        contained: {
          color: 'white',
          '&:disabled': {
            color: '#fff',
            backgroundColor: GRAY_3
          }
        },
        text: {
          '&:hover': {
            color: 'primary.dark',
          },
          '&:disabled': {
            color: GRAY_3
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...TYPOGRAPHY.paragrafRegular
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          backgroundColor: GRAY_2,
          color: '#fff'
        },
        arrow: {
          color: GRAY_2,
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          ...TYPOGRAPHY.paragrafRegular
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: PRIMARY_2,
          borderRadius: '4px !important',
          '&:hover': {
            borderColor: '#fff',
          }
        },
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      }
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingLeft: 12,
          paddingRight: 12,
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          height: 2,
          borderRadius: 6,
          backgroundColor: BG,
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          ...TYPOGRAPHY.paragrafRegular,
          color: GRAY_3,
          fontWeight: 'normal',

          '&.Mui-active': {
            ...TYPOGRAPHY.paragrafRegular,
            fontWeight: 'normal',
            color: GRAY_2,
          },

          '&.Mui-completed': {
            ...TYPOGRAPHY.paragrafRegular,
            fontWeight: 'normal',
            color: GRAY_2,
          }
        },
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: GRAY_5,

          '.MuiStepIcon-text': {
            fill: GRAY_3
          },

          '&.Mui-active': {
            fill: GRAY_1,

            '.MuiStepIcon-text': {
              fill: '#fff',
            }
          },

          '&.Mui-completed': {
            fill: PRIMARY_2,

            '.MuiStepIcon-text': {
              fill: '#000',
            }
          }
        },
      }
    }
  },
  typography: {
    body1: {
      ...TYPOGRAPHY.paragrafRegular,
    },
    body2: {
      ...TYPOGRAPHY.paragrafLarge,
    },
    h1: {
      ...TYPOGRAPHY.heading1,
    },
    h2: {
      ...TYPOGRAPHY.heading2,
    },
    h3: {
      ...TYPOGRAPHY.heading3,
    },
    h4: {
      ...TYPOGRAPHY.heading4,
    },
  },
  palette: {
    background: {
      default: BG
    },
    primary: {
      main: PRIMARY_2,
      dark: PRIMARY_1,
      light: PRIMARY_3,
    },
    secondary: {
      main: GRAY_3,
      dark: GRAY_1,
      light: GRAY_5,
    },
    error: {
      main: RED_1,
      dark: RED_2,
      light: RED_3,
    },
    success: {
      main: GREEN_1,
      dark: GREEN_2,
      light: GREEN_3,
    },
    grey: {
      300: GRAY_7,
      400: GRAY_6,
      500: GRAY_5,
      600: GRAY_4,
      700: GRAY_3,
      800: GRAY_2,
      900: GRAY_1,
    }
  }
});
