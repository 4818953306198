import { Typography } from '@mui/material';
import { StatusContainer } from 'components/StatusContainer';
import dayjs from 'dayjs';
import { PolicyDTO, PolicyStatus } from 'dtos';
import { ReactComponent as PaymentSuccess } from 'images/icons/payment-success.svg';

interface Props {
  policy: PolicyDTO;
}

export const SuccessPaymentSection = ({ policy }: Props) => {
  if(policy.status === PolicyStatus.ACTIVE) {
    return (
      <StatusContainer
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mt: 10}}
        description="This policy is active. You can now start using it."
        image={<PaymentSuccess />}
        title="We've got you covered!"
      />
    );
  }

  return (
    <StatusContainer
      sx={{
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 8,
      }}
      descriptionSx={{ maxWidth: 300 }}
      description="Your agent will send you an email as soon as the policy is ready to download"
      title="Thank you for your payment!"
      extra={(
        <Typography variant="h3" sx={(t) => ({ color: t.palette.primary.main, mt: 1, mb: 2, fontWeight: 'normal' })}>
          Your policy will take effect on {dayjs(policy.effectiveDate).format('MMMM DD, YYYY')}
        </Typography>
      )}
      image={<PaymentSuccess/>}
    />
  );
};
