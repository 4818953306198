import { Box, RadioGroup } from '@mui/material';
import { RentalTime } from 'dtos/lead';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: RentalTime;
  onSubmit: (val: RentalTime) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<RentalTime>, 'onSelect'>> = [
  {
    label: '6+ Months',
    value: RentalTime.SIX_MONTHS_PLUS,
  },
  {
    label: '0 - 6 Months',
    value: RentalTime.SHORT_TERM,
  },
];

export function RentalTimeline({ value: valueProp, onSubmit, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<RentalTime>(valueProp);

  const handleNext = React.useCallback(() => {
    onSubmit(currentValue);
  }, [currentValue, onSubmit]);

  return (
    <SectionWrapper
      title="How long do you typically rent this property for?"
      description="This helps us determine which type of policy or endorsements you will need"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!currentValue}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: 4, width: { xs: '100%', md: '480px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={setCurrentValue}
              selected={currentValue === option.value}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Box>
    </SectionWrapper>
  );
}
