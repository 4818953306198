import { UserDTO } from 'dtos';

import RequestService from './Request';

class _UserService {
  getCurrentUser(): Promise<UserDTO> {
    return RequestService.client.get<UserDTO>('/user/me').then((response) => response.data);
  }
}

const UserService = new _UserService();

export { _UserService, UserService };
