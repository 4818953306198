import { Box, BoxProps, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MailingType } from 'dtos';
import { useCreateMailingListEntry } from 'queries/useBetaList';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

const emailValidator = yup.string().email('Incorrect address email.').required('Email is required.');

interface Options {
  initMessage?: string;
  inputPlaceholder?: string;
  successMessage?: string;
  containerProps?: BoxProps['sx'],
  type: MailingType;
  state?: string | null;
  data?: any | null;
}

export const useMailingListSignup = (options: Options) => {
  const initMessage = options.initMessage || 'Send';
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState(initMessage);
  const { mutate, isLoading } = useCreateMailingListEntry({
    onSuccess: () => {
      setMessage(options.successMessage || 'Thank you for signing up!');
      setEmail('');

      setTimeout(() => {
        setMessage(initMessage);
      }, 2000);
    }
  });

  const handleSubmit = useCallback(async () => {
    try {
      setError(null);
      await emailValidator.validate(email);
      mutate({
        email,
        type: options.type,
        state: options.state,
        data: options.data,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }, [email, mutate, options.data, options.state, options.type]);

  const form = useMemo(() => {
    return (
      <Box sx={options.containerProps}>
        <Box sx={{ position: 'relative' }}>
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={options.inputPlaceholder || 'Enter your email'}
            sx={{ backgroundColor: '#fff', width: '100%', borderRadius: 2 }}
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              borderRadius: 2,
              position: { md: 'absolute' },
              mt: { md: 0, xs: 2 },
              width: { md: 'auto', xs: 240 },
              minWidth: { md: 100, xs: 0 },
              right: 0,
              height: { xs: 40, md: '100%' }
            }}
          >
            {message}
          </Button>
        </Box>
        {error && (
          <Typography variant="body1" sx={{ color: 'red', mt: 1 }}>
            {error}
          </Typography>
        )}
      </Box>
    );
  }, [options.containerProps, options.inputPlaceholder, email, handleSubmit, message, error]);

  return {
    form,
    email,
    error,
    setEmail,
    handleSubmit,
    message: isLoading ? 'Submitting...' : message
  };
};
