import { Box, CircularProgress as _CircularProgress,circularProgressClasses, CircularProgressProps } from '@mui/material';

export const CircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <_CircularProgress
        variant="determinate"
        sx={{ color: '#fff' }}
        size={140}
        thickness={4}
        {...props}
        value={100}
      />
      <_CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          animationDuration: '800ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={140}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
