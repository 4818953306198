import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useDateFieldRendered, useTextFieldRendered } from 'components/forms/helpers';
import dayjs from 'dayjs';
import { InsuranceHolder } from 'dtos';
import { noop } from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

interface FormData {
  insuranceHolder: string;
  companyName?: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  additionalInsured: boolean;
  additionalInsuredFirstName?: string;
  additionalInsuredLastName?: string;
  additionalInsuredDateOfBirth?: string;
}
export interface Props {
  insuranceHolder?: InsuranceHolder;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  additionalInsured?: boolean;
  additionalInsuredFirstName?: string;
  additionalInsuredLastName?: string;
  additionalInsuredDateOfBirth?: string;
  onSubmit: (data: FormData) => void;
  updating?: boolean;
}

const schema = yup.object().shape({
  companyName: yup.string().when('insuranceHolder', {
    is: InsuranceHolder.COMPANY,
    then: yup.string().required('Company name is required'),
    otherwise: yup.string().nullable()
  }),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  birthDate: yup.string().required('Date of Birth is required').nullable().test(
    'past-date',
    'Birth date must be in the past',
    (value) => {
      if (!value) {
        return true;
      }
      return dayjs(value).isBefore(dayjs());
    }
  ),
  additionalInsured: yup.boolean().nullable(),
  additionalInsuredFirstName: yup.string().nullable().when('additionalInsured', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  additionalInsuredLastName: yup.string().nullable().when('additionalInsured', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  additionalInsuredDateOfBirth: yup.string().nullable().when('additionalInsured', {
    is: true,
    then: yup.string().required('Date of Birth is required').nullable().test(
      'past-date',
      'Birth date must be in the past',
      (value) => {
        if (!value) {
          return true;
        }
        return dayjs(value).isBefore(dayjs());
      }
    ),
    otherwise: yup.string().nullable()
  })
});

export function FirstLastDob({
  insuranceHolder,
  companyName: initialCompanyName,
  firstName: initialFirstName,
  lastName: initialLastName,
  birthDate: initialBirthDate,
  additionalInsured: initialAdditionalInsured,
  additionalInsuredFirstName: initialAdditionalInsuredFirstName,
  additionalInsuredLastName: initialAdditionalInsuredLastName,
  additionalInsuredDateOfBirth: initialAdditionalInsuredDateOfBirth,
  onSubmit,
  updating = false
}: Props) {
  const { getValues, control, formState, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      insuranceHolder,
      companyName: initialCompanyName,
      firstName: initialFirstName,
      lastName: initialLastName,
      birthDate: initialBirthDate,
      additionalInsured: initialAdditionalInsured,
      additionalInsuredFirstName: initialAdditionalInsuredFirstName,
      additionalInsuredLastName: initialAdditionalInsuredLastName,
      additionalInsuredDateOfBirth: initialAdditionalInsuredDateOfBirth,
    },
    mode: 'all'
  });

  React.useEffect(() => setValue('insuranceHolder', insuranceHolder), [setValue, insuranceHolder]);

  const focusOnInput = React.useCallback((input) => {
    if (input != null) {
      input.focus();
    }
  }, []);

  const handleSubmit = React.useCallback(() => {
    const additionalInsured = getValues('additionalInsured');
    if (!additionalInsured) {
      setValue('additionalInsuredFirstName', null);
      setValue('additionalInsuredLastName', null);
      setValue('additionalInsuredDateOfBirth', null);
    }
    onSubmit({ ...getValues() });
  }, [getValues, setValue, onSubmit]);

  const watchedAdditionalInsured = watch('additionalInsured');
  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderDateField = useDateFieldRendered(control, formState.errors);

  return (
    <SectionWrapper
      title="We'll help you find great coverage in minutes. Ready to go?"
      primaryButtonLabel="Let's do this"
      primaryButtonLoading={updating}
      onPrimaryButtonClick={handleSubmit}
      disableButtons={!formState.isValid}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          mt: { xs: 1, md: 2 }
        }}
      >
        {insuranceHolder === InsuranceHolder.COMPANY && (
          <Box sx={{ width: 330 }}>
            {renderTextField('companyName', 'Company Name', { inputRef: insuranceHolder === InsuranceHolder.COMPANY ? focusOnInput : noop })}
          </Box>
        )}
        <Box sx={{ width: 330 }}>
          {renderTextField('firstName', 'First Name', { inputRef: insuranceHolder === InsuranceHolder.PERSON ? focusOnInput : noop })}
        </Box>
        <Box sx={{ width: 330 }}>
          {renderTextField('lastName', 'Last Name')}
        </Box>
        <Box sx={{ width: 330 }}>
          {renderDateField('birthDate', 'Date of Birth')}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: 330, pl: 2 }}>
          <FormControlLabel
            control={
              <Controller
                name={'additionalInsured'}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <Typography variant="body2" sx={{ fontWeight: 400, color: (t) => t.palette.grey['900'] }}>
                Additional Insured?
              </Typography>
            }
          />
        </Box>
        {watchedAdditionalInsured && (
          <>
            <Box sx={{ width: 330 }}>
              {renderTextField('additionalInsuredFirstName', 'Additional First Name')}
            </Box>
            <Box sx={{ width: 330 }}>
              {renderTextField('additionalInsuredLastName', 'Additional Last Name')}
            </Box>
            <Box sx={{ width: 330 }}>
              {renderDateField('additionalInsuredDateOfBirth', 'Additional Date of Birth')}
            </Box>
          </>
        )}
      </Box>
    </SectionWrapper>
  );
}
