import { Box, FormControlLabel, Typography } from '@mui/material';
import { LeadSourceForm } from 'components/forms/lead-source';
import { Switch } from 'components/Switch';
import { LeadDTO } from 'dtos';
import * as React from 'react';

export interface Props {
  lead: LeadDTO;
}

export const AdditionalSection = ({ lead }: Props) => {
  return (
    <Box sx={() => ({ flex: 1 })}>
      <Typography sx={{ mb: 2 }} variant="h4">Additional Information</Typography>
      <Box sx={() => ({ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' })}>
        <FormControlLabel
          labelPlacement="start"
          sx={{ ml: 0, width: '100%', '.MuiTypography-root': { width: '100%' } }}
          control={<Switch sx={{ ml: 2 }} checked={lead.earthquakeCoverage} disabled/>}
          label="Earthquake Coverage"
        />

        <FormControlLabel
          labelPlacement="start"
          sx={{ ml: 0, width: '100%', '.MuiTypography-root': { width: '100%' } }}
          control={<Switch sx={{ ml: 2 }} checked={lead.floodCoverage} disabled/>}
          label="Flood Coverage"
        />

        <LeadSourceForm
          leadId={lead.id}
          sx={{
            mt: 2,
          }}
        />
      </Box>
    </Box>
  );
};
