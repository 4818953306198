import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box, BoxProps,
  Button, InputAdornment
} from '@mui/material';
import {
  useDateFieldRendered,
  useSelectRendered,
  useSwitchRendered,
  useTextFieldRendered
} from 'components/forms/helpers';
import dayjs from 'dayjs';
import { LeadDTO, UpdateLeadDTO } from 'dtos';
import { isString } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface Props {
  lead?: Partial<LeadDTO>;
  onSubmit: (data: UpdateLeadDTO) => void;
  isLoading?: boolean;
  submit?: string | JSX.Element;
  containerSx?: BoxProps['sx'];
}

interface FormData {
  questionUnrepairedDamageKnownDefects: boolean;
  questionFuses: boolean;
  questionBusinessFootTraffic: boolean;
  questionPoolHotTubSpa: boolean;

  questionPlumbingReplaced: boolean;
  plumbingReplacementYear?: number;

  questionRoofReplaced: boolean;
  roofReplacementYear?: number;

  questionInsuranceClaims: boolean;
  insuranceClaimValue?: number;
  insuranceClaimsOccurrence?: string;
}

const schema = yup.object().shape({
  questionUnrepairedDamageKnownDefects: yup.boolean(),
  questionFuses: yup.boolean(),
  questionBusinessFootTraffic: yup.boolean(),
  questionPoolHotTubSpa: yup.boolean(),

  questionPlumbingReplaced: yup.boolean(),
  plumbingReplacementYear: yup.number().when('questionPlumbingReplaced', {
    is: true,
    then: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Year is required').min(1500, 'Please enter a correct year').max(new Date().getFullYear(), `Max current year - ${new Date().getFullYear()}`),
    otherwise: yup.number().nullable()
  }),

  questionRoofReplaced: yup.boolean(),
  roofReplacementYear: yup.number().when('questionRoofReplaced', {
    is: true,
    then: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Year is required').min(1500, 'Please enter a correct year').max(new Date().getFullYear(), `Max current year - ${new Date().getFullYear()}`),
    otherwise: yup.number().nullable()
  }),

  questionInsuranceClaims: yup.boolean(),
  insuranceClaimValue: yup.number().when('questionInsuranceClaims', {
    is: true,
    then: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Value is required').min(0, 'Minimum 0'),
  }),
  insuranceClaimsOccurrence: yup.string().when('questionInsuranceClaims', {
    is: true,
    then: yup.string().required('Occurrence is required').nullable().test(
      'past-date',
      'Date must be in the past',
      (value) => {
        return dayjs(value).isBefore(dayjs());
      }
    ),
    otherwise: yup.string().nullable()
  }),
});

export const HomeQuestionsForm = ({ lead, isLoading, onSubmit, submit, containerSx }: Props) => {
  const { control, handleSubmit, formState, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      questionUnrepairedDamageKnownDefects: lead?.questionUnrepairedDamageKnownDefects || false,
      questionFuses: lead?.questionFuses || false,
      questionBusinessFootTraffic: lead?.questionBusinessFootTraffic || false,
      questionPoolHotTubSpa: lead?.questionPoolHotTubSpa || false,
      questionPlumbingReplaced: lead?.questionPlumbingReplaced || false,
      plumbingReplacementYear: lead?.plumbingReplacementYear || undefined,
      questionRoofReplaced: lead?.questionRoofReplaced || false,
      roofReplacementYear: lead?.roofReplacementYear || undefined,
      questionInsuranceClaims: lead?.questionInsuranceClaims || false,
      insuranceClaimValue: lead?.insuranceClaimValue || undefined,
      insuranceClaimsOccurrence: lead?.insuranceClaimsOccurrence || undefined
    }
  });
  const questionInsuranceClaims = watch('questionInsuranceClaims');
  const questionPlumbingReplaced = watch('questionPlumbingReplaced');
  const questionRoofReplaced = watch('questionRoofReplaced');

  const renderTextField = useTextFieldRendered(control, formState.errors);
  const _renderSelect = useSelectRendered(control, formState.errors);
  const renderDate = useDateFieldRendered(control, formState.errors);
  const renderSwitch = useSwitchRendered(control, formState.errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Box
        sx={{
          gridTemplateColumns: {
            sm: '1fr',
          },
          gap: (t) => t.spacing(4),
          display: 'grid',
          mb: 6,
          ...containerSx
        }}
      >
        {renderSwitch('questionUnrepairedDamageKnownDefects', 'The dwelling has and/or is the subject of any of the following: unrepaired damage, known potential defects, open or pending claims of any kind, property disputes and/or lawsuits.')}
        {renderSwitch('questionFuses', 'The dwelling has fuse(s) providing power to some portion of the dwelling.')}
        {renderSwitch('questionBusinessFootTraffic', 'Business with foot traffic into your home (e.g. hairdresser, counselor, daycare, Airbnb). Does not include home offices.')}
        {renderSwitch('questionPoolHotTubSpa', 'The dwelling has a pool, hot tub, spa or similar structure.')}
        {renderSwitch('questionPlumbingReplaced', 'The plumbing has been fully replaced since the property was built.')}
        {questionPlumbingReplaced ? renderTextField('plumbingReplacementYear', 'What year it was replaced?', {
          type: 'number',
          required: true,
          inputProps: { min: 1900 },
        }) : null}
        {renderSwitch('questionRoofReplaced', 'The roof has been fully replaced since the property was built.')}
        {questionRoofReplaced ? renderTextField('roofReplacementYear', 'What year it was replaced?', {
          type: 'number',
          required: true,
          inputProps: { min: 1900 },
        }) : null}

        {renderSwitch('questionInsuranceClaims', 'There were insurance claims on the property in the past 3 years.')}
        {questionInsuranceClaims ? renderDate('insuranceClaimsOccurrence', 'When did the claim occur?', {
          required: true,
        }) : null}

        {questionInsuranceClaims ? renderTextField('insuranceClaimValue', 'How much was the claim for?', {
          type: 'number',
          required: true,
          inputProps: { min: 0 },
          InputProps: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }
        }) : null}
      </Box>

      {!isString(submit) ? submit : (
        <Button
          sx={{ width: 200, margin: '0 auto', display: 'block' }}
          type="submit"
          disabled={isLoading}
          variant="contained"
        >
          {isLoading ? 'Loading...' : submit || 'Save'}
        </Button>
      )}
    </form>
  );
};
