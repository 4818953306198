import { Box, BoxProps, Container } from '@mui/material';
import { Navigation } from 'components/Navigation';
import { FCC } from 'types';

interface Props {
  onLogoClickPath?: string;
  alwaysContact?: boolean;
  sx?: BoxProps['sx'];
}

export const Layout: FCC<Props> = ({ children, onLogoClickPath, alwaysContact, sx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', ...sx }}>
      <Navigation onLogoClickPath={onLogoClickPath} alwaysContact={alwaysContact}/>
      <Container sx={{ p: 2, flex: 1 }}>
        {children}
      </Container>
    </Box>
  );
};
