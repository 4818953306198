import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, RadioGroup } from '@mui/material';
import { useSelectRendered, useTextFieldRendered } from 'components/forms/helpers';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  source?: string;
  sourceMore?: string;
  onSubmit: (source: string, sourceMore?: string) => void;
  onSkip: () => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<string>, 'onSelect'>> = [
  {
    label: 'Word of Mouth',
    value: 'word_of_mouth'
  },
  {
    label: 'google',
    value: 'google',
  },
  {
    label: 'Facebook',
    value: 'facebook'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Partner',
    value: 'partnership'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

interface FormData {
  source: string;
  sourceMore?: string;
}

const schema = yup.object().shape({
  source: yup.string().required('Referral source is required'),
  sourceMore: yup.string().nullable().when('source', (source, sourceMoreSchema) => {
    switch (source) {
      case 'partnership':
        return sourceMoreSchema.required('Please select the parnter who referred you');
      case 'other':
        return sourceMoreSchema.required('Please tell us more');
      default:
        return sourceMoreSchema.optional();
    }
  }),
});

export function ReferralSource({
  source: sourceProp,
  sourceMore: sourceMoreProp,
  onSubmit,
  onSkip,
  updating = false
}: Props) {
  const [showPartnerDropodown, setShowPartnerDropdown] = React.useState(false);
  const [showOtherInput, setShowOtherInput] = React.useState(false);
  const [source, setSource] = React.useState<string>(null);

  const { control, getValues, formState, setValue, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      source: sourceProp,
      sourceMore: sourceMoreProp
    },
    mode: 'all'
  });

  React.useEffect(function displayInputs() {
    if (source?.length) {
      clearErrors();
      switch (source) {
        case 'partnership':
          setShowOtherInput(false);
          setShowPartnerDropdown(true);
          break;
        case 'other':
          setShowPartnerDropdown(false);
          setShowOtherInput(true);
          break;
        default:
          setShowOtherInput(false);
          setShowPartnerDropdown(false);
          setValue('sourceMore', null, { shouldValidate: true });
          break;
      }
    }
  }, [source, setValue, setShowPartnerDropdown, setShowOtherInput, clearErrors]);

  const handleNext = React.useCallback(() => {
    onSubmit(getValues('source'), getValues('sourceMore'));
  }, [getValues, onSubmit]);

  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderSelectField = useSelectRendered(control, formState.errors);

  return (
    <SectionWrapper
      title="How did you hear about us?"
      primaryButtonLabel="Submit"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disablePrimaryButton={!formState.isValid}
      secondaryButtonLabel="Skip"
      onSecondaryButtonClick={onSkip}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: { xs: 2, md: 4 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 3 }, width: { xs: '100%', md: '420px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={(v) => {
                setSource(v);
                setValue('source', v, { shouldValidate: true });
              }}
              selected={source === option.value}
              key={idx}
              formControlLabelSx={{ py: 1 }}
            />
          ))}
        </RadioGroup>
        {showPartnerDropodown && (
          <Box sx={{ gap: { xs: 2, md: 3 }, width: { xs: '100%', md: '480px' } }}>
            {renderSelectField([
              {
                label: 'Marble',
                value: 'marble'
              },
              {
                label: 'Latchel',
                value: 'latchel'
              }
            ], 'sourceMore', 'Which partner?', { required: false })}
          </Box>
        )}
        {showOtherInput && (
          <Box sx={{ gap: { xs: 2, md: 3 }, width: { xs: '100%', md: '480px' } }}>
            {renderTextField('sourceMore', 'Who told you about us?', { required: false, variant: 'filled', placeholder: 'i.e. Jennie Miller referred me' })}
          </Box>
        )}
      </Box>
    </SectionWrapper>
  );
}
