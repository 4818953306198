import { Box, BoxProps, Button, CircularProgress } from '@mui/material';
import { Heading } from 'components/Heading';
import { noop } from 'lodash';
import * as React from 'react';
import { FCC } from 'types';

export interface Props {
  title: string;
  description?: string;
  primaryButtonLabel: string;
  primaryButtonLoading: boolean;
  onPrimaryButtonClick: () => void;
  disablePrimaryButton?: boolean;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  disableSecondaryButton?: boolean;
  disableButtons?: boolean;
  containerSx?: BoxProps['sx'];
}

export const SectionWrapper: FCC<Props> = ({
  title,
  description,
  containerSx,
  primaryButtonLabel,
  primaryButtonLoading = false,
  onPrimaryButtonClick = noop,
  disablePrimaryButton = false,
  disableButtons = false,
  secondaryButtonLabel,
  onSecondaryButtonClick = noop,
  disableSecondaryButton = false,
  children
}) => (
  <Box sx={{ px: { xs: 1, sm: 2 }, ...containerSx }}>
    <Heading title={title} description={description} />
    <Box display="flex" sx={{ flex: 1, justifyContent: 'center', my: 4 }}>
      {children}
    </Box>
    <Box
      display="flex"
      sx={{
        gap: 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        pb: { xs: 6, md: 0 },
        px: 2
      }}
    >
      <Button
        variant="contained"
        disabled={disableButtons || disablePrimaryButton}
        onClick={onPrimaryButtonClick}
        size="large"
        disableRipple
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 200,
          height: 50
        }}
      >
        {primaryButtonLoading
          ?  <CircularProgress size={30} color="inherit" />
          : primaryButtonLabel
        }
      </Button>
      {secondaryButtonLabel?.length && (
        <Button
          variant="text"
          disabled={disableButtons || disableSecondaryButton}
          onClick={onSecondaryButtonClick}
          size="large"
          disableRipple
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200,
            height: 50
          }}
        >
          {secondaryButtonLabel}
        </Button>
      )}
    </Box>
  </Box>
);
