import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { isString } from 'lodash';
import * as React from 'react';

interface Props extends BoxProps {
  headerFirst?: boolean;
  title: string;
  description: string | JSX.Element;
  image?: JSX.Element;
  extra?: JSX.Element;
  descriptionSx?: TypographyProps['sx'];
  button?: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
  };
}

export const StatusContainer = ({ title, descriptionSx, headerFirst, description, image, extra, button, children, ...restProps }: Props) => {
  return (
    <Box {...restProps} sx={{ textAlign: 'center', ...restProps.sx }}>
      {!headerFirst && image ? <Box sx={{ mb: 4 }}>{image}</Box> : null}

      <Typography variant="h2" sx={() => ({ mb: 1 })}>{title}</Typography>
      {extra}
      {isString(description) ? <Typography variant="body1" sx={descriptionSx}>{description}</Typography> : description}

      {(headerFirst && image) ? <Box sx={{ mt: 6, mb: 6 }}>{image}</Box> : null}

      {button ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: headerFirst ? 0 : 3 }}>
          <Button
            variant="contained"
            onClick={button.onClick}
            sx={{ display: 'block', margin: '0 auto' }}
            {...button.props}
          >
            {button.label}
          </Button>
        </Box>
      ) : null}

      {children}
    </Box>
  );
};
