import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Container, IconButton, Tooltip } from '@mui/material';
import { ContactModal } from 'components/ContactModal';
import { ReactComponent as Logo } from 'images/logo.svg';
import { useState } from 'react';
import { WEBSITE_URL } from 'rentr-constants';
import { StartOverModal } from 'views/onboarding/components/StartOverModal';

interface Props {
  allowStartOver?: boolean;
  onStartOver: () => void;
}

export const Navigation = ({ allowStartOver, onStartOver }: Props) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showStartOverModal, setShowStartOverModal] = useState(false);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: 70,
      backgroundColor: '#fff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }}>
      <Container sx={{
        color: '#fff',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        display: 'flex',
        gap: 2
      }}>
        <a href={WEBSITE_URL}>
          <Logo style={{ display: 'block', height: 34, width: 'auto' }}/>
        </a>
        <Box>
          {allowStartOver && (
            <Tooltip title="Start over" placement="bottom" arrow>
              <IconButton onClick={() => setShowStartOverModal(true)} sx={{ mr: 2 }}>
                <ReplayIcon sx={{ color: 'gray[900]'}} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Need help?" placement="bottom" arrow>
            <IconButton onClick={() => setShowContactModal(true)}>
              <HelpOutlineIcon sx={{ color: 'gray[900]'}} />
            </IconButton>
          </Tooltip>
        </Box>
        <ContactModal
          open={showContactModal}
          onClose={() => setShowContactModal(false)}
        />
        <StartOverModal
          open={showStartOverModal}
          onClose={() => setShowStartOverModal(false)}
          onCancel={() => setShowStartOverModal(false)}
          onStartOver={() => {
            onStartOver();
            setShowStartOverModal(false);
          }}
        />
      </Container>
    </Box>
  );
};
