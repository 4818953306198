import { BigLoaderWithMessage } from 'components/BigLoaderWithMessage';
import { ErrorTypography } from 'components/ErrorTypography';
import { StatusContainer } from 'components/StatusContainer';
import { ReactComponent as Files } from 'images/icons/files.svg';
import { useCheckPolicySigningStatus, useRequestSigningPolicyLink } from 'queries/usePolicy';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

interface QueryParams {
  event?: 'signing-complete';
  signing?: 'true';
}

interface Props {
  policyId: string;
  refetch: () => void;
}

export const TermsAndConditionsSection = ({ policyId, refetch }: Props) => {
  const { search } = useLocation();
  const data: QueryParams = qs.parse(search);
  const { mutate, isLoading, error } = useRequestSigningPolicyLink({
    onSuccess: (link) => {
      window.location.replace(link);
    }
  });

  useCheckPolicySigningStatus(policyId, {
    enabled: data.signing === 'true',
    refetchInterval: 2000,
    onSuccess: (data) => {
      if(data) {
        refetch();
      }
    }
  });

  if(data.signing === 'true') {
    return (
      <BigLoaderWithMessage
        title='We are waiting for DocuSign confirmation. It may take a minute.'
        description='Did you know that Starbucks pays more for employee health insurance than it pays for coffee?'
      />
    );
  }

  return (
    <StatusContainer
      sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mt: 10}}
      title="Terms and conditions"
      description="Sign the documents to confirm your purchase"
      image={<Files/>}
      headerFirst
      button={{
        label: isLoading ? 'Preparing documents...' : 'Preview and sign documents',
        onClick: () => mutate(policyId),
        props: {
          disabled: isLoading,
          sx: {
            minWidth: 280,
          },
        },
      }}
    >
      {<ErrorTypography error={error} />}
    </StatusContainer>
  );
};
