import AddIcon from '@mui/icons-material/Add';
import { Box, BoxProps, Button, Divider, Typography } from '@mui/material';
import { QuoteForm } from 'components/forms/quote';
import { Modal } from 'components/Modal';
import { CreateQuoteDTO, LeadDTO, UpdateQuoteDTO } from 'dtos';
import { useCreateQuote } from 'queries';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'rentr-constants';

interface Props extends BoxProps {
  lead: LeadDTO
}

export const AddQuoteButton = ({ lead, ...restProps }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const { mutate, isLoading } = useCreateQuote({
    onSuccess: (data) => {
      navigate(insertIdToRoute(ROUTES.dashboard.lead.QUOTES, data.leadId));
      setOpen(false);
    }
  });

  const handleSubmit = useCallback((d: UpdateQuoteDTO) => {
    mutate({
      ...d,
      leadId: lead.id,
    } as CreateQuoteDTO);
  }, [lead.id, mutate]);

  return (
    <Box {...restProps}>
      <Button onClick={() => setOpen(true)} variant="contained">
        <AddIcon sx={{ mr: 1 }} />
        New quote
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} boxSx={{ width: 800 }}>
        <div>
          <Typography variant="h2" sx={{ mb: 1 }}>
            New Quote
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <QuoteForm lead={lead} onSubmit={handleSubmit} isLoading={isLoading}/>
        </div>
      </Modal>
    </Box>
  );
};
