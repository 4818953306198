import { Typography, TypographyProps } from '@mui/material';
import { AxiosError } from 'axios';

interface Props {
  error?: AxiosError | null;
  sx?: TypographyProps['sx'];
}

export const ErrorTypography = ({ error, sx }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <Typography variant="body1" sx={{ color: 'red', mt: 2, ...sx }}>
      {error.response?.status === 500 ? 'Something went wrong. Please try again later.' : error.message}
    </Typography>
  );
};
