import { Box, Typography } from '@mui/material';
import { Layout } from 'components/Layout';
import { LoaderLayout } from 'components/LoaderLayout';
import { Stepper } from 'components/Stepper';
import { PolicyDTO, PolicyStatus, QuoteDTO } from 'dtos';
import { useLead } from 'queries';
import { useCreatePolicyClient, useLeadPolicies } from 'queries/usePolicy';
import qs from 'query-string';
import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  findNextSection,
  findPreviousSection,
  Flow,
  FORM_SECTIONS_FOR_FLOW,
  FormSection,
  getFlowForCarrier,
  STEPS_FOR_FLOW,
} from 'views/purchase/config';
import { DirectTermsSection } from 'views/purchase/sections/DirectTerms';
import { InFlowPaymentSection } from 'views/purchase/sections/InFlowPayment';
import { OutOfFlowPaymentSection } from 'views/purchase/sections/OutOfFlowPayment';
import { PaymentSection } from 'views/purchase/sections/Payment';
import { SignDocumentsSection } from 'views/purchase/sections/SignDocumentsSection';
import { SuccessPaymentSection } from 'views/purchase/sections/SuccessPayment';
import { TermsAndConditionsSection } from 'views/purchase/sections/TermsAndConditions';

import { QuoteSelection } from './sections/QuoteSelection';
import { SelectedQuoteSection } from './sections/SelectedQuote';
import { YoureAllSetSection } from './sections/YoureAllSet';

interface QueryParams {
  checkout?: string;
}

export const PurchaseView = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const params: QueryParams = qs.parse(search);

  const leadId = id as string;
  const [section, setSection] = useState<FormSection>(FormSection.QUOTES);
  const [selectedQuote, setSelectedQuote] = useState<null | QuoteDTO>(null);
  const [policy, setPolicy] = useState<null | PolicyDTO>(null);
  const [flow, setFlow] = useState<null | Flow>(null);

  const { isLoading: isLoadingLead } = useLead(leadId);

  const { mutate: createPolicy, isLoading: _isCreatingPolicy } = useCreatePolicyClient({
    onSuccess: (data) => {
      setPolicy(data);
      if (flow) {
        const nextSection = findNextSection(flow, FormSection.IN_FLOW_PAYMENT);
        setSection(nextSection);
      }
    }
  });

  const { isLoading: isLoadingPolicies, refetch } = useLeadPolicies(leadId, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      const draftPolicy = data.find((p) => p.status === PolicyStatus.DRAFT);
      const activePolicy = data.find((p) => p.status === PolicyStatus.ACTIVE);

      if (activePolicy) {
        setSection(FormSection.YOURE_ALL_SET);
        setPolicy(activePolicy);
      } else if (draftPolicy) {
        const flow = getFlowForCarrier(draftPolicy.carrier);
        setFlow(flow);

        const section = FORM_SECTIONS_FOR_FLOW[flow].initSection(
          draftPolicy,
          Boolean(params.checkout)
        );
        setSection(section);
        setPolicy(draftPolicy);
      }
    },
  });

  const activeSectionIdx = useMemo(() => {
    if (flow) {
      return STEPS_FOR_FLOW(flow).findIndex((step) =>
        step.sections.includes(section)
      );
    }

    return -1;
  }, [flow, section]);

  const stepsRenderers = useMemo((): {
    [key in FormSection]: () => JSX.Element | null;
  } => {
    return {
      // review quotes
      [FormSection.QUOTES]: () => (
        <QuoteSelection
          leadId={leadId}
          onQuoteSelection={(quote) => {
            setSelectedQuote(quote);
            const expectedFlow = getFlowForCarrier(quote.carrier);
            setFlow(expectedFlow);

            if (expectedFlow) {
              setSection(findNextSection(expectedFlow, FormSection.QUOTES));
            }
          }}
        />
      ),
      // confirm quote selection
      [FormSection.SELECTED_QUOTE]: () => {
        if (!selectedQuote) {
          return null;
        }

        return (
          <SelectedQuoteSection
            quote={selectedQuote}
            onConfirm={() => {
              if (flow) {
                setSection(findNextSection(flow, FormSection.SELECTED_QUOTE));
              }
            }}
            onPreviousStepClick={() => {
              setSection(findPreviousSection(flow, FormSection.SELECTED_QUOTE));
            }}
          />
        );
      },
      // direct T&C - let customer know to expect t&c from carrier
      [FormSection.DIRECT_TERMS_AND_CONDITIONS]: () => {
        if (!selectedQuote) {
          return null;
        }

        return (
          <DirectTermsSection
            quote={selectedQuote}
            onContinueClick={() => {
              if (flow) {
                setSection(
                  findNextSection(flow, FormSection.DIRECT_TERMS_AND_CONDITIONS)
                );
              }
            }}
            onReviewPolicyClick={() => {
              if (flow) {
                setSection(
                  findPreviousSection(flow, FormSection.DIRECT_TERMS_AND_CONDITIONS)
                );
              }
            }}
          />
        );
      },
      // (N/A) agency T&C - start docusign flow
      [FormSection.HF_TERMS_AND_CONDITIONS]: () => {
        if (!policy) {
          return null;
        }

        return (
          <TermsAndConditionsSection policyId={policy?.id} refetch={refetch} />
        );
      },
      // (N/A) agency T&C - docusign success
      [FormSection.HF_SIGN_DOCUMENTS]: () => (
        <SignDocumentsSection
          onContinueClick={() => {
            if (flow) {
              setSection(findNextSection(flow, FormSection.HF_SIGN_DOCUMENTS));
            }
          }}
        />
      ),
      // direct bill - agent must collect over the phone
      [FormSection.IN_FLOW_PAYMENT]: () => (
        <InFlowPaymentSection
          onContinueClick={() => createPolicy({ quoteId: selectedQuote.id })}
        />
      ),
      // agency bill - kick out to ascend
      [FormSection.HF_PAYMENT]: () => {
        if (!(selectedQuote || policy)) {
          return null;
        }

        return <PaymentSection quoteId={selectedQuote ? selectedQuote.id : policy.quoteId} policy={policy} refetch={refetch} />;
      },
      // agency bill - successful payment through ascend
      [FormSection.FINISHED_FLOW]: () => {
        if (!policy) {
          return null;
        }

        return <SuccessPaymentSection policy={policy} />;
      },
      // (N/A) carrier will send link of some sort to pay
      [FormSection.OUT_OF_FLOW_PAYMENT]: () => {
        if (!policy) {
          return null;
        }

        return (
          <OutOfFlowPaymentSection
            policy={policy}
            onContinueClick={() => {
              if (flow) {
                setSection(
                  findNextSection(flow, FormSection.OUT_OF_FLOW_PAYMENT)
                );
              }
            }}
          />
        );
      },
      // (end of flow) policy created in draft state, possibly paid (through ascend)
      [FormSection.YOURE_ALL_SET]: () => {
        if (!policy) {
          return null;
        }

        return <YoureAllSetSection policy={policy} />;
      },
    };
  }, [leadId, selectedQuote, flow, policy, createPolicy, refetch]);

  if (isLoadingLead || isLoadingPolicies) {
    return <LoaderLayout title="Loading..." />;
  }

  return (
    <Layout onLogoClickPath="/" sx={{ backgroundColor: (t) => t.palette.background.default }} alwaysContact>
      <Box sx={{ mx: { xs: 0, md: 2 } }}>
        {flow && (
          <Stepper
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, mt: 2 }}
            disabled
            steps={STEPS_FOR_FLOW(flow).map((step) => step.name)}
            nonLinear={false}
            activeStep={activeSectionIdx}
            onStepClick={(_i) => void 0}
            completedIdxs={[]}
          />
        )}
        <Box sx={{ py: { xs: 0, md: 4 } }}>{stepsRenderers[section]()}</Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={(t) => ({
              fontSize: '12px',
              color: t.palette.grey['700'],
              maxWidth: '600px',
              textAlign: 'center',
            })}
          >
            Rentr Insurance Services dba Homeflow Insurance Services
            (“Homeflow”) is licensed as a property casualty insurance agency in
            all states in which products are offered.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};
