import { Box, List, ListItem, Typography } from '@mui/material';
import { Layout } from 'components/Layout';

export const PrivacyPolicyView = () => {
  return (
    <Layout
      alwaysContact
    >
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <Typography variant="h2" alignSelf="center" sx={{ mb: 2 }}>
          Privacy Policy
        </Typography>
        <Typography>
          This Privacy Policy describes how Rentr Insurance Services, LLC dba
          Homeflow Insurance Services (&ldquo;Homeflow&rdquo;) collects, uses,
          discloses and otherwise processes personal information from homeowners
          and website visitors in connection with our website and applications
          (collectively, &ldquo;the Services&rdquo;), and explains the rights
          and choices available to individuals with respect to their
          information.
        </Typography>
        <Typography>
          We treat all personal information covered by this Privacy Policy,
          including information about any visitors to our website, as pertaining
          to individuals acting as business representatives, and not in their
          individual or household capacity.
        </Typography>
        <Typography variant="h3" sx={{ my: 2 }}>Personal information we collect</Typography>
        <Typography variant="h4">
          Personal information you provide to us:
        </Typography>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Contact information</strong>, such as your first and
            last name, email address, and phone number.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Insurance application information</strong>, such as
            your date of birth, property address, and any other personal
            information required to complete your insurance application.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Payment information</strong>needed to complete your
            transactions on or through our Services, including name, payment
            card information, and billing information. This information is
            processed by our payment service provider, Ascend, or by our
            insurance carrier partners. We do not store payment card numbers.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Feedback or correspondence</strong>, such as
            information you provide when you contact us with questions,
            feedback, product reviews, or otherwise correspond with us online.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Usage information</strong>, such as information about how you use the Services and interact
          with us, including information associated with any content you upload
          to the Services or otherwise submit to us, and information you provide
          when you use any interactive features of the Services.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Marketing information</strong>, such as your preferences for receiving communications about our
          activities and publications, and details about how you engage with our
          communications..
          </ListItem>
        </List>
        <Typography variant="h4">
          Information we obtain from other sources:
        </Typography>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Social media information.</strong>
            We may maintain pages on social media platforms, such as Facebook,
            TikTok, Instagram, LinkedIn, and other third-party platforms. When you
            visit or interact with our pages on those platforms, you or the
            platforms may provide us with information and we will treat such
            information in accordance with this Privacy Policy. Please note that
            the platform provider&rsquo;s privacy policy will apply to their
            collection, use and processing of your personal information.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Third-party login information.</strong>
            When you link, connect, or login to the Services with a third party
            service (e.g. Google, Facebook, or Apple), you direct the service to
            send us information such as your registration, friends list, and
            profile information as controlled by that service or as authorized by
            you via your privacy settings at that service.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Lead generators and data providers.</strong>
            We engage lead generators to help us identify prospective customers
            who may be in the market for home insurance. We also use data
            providers to identify contact information for those prospective
            customers.
          </ListItem>
        </List>
        <Box>
          <Typography variant="h4">
            <strong>Automatic data collection.</strong>
          </Typography>
          <Typography variant="body1">
            We and our service providers may automatically log information
            about you, your computer or mobile device, and your interaction over
            time with our Services, our communications and other online services,
            such as:
          </Typography>
        </Box>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Devide data</strong>, such as your computer&rsquo;s or mobile device&rsquo;s operating
            system type and version, manufacturer and model, browser type, screen
            resolution, RAM and disk size, CPU usage, device type (e.g., phone,
            tablet), IP address, unique identifiers (including identifiers used
            for advertising purposes), language settings, mobile device carrier,
            radio/network information (e.g., WiFi, LTE, 4G), and general location
            information such as city, state or geographic area.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Online activity data</strong>, such as pages or screens you viewed, how long you spent on a page
            or screen, browsing history, navigation paths between pages or
            screens, information about your activity on a page or screen, access
            times, and duration of access, and whether you have opened our
            marketing emails or clicked links within them.
          </ListItem>
        </List>
        <Typography variant="h4">We use the following tools for automatic data collection:</Typography>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Cookies</strong>, which are text files that websites store on a visitor&lsquo;s
            device to uniquely identify the visitor&rsquo;s browser or to store
            information or settings in the browser for the purpose of helping you
            navigate between pages efficiently, remembering your preferences,
            enabling functionality, helping us understand user activity and
            patterns, and facilitating online advertising.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Local storage technologies</strong>, like HTML5, that provide cookie-equivalent functionality but can
            store larger amounts of data, including on your device outside of your
            browser in connection with specific applications.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Web beacons</strong>, also known as pixel tags or clear GIFs, which are used to
            demonstrate that a webpage or email was accessed or opened, or that
            certain content was viewed or clicked.
          </ListItem>
        </List>
        <Typography variant="h3" sx={{ my: 2 }}>How we use your personal information</Typography>
        <Typography variant="h4">To operate our Services:</Typography>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            Provide, operate, maintain, secure and improve our Services
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Fulfill payments and transactions
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Communicate with you about our Services, including by sending you
            announcements, updates, security alerts, and support and
            administrative messages
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Understand your needs and interests, and personalize your experience
            with our Services and our communications
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Respond to your requests, questions and feedback
          </ListItem>
        </List>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4">Marketing and Advertising</Typography>
          <Typography variant="h4" sx={{ fontWeight: 400 }}>&nbsp;including:</Typography>
        </Box>
        <List sx={{ listStyleType: 'disc', px: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Email</strong>. We, and our affiliates, may from time-to-time send you direct
            marketing emails as permitted by law, including, but not limited to,
            notifying you of special promotions, offers and events via email. You
            may opt out of our marketing emails as described in the &ldquo;Opt out of
            marketing communications&rdquo; section below.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong> Text Message</strong>. We may notify you of opportunities to use our Services via text
            message and other alerts you request. If you would like more
            information about our do-not-call policy, or how to opt out, please
            review the &ldquo;Opt out of marketing communications&rdquo; section below. You may be liable for standard SMS and
            per-minute charges by your mobile carrier.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <strong>Interest-based advertising</strong>. We engage our advertising partners, including third party
            advertising companies and social media companies, to display ads
            around the web. These companies may use cookies and similar
            technologies to collect information about your interaction (including
            the data described in the &ldquo;Automatic Data Collection&rdquo; section above) over time across our Services, our
            communications and other online services, and use that information to
            serve online ads that they think will interest you. This is called
            interest-based advertising. We may also share information about our
            users with these companies to facilitate interest-based advertising to
            those or similar users on other online platforms. You can learn more
            about your choices for limiting interest-based advertising in the
            &ldquo;Online tracking opt-out&rdquo; section below.
          </ListItem>
        </List>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>To comply with law.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;As we believe necessary or appropriate to comply with applicable laws,
            lawful requests, and legal process, such as to respond to subpoenas or
            requests from government authorities.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>For compliance, fraud prevention, and safety.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;To: (a) protect our, your or others&rsquo; rights, privacy, safety or
            property (including by making and defending legal claims); (b) enforce
            the terms and conditions that govern our services; and (c) protect,
            investigate and deter against fraudulent, harmful, unauthorized,
            unethical or illegal activity.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>For research and development.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;To analyze and improve the Services, promote our business, and to
            develop new products and Services, including by studying use of our
            Services.
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ my: 2 }}>How we share your personal information</Typography>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Affiliates.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may share your personal information with our corporate parent,
            subsidiaries, and affiliates, for purposes consistent with this Privacy
            Policy.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Service providers.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may share your personal information with third party companies
            and individuals that provide services on our behalf or help us operate
            our Services (such as customer support, content moderation, hosting,
            analytics, email delivery, marketing, identity verification, fraud
            detection, payment processing, and database management).
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Advertising partners.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may share your personal information with third party
            advertising companies, including for the&nbsp;interest-based advertising&nbsp;purposes
            &nbsp;described above.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Professional advisors.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may disclose your personal information to professional advisors,
            such as lawyers, bankers, auditors and insurers, where necessary in the
            course of the professional services that they render to us.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Authorities and others.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may share your personal information with law enforcement, government
            authorities and private parties as we believe in good faith to be
            necessary or appropriate for the compliance purposes described
            above.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Business transfers.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;We may sell, transfer or otherwise share some or all of our
            business or assets, including your personal information, in connection
            with a business transaction (or potential business transaction) such as
            a corporate divestiture, merger, consolidation, acquisition,
            reorganization or sale of assets, or in the event of bankruptcy or
            dissolution.
          </Typography>
        </Box>
        <Typography variant="h4">Your Choices</Typography>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Access or update your information.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;If you have registered for an account with us, you may review and
            update certain personal information in your account profile by logging
            into the account.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Opt out of marketing communications.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;You may opt out of marketing-related emails and texts by following the
            opt-out or unsubscribe instructions within the marketing communication
            we send you. You may also contact us at
            &nbsp;<a href="mailto:support@homeflow.me">support@homeflow.me</a>
            &nbsp;to unsubscribe or learn more about our do-not-call policy. Even
            if you opt out of marketing-related communications, you may continue to
            receive service-related and other non-marketing
            communications
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Online tracking opt-out.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;There are a number of ways to opt out of having your online
            activity and device data collected through our Services, which we have
            summarized below:
          </Typography>
        </Box>
        <Box>
          <List sx={{ listStyleType: 'disc', px: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Blocking cookies in your browser</strong>.&nbsp;Most browsers let you remove or reject cookies, including
              cookies used for interest-based advertising. To do this, follow the
              instructions in your browser settings. Many browsers accept cookies by
              default until you change your settings. For more information about
              cookies, including how to see what cookies have been set on your
              device and how to manage and delete them, visit
              &nbsp;<a href="https://www.google.com/url?q=https://www.allaboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1661795645930424&amp;usg=AOvVaw3qEZsTrGsPYDYJG8IPo2Ki">
                allaboutcookies.org
              </a>
              . Use the following links to learn more about how to control cookies
              and online tracking through your browser:
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'circle', px: 8 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <a href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop&amp;sa=D&amp;source=editors&amp;ust=1661795645930804&amp;usg=AOvVaw3wmdgYRwVhyMWeyX1KWLGE" target="_blank" rel="noreferrer">
                Firefox
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <a href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647&amp;sa=D&amp;source=editors&amp;ust=1661795645931047&amp;usg=AOvVaw2ClOri7HGxW_GFQEbzRXbl" target="_blank" rel="noreferrer">
                Chrome
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <a href="https://www.google.com/url?q=https://support.microsoft.com/en-us/microsoft-edge/learn-about-tracking-prevention-in-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869&amp;sa=D&amp;source=editors&amp;ust=1661795645931402&amp;usg=AOvVaw3OJPP5E9KRBdjmkThWN7bI" target="_blank" rel="noreferrer">
                Microsoft Edge
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <a href="https://www.google.com/url?q=https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1661795645931747&amp;usg=AOvVaw3RO8byWzAq3xQFfKWUAD3p" target="_blank" rel="noreferrer">
                Safari
              </a>
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'disc', px: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Blocking advertising ID use in your mobile settings</strong>.
              &nbsp;Your mobile device settings may provide functionality to limit
              use of the advertising ID associated with your mobile device for
              interest-based advertising purposes.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Using privacy plug-ins or browsers</strong>.
              &nbsp;You can block our Services from setting cookies used for
              interest-based ads by using a browser with privacy features, like
              Brave, or installing browser plugins like&nbsp;
              <a href="https://www.google.com/url?q=https://www.eff.org/privacybadger&amp;sa=D&amp;source=editors&amp;ust=1661795645932322&amp;usg=AOvVaw2x0JArkNVIlJnAjj5iHNIS" target="_blank" rel="noreferrer">
                Privacy Badger
              </a>
              ,&nbsp;
              <a href="https://www.google.com/url?q=https://duckduckgo.com/&amp;sa=D&amp;source=editors&amp;ust=1661795645932560&amp;usg=AOvVaw2wT3yG0KyqH6B4xU3uKAOV" target="_blank" rel="noreferrer">
                DuckDuckGo
              </a>
              ,&nbsp;
              <a href="https://www.google.com/url?q=https://www.ghostery.com/&amp;sa=D&amp;source=editors&amp;ust=1661795645932772&amp;usg=AOvVaw2fIyIXV94yOOr7AfLmb7AY" target="_blank" rel="noreferrer">
                Ghostery
              </a>
              ,&nbsp;
              <a href="https://www.google.com/url?q=https://ublock.org/&amp;sa=D&amp;source=editors&amp;ust=1661795645933001&amp;usg=AOvVaw3vZF14Zy5HzFezvtPthPFs" target="_blank" rel="noreferrer">
                uBlock Origin
              </a>
              ,&nbsp;and configuring them to block third party cookies/trackers.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Platform opt-outs</strong>.
              &nbsp;The following advertising partners offer opt-out features that
              let you opt out of use of your information for interest-based
              advertising:
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'circle', px: 8 }}>
            <ListItem sx={{ display: 'list-item'}}>
              <a href="https://www.google.com/url?q=https://adssettings.google.com/&amp;sa=D&amp;source=editors&amp;ust=1661795645933519&amp;usg=AOvVaw0fLV0KMW_wFssZ3ep41_v2" target="_blank" rel="noreferrer">
                Google
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item'}}>
              <a href="https://www.google.com/url?q=https://www.facebook.com/about/ads&amp;sa=D&amp;source=editors&amp;ust=1661795645933940&amp;usg=AOvVaw1x4Z3YcL-_Im3BZJ_-NjeS" target="_blank" rel="noreferrer">
                Facebook
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item'}}>
              <a href="https://www.google.com/url?q=https://twitter.com/settings/account/personalization?lang%3Den&amp;sa=D&amp;source=editors&amp;ust=1661795645934321&amp;usg=AOvVaw1GSm4IkNevqRGwObsNr1PF" target="_blank" rel="noreferrer">
                Twitter
              </a>
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'disc', px: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Advertising industry opt-out tools</strong>.
              &nbsp;You can also use these opt-out options to limit use of your
              information for interest-based advertising by participating
              companies:
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'circle', px: 8 }}>
            <ListItem sx={{ display: 'list-item '}}>
              <a href="https://www.google.com/url?q=http://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;ust=1661795645934799&amp;usg=AOvVaw32wz5FjhQmKFZQy4JJipVu" target="_blank" rel="noreferrer">
                Digital Advertising Alliance
              </a>
            </ListItem>
            <ListItem sx={{ display: 'list-item '}}>
              <a href="https://www.google.com/url?q=http://optout.networkadvertising.org/?c%3D1&amp;sa=D&amp;source=editors&amp;ust=1661795645935104&amp;usg=AOvVaw3-QNbQt9QHCyFN0v-ukdzZ" target="_blank" rel="noreferrer">
                Network Advertising Initiative
              </a>
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'disc', px: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <strong>Google Analytics</strong>.
              We use Google Analytics to help us better understand how people
              engage with the Services by collecting information and creating
              reports about how users use our Services. For more information on
              Google Analytics, click&nbsp;
              <a href="https://www.google.com/url?q=https://marketingplatform.google.com/about/analytics/&amp;sa=D&amp;source=editors&amp;ust=1661795645935517&amp;usg=AOvVaw3fbcsQnLv4aswSFXfvLAc4" target="_blank" rel="noreferrer">
                here
              </a>
              . For more information about Google&rsquo;s privacy practices,
              click&nbsp;
              <a href="https://www.google.com/url?q=https://www.google.com/policies/privacy/partners/&amp;sa=D&amp;source=editors&amp;ust=1661795645935764&amp;usg=AOvVaw1V1lU4nnMqcDQJHas0Y3tP" target="_blank" rel="noreferrer">
                here
              </a>
              . You can opt out of Google Analytics by downloading and installing
              the browser plug-in available at:&nbsp;
              <a href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1661795645936001&amp;usg=AOvVaw0KYV817sckvbbwi8E25jef" target="_blank" rel="noreferrer">
                https://tools.google.com/dlpage/gaoptout
              </a>
            </ListItem>
          </List>
        </Box>
        <Box>
          <Typography>
            Note that because these opt-out mechanisms are specific to the device
            or browser on which they are exercised, you will need to opt out on
            every browser and device that you use.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 800, display: 'inline' }}>Do Not Track.</Typography>
          <Typography sx={{ display: 'inline' }}>
            &nbsp;Some Internet browsers may be configured to send &ldquo;Do Not
            Track&rdquo; signals to the online services that you
            visit.&nbsp;&nbsp;We currently do not respond to &ldquo;Do Not
            Track&rdquo; or similar signals.&nbsp;To find out more about &ldquo;Do
            Not Track,&rdquo; please visit&nbsp;
            <a href="https://www.google.com/url?q=http://www.allaboutdnt.com/&amp;sa=D&amp;source=editors&amp;ust=1661795645936489&amp;usg=AOvVaw3HVyOgcaogIzzsfVQImDA6" target="_blank" rel="noreferrer">
              http://www.allaboutdnt.com
            </a>
            .
          </Typography>
        </Box>
        <Typography variant="h4">Children&apos;s Privacy</Typography>
        <Box>
          <Typography>
            Our Services are not intended for use by children under 13 years of
            age. If we learn that we have collected personal information through the
            Services from a child under 13 without the consent of the child&rsquo;s
            parent or guardian as required by law, we will delete it. &nbsp;If you
            are a parent or guardian and you are aware that your Child has provided
            us with personal information, please contact us as set out in the
            &ldquo;Contact us&rdquo; section below.
          </Typography>
        </Box>
        <Typography variant="h4">Security practices</Typography>
        <Box>
          <Typography>
            We use reasonable organizational, technical and administrative measures
            designed to protect against unauthorized access, misuse, loss,
            disclosure, alteration and destruction of personal information.
            Unfortunately, data transmission over the Internet cannot be guaranteed
            as completely secure. Therefore, while we strive to protect your
            personal information, we cannot guarantee the security of personal
            information.
          </Typography>
        </Box>
        <Typography variant="h4">Security practices</Typography>
        <Box>
          <Typography>
            We use reasonable organizational, technical and administrative measures
            designed to protect against unauthorized access, misuse, loss,
            disclosure, alteration and destruction of personal information.
            Unfortunately, data transmission over the Internet cannot be guaranteed
            as completely secure. Therefore, while we strive to protect your
            personal information, we cannot guarantee the security of personal
            information.
          </Typography>
        </Box>
        <Typography variant="h4">Other sites, mobile applications and services</Typography>
        <Box>
          <Typography>
            Our Services may contain links to other websites, mobile applications,
            and other online services operated by third parties. These links are not
            an endorsement of, or representation that we are affiliated with, any
            third party. In addition, our content may be included on web pages or in
            mobile applications or online services that are not associated with us.
          </Typography>
        </Box>
        <Box>
          <Typography>
            We do not control third-party websites, mobile applications or online
            services, and we are not responsible for their actions. Other websites
            and services follow different rules regarding the collection, use and
            sharing of your personal information. We encourage you to read the
            privacy policies of the other websites and mobile applications and
            online services you use.
          </Typography>
        </Box>
        <Typography variant="h4">Changes to this Privacy Policy</Typography>
        <Box>
          <Typography>
            We reserve the right to modify this Privacy Policy at any time. If we
            make material changes to this Privacy Policy, we will notify you by
            updating the date of this Privacy Policy and posting it on our Services.
          </Typography>
        </Box>
        <Typography variant="h4">Contact us</Typography>
        <Box>
          <Typography>
            Please direct any questions or comments about this Policy or privacy
            practices to &nbsp;
            <a href="mailto:support@homeflow.me">support@homeflow.me</a>
            .
          </Typography>
        </Box>
        <Box>
          <Typography>
            You may also write to us via postal mail at:
          </Typography>
        </Box>
        <Box>
          <Typography>
            Homeflow Insurance Services<br />
            PO Box 193484<br />
            San Francisco, CA 94119
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};
