import 'yup-phone';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { usePhoneNumberFieldRendered, useTextFieldRendered } from 'components/forms/helpers';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ROUTES } from 'rentr-constants';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';
import * as yup from 'yup';

export interface Props {
  firstName: string;
  email?: string;
  phoneNumber?: string;
  onSubmit: (data: FormData) => void;
  updating?: boolean;
}

interface FormData {
  email: string;
  phoneNumber: string;
  agreedToTerms: boolean;
}

const schema = yup.object().shape({
  email: yup.string().email('This doesn\t look like a valid email').required('Email is required'),
  phoneNumber: yup.string().phone('US', true, 'Invalid phone number').required('Phone number is required'),
  agreedToTerms: yup.boolean().required()
});

export function ContactInfo({ firstName, email: emailProp, phoneNumber: phoneNumberProp, onSubmit, updating = false }: Props) {
  const emailInputRef = React.useRef<HTMLInputElement>();
  const { getValues, control, formState, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: emailProp,
      phoneNumber: phoneNumberProp,
      agreedToTerms: false
    },
    mode: 'onChange'
  });

  const handleSubmit = React.useCallback(() => {
    onSubmit(getValues());
  }, [getValues, onSubmit]);

  React.useEffect(() => {
    if (emailInputRef.current !== null) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef]);

  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderPhoneNumber = usePhoneNumberFieldRendered(control, formState.errors);

  const watchedAgreedToTerms = watch('agreedToTerms');

  return (
    <>
      <SectionWrapper
        title={`Alright ${firstName}, where should we send your quotes?`}
        primaryButtonLabel="Next"
        primaryButtonLoading={updating}
        onPrimaryButtonClick={handleSubmit}
        disableButtons={!formState.isValid || !watchedAgreedToTerms}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
            mt: { xs: 1, md: 2 }
          }}
        >
          <Box sx={{ width: 330 }}>
            {renderTextField('email', 'Email', { type: 'email', inputRef: emailInputRef })}
          </Box>
          <Box sx={{ width: 330 }}>
            {renderPhoneNumber('phoneNumber', 'Phone Number', '(###) ###-####')}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 330, pl: 1 }}>
            <FormControlLabel
              control={
                <Controller
                  name={'agreedToTerms'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontWeight: 400, color: (t) => t.palette.grey['900'] }}>
                  I agree to the
                  <Box component="span" sx={{ fontWeight: 500, color: (t) => t.palette.primary.main }} onClick={() => window.open(`${window.location.origin}${ROUTES.TERMS_OF_SERVICE}`, '_blank')}>
                    {' '}Terms of Service
                  </Box>
                </Typography>
              }
            />
          </Box>
        </Box>
      </SectionWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: { xs: 4, md: 20 } }}>
        <Box sx={{ maxWidth: { md: '60%' } }}>
          <Typography variant="body2" sx={{ fontSize: { xs: 10, md: 12 }, color: (t) => t.palette.grey['700'], textAlign: 'center',  }}>
            By clicking “next”, I consent by electronic signature to receive information about my insurance quote(s) or other Homeflow services by text message or phone call, made by any means or technology, from Homeflow to the phone number I have provided.
          </Typography>
        </Box>
      </Box>
    </>
  );
}
