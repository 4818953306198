import { Box, Typography } from '@mui/material';
import { PreviousStepButton } from 'components/PreviousStepButton';
import { StatusContainer } from 'components/StatusContainer';
import { QuoteDTO } from 'dtos';
import { ReactComponent as DirectTerms } from 'images/icons/direct-terms.svg';

interface Props {
  quote: QuoteDTO;
  onContinueClick: () => void;
  onReviewPolicyClick: () => void;
}

export const DirectTermsSection = ({ quote, onContinueClick, onReviewPolicyClick }: Props) => {
  return (
    <Box>
      <PreviousStepButton
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          mb: 4,
        }}
        onClick={onReviewPolicyClick}
        label="Review Policy"
      />
      <StatusContainer
        sx={{
          width: '100%',
          display: 'flex',
          margin: '0 auto',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 16,
        }}
        button={{
          label: 'Continue',
          onClick: onContinueClick,
          props: {
            sx: {
              minWidth: 200,
              mt: 2,
            },
          },
        }}
        description={
          <Typography sx={{ maxWidth: 500 }}>
            <b>
              You can expect an email shortly from {quote.carrier.displayName}{' '}
              to sign your terms & conditions.
            </b>
          </Typography>
        }
        title="Terms and conditions"
        image={<DirectTerms />}
      />
    </Box>
  );
};
